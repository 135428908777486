import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { NotificationSnackbarDuration, useNotificationSnackbar } from '@nordic-web/ui-components'

export const NetworkListener = () => {
  const [isOnline, setIsOnline] = useState<null | boolean>(null)
  const { showSnackbarMessage } = useNotificationSnackbar()
  const t = useTranslations()

  const handleNetworkChange = () => {
    setIsOnline(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('online', handleNetworkChange)
    window.addEventListener('offline', handleNetworkChange)

    return () => {
      window.removeEventListener('online', handleNetworkChange)
      window.removeEventListener('offline', handleNetworkChange)
    }
  }, [])

  useEffect(() => {
    if (isOnline === null) return

    if (!isOnline) {
      showSnackbarMessage({
        text: t('snackbar__network_lost'),
        duration: NotificationSnackbarDuration.LONG,
        type: 'negative',
      })
    } else {
      showSnackbarMessage({
        text: t('snackbar__network_retained'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]) // Only want it to trigger on isOnline change

  return null
}
