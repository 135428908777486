import styled from 'styled-components'

type Tv4AgeRatingProps = {
  ageRating?: number
}

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  background: theme.color.surface.white20,
  color: theme.color.text.primary,
  borderRadius: theme.radii.border_radius_small,
  fontWeight: theme.fontWeightBold,
  paddingLeft: theme.space(2),
  paddingRight: theme.space(2),
  paddingBottom: theme.space(0),
  paddingTop: theme.space(0),
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
}))

export const Tv4AgeRating = ({ ageRating }: Tv4AgeRatingProps) => {
  if (ageRating === undefined) {
    return null
  }

  return <Container>{ageRating} +</Container>
}
