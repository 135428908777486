import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import type {
  ChannelVideoFieldsFragment,
  EpisodeVideoFieldsFragment,
  MovieVideoFieldsFragment,
  SportEventVideoFieldsFragment,
} from '@nordic-web/gql'
import {
  CardImageContainer,
  CardLabelContainer,
  CardMainText,
  CardSecondaryText,
  ResponsiveImage,
} from '@nordic-web/ui-components'
import { isCurrentlyLive } from '@nordic-web/utils/date'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'
import { parseLiveCardData } from '@/components/cards/live-card/parse-live-card-data'
import { useUpdateEpg } from '@/components/epg-channel-card/use-update-epg'
import { FadeInImage } from '@/components/fade-in-image'
import { LivePanelLabels } from '@/components/live-panel/live-panel-labels'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { PlayerContextProvider } from '@/context/player-context'
import { PlayerTimeContextProvider } from '@/context/player-time-context'
import { isLiveContent } from '@/features/video/utils/is-live-content'
import { LazyVideoPlayer } from '@/features/video/video-player/lazy-video-player'
import { LazyVideoPlayerAccessControl } from '@/features/video/video-player/lazy-video-player-access-control'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'
import { formatStartEndTime } from '@/utils/date'

export type LiveCardAsset =
  | ChannelVideoFieldsFragment
  | EpisodeVideoFieldsFragment
  | MovieVideoFieldsFragment
  | SportEventVideoFieldsFragment

type LiveCardProps = {
  asset: LiveCardAsset
  isActive: boolean
  shouldPlayOutOfView?: boolean | null
}

export const LiveCard = ({ asset, isActive, shouldPlayOutOfView = false }: LiveCardProps) => {
  const { ref, inView: isInView } = useInView({ threshold: 0.7 })
  const { push } = useRouter()
  const [shouldShowPlayer, setShouldShowPlayer] = useState(false)
  const [shouldShowImage, setShouldShowImage] = useState(true)
  useReRenderWithInterval(1_000)

  const isChannel = asset.__typename === 'Channel'

  const { isLive, title, logo, playableFrom, startTime, endTime, cardImage, playerPosterImage, brandLogo } =
    parseLiveCardData(asset)

  const hasAccess = asset.access.hasAccess

  // This effect is used to avoid flickering of the image when transitioning from image to video
  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (shouldShowPlayer) {
      timeoutId = setTimeout(() => setShouldShowImage(false), 500)
    } else {
      setShouldShowImage(true)
    }
    return () => clearTimeout(timeoutId)
  }, [shouldShowPlayer])

  // We want to avoid loading vods that has ended, it causes a lot of analytics AFS error events
  const isLiveNow =
    isLiveContent(asset) &&
    (isChannel ||
      isCurrentlyLive({
        eventStartTime: playableFrom?.isoString,
        eventEndTime: endTime,
      }))

  const shouldPlayVideo = (shouldPlayOutOfView ? isActive : isActive && isInView) && isLiveNow

  useEffect(() => {
    if (!hasAccess) return
    let timeoutId: NodeJS.Timeout

    if (!shouldPlayVideo) {
      setShouldShowPlayer(false)
    } else {
      timeoutId = setTimeout(() => {
        if (isActive) setShouldShowPlayer(true)
      }, 500)
    }

    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, shouldPlayVideo])

  useUpdateEpg(asset.id, isChannel)

  const onAssetClick = (trackOnAssetClick: () => void) => {
    trackOnAssetClick()
    return push(paths.video.urlString({ assetId: asset.id, slug: asset.slug }))
  }

  return (
    <AssetTracking content_media_id={asset.id}>
      {({ trackOnAssetClick }) => (
        <LiveCardContainer ref={ref}>
          <CardImageContainer nwHoverEffect="small">
            <CardLabelContainer>
              <LivePanelLabels playableFrom={playableFrom} isLiveContent={isLive} />
            </CardLabelContainer>
            {brandLogo && (
              <BrandLogoContainer>
                <ResponsiveImage height={36} width={100} alt="" src={brandLogo.sourceEncoded} />
              </BrandLogoContainer>
            )}
            {shouldShowPlayer && (
              <PlayerContextProvider>
                <PlayerTimeContextProvider assetId={asset.id}>
                  <LazyVideoPlayerAccessControl asset={asset}>
                    <LazyVideoPlayer
                      asset={asset}
                      autoplay
                      useMinimalControls
                      onVideoClick={() => onAssetClick(trackOnAssetClick)}
                      poster={playerPosterImage}
                    />
                  </LazyVideoPlayerAccessControl>
                </PlayerTimeContextProvider>
              </PlayerContextProvider>
            )}
            {shouldShowImage && (
              <LiveImage
                onClick={() => onAssetClick(trackOnAssetClick)}
                height={928}
                width={522}
                alt={title}
                source={cardImage}
              />
            )}
            <LiveInnerTextArea>
              <InfoContainer>
                {logo && <ResponsiveImage alt="" src={logo} width={30} />}
                <div>
                  <>
                    <CardMainText>
                      <TruncateLines lines={1}>{title}</TruncateLines>
                    </CardMainText>
                    {startTime && (
                      <CardSecondaryText>
                        <TruncateLines lines={1}>{formatStartEndTime(startTime, endTime)}</TruncateLines>
                      </CardSecondaryText>
                    )}
                  </>
                </div>
              </InfoContainer>
            </LiveInnerTextArea>
          </CardImageContainer>
        </LiveCardContainer>
      )}
    </AssetTracking>
  )
}

const LiveCardContainer = styled.div({
  aspectRatio: '16/9',
  position: 'relative',
  cursor: 'pointer',
})

const InfoContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.space(4),
}))

const LiveInnerTextArea = styled.div({
  position: 'absolute',
  bottom: 10,
  left: 10,
  zIndex: 3,
  maxWidth: '85%',
  ['-webkit-transform']: 'translate3d(0, 0, 0)' /* Prevents flickering when swiping in Safari */,
})

const LiveImage = styled(FadeInImage)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  '&::after': {
    content: '" "',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50%',
    backgroundBlendMode: 'overlay',
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%)',
    zIndex: 2,
    pointerEvents: 'none',
  },
})

const BrandLogoContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  top: theme.space(5),
  right: theme.space(5),
  zIndex: 1,
}))
