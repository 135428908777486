import type { PropsWithChildren } from 'react'
import { useTranslations } from 'next-intl'
import type { ListSearchCountFieldsFragment } from '@nordic-web/gql'
import { A11yVisuallyHidden, LabelCount, NavigationTabs } from '@nordic-web/ui-components'
import { filterChildContent } from '@/features/profiles/helpers/filter-child-content'

export enum FilterTypes {
  ALL = 'all',
  SERIES = 'series',
  MOVIES = 'movies',
  CLIPS = 'clips',
  SPORT_EVENTS = 'sportEvents',
  PAGES = 'pages',
}

type FilterTabsProps = PropsWithChildren<{
  activeFilter: FilterTypes
  hits: ListSearchCountFieldsFragment
  totalHits: number
  onFilterChange: (filter: FilterTypes) => void
  isChildProfile: boolean
}>

export const FilterTabs = ({
  onFilterChange,
  activeFilter,
  hits,
  children,
  totalHits,
  isChildProfile,
}: FilterTabsProps) => {
  const t = useTranslations()

  const filterOrder = [
    FilterTypes.ALL,
    FilterTypes.SERIES,
    FilterTypes.MOVIES,
    FilterTypes.CLIPS,
    FilterTypes.SPORT_EVENTS,
    FilterTypes.PAGES,
  ]

  const initActiveFilter = filterOrder.indexOf(activeFilter)

  const handleChange = (index: number) => {
    const filter = filterOrder[index]
    if (!filter) return
    onFilterChange(filter)
  }

  const { clips, movies, pages, series, sportEvents } = hits

  const tabs = filterChildContent(
    [
      {
        id: 'all',
        label: t('search__tabs__all_results'),
        actions: <Count count={totalHits} />,
        disabled: !totalHits,
        shouldShowInChildProfile: true,
      },
      {
        id: 'series',
        label: t('search__tabs__series'),
        actions: <Count count={series} />,
        disabled: !series,
        shouldShowInChildProfile: true,
      },
      {
        id: 'movies',
        label: t('search__tabs__movies'),
        actions: <Count count={movies} />,
        disabled: !movies,
        shouldShowInChildProfile: true,
      },
      {
        id: 'clips',
        label: t('search__tabs__clips'),
        actions: <Count count={clips} />,
        disabled: !clips,
      },
      {
        id: 'sportevents',
        label: t('search__tabs__sportevent'),
        actions: <Count count={sportEvents} />,
        disabled: !sportEvents,
      },
      {
        id: 'pages',
        label: t('search__tabs__pages'),
        actions: <Count count={pages} />,
        disabled: !pages,
      },
    ],
    isChildProfile
  )

  return (
    <NavigationTabs nwTabs={tabs} nwInitActiveTab={initActiveFilter} nwOnChange={handleChange}>
      {children}
    </NavigationTabs>
  )
}

const Count = ({ count = 0 }) => {
  const t = useTranslations()
  const parsedTotal = count > 99 ? '99+' : count
  return (
    <>
      <LabelCount aria-hidden nwCount={parsedTotal} />
      <A11yVisuallyHidden>{t('search__matching_results', { number: parsedTotal })}</A11yVisuallyHidden>
    </>
  )
}
