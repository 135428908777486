import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { Button, ButtonGroup, Dialog, TypographyTitle } from '@nordic-web/ui-components'
import { useContinueWatching } from '@nordic-web/utils/hooks/use-continue-watching'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { paths } from '@/helpers/paths'

type ContinueWatchingDialogProps = {
  mediaId: string
  title: string
  parentId?: string
  panelId: string
  slug: string
  continueWatchingEntryId: string
  onClose: () => void
  isOpen: boolean
  parentSlug?: string
}

export const ContinueWatchingDialog = ({ onClose, isOpen, ...forwardProps }: ContinueWatchingDialogProps) => {
  const [isInnerOpen, setInnerOpen] = useState(isOpen)

  useEffect(() => {
    setInnerOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog nwInitOpen={isInnerOpen} onClose={onClose}>
      <Dialog.Top />
      <Dialog.Body>
        <Inner {...forwardProps} onRequestToClose={() => setInnerOpen(false)} />
      </Dialog.Body>
    </Dialog>
  )
}

type InnerProps = {
  onRequestToClose: () => void
} & Omit<ContinueWatchingDialogProps, 'onClose' | 'isOpen'>

const Inner = ({
  onRequestToClose,
  mediaId,
  title,
  parentId,
  parentSlug,
  panelId,
  slug,
  continueWatchingEntryId,
}: InnerProps) => {
  const t = useTranslations()
  const { markAsSeen, remove } = useContinueWatching(panelId)

  const removeHandler = () => {
    remove({ continueWatchingEntryId })
    onRequestToClose()
  }

  const markAsSeenHandler = () => {
    markAsSeen({ id: mediaId })
    onRequestToClose()
  }

  return (
    <>
      <TypographyTitle as="h2" nwTextAlign="start">
        <TruncateLines lines={2}>{title}</TruncateLines>
      </TypographyTitle>
      <ButtonGroup nwAxis="y-reverse">
        <Link href={paths.video.urlString({ assetId: mediaId, slug })} passHref legacyBehavior>
          <Button as="a" nwVariant="subtle" nwIconFirst="play">
            {t('general__play_cta')}
          </Button>
        </Link>
        <Button onClick={markAsSeenHandler} nwVariant="subtle" nwIconFirst="check">
          {t('continue_watching__mark_as_seen')}
        </Button>
        <Button onClick={removeHandler} nwVariant="subtle" nwIconFirst="trash">
          {t('continue_watching__remove_from_continue_watching')}
        </Button>
        {parentId && (
          <Link href={paths.program.urlString({ id: parentId, slug: parentSlug })} passHref legacyBehavior>
            <Button as="a" nwVariant="subtle" nwIconFirst="alert-info-outlined">
              {t('general__program_page_cta')}
            </Button>
          </Link>
        )}
      </ButtonGroup>
    </>
  )
}
