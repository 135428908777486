import styled from 'styled-components'
import { desktop, tablet } from '@nordic-web/ui-styles'
import { multiMaskBaseObj } from '@/styles/utils/masking'

export const SPACE_BETWEEN = 20

const maskTransparency = '#00000066'
const maskStartLeft = 97.0
const maskStartRight = 96.3
const maskStartDesktopLeft = 'calc(95% - var(--arrow-inset) / 0.5)'
const maskStartDesktopRight = 'calc(95% - var(--arrow-inset))'

export const NavigationButton = styled.button({
  outline: 'none',
  border: 'none',
  appearance: 'none',
  position: 'absolute',
  background: 'transparent',
  height: '100%',
  top: 0,
  width: 'calc(var(--arrow-width) + var(--arrow-inset))',
  opacity: 0,
  color: 'white',
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'opacity 0.2s ease-in-out',
  '--chevron-scale': 1,

  '& svg': {
    position: 'relative',
    flexShrink: 0,
    transition: 'transform 0.1s ease-in-out',
    width: 36,
    height: 36,
  },

  '&.slider-button-prev': {
    left: 0,
    '& svg': {
      transform: 'rotate(90deg) scale(var(--chevron-scale))',
    },
  },

  '&.slider-button-next': {
    right: 0,
    '& svg': {
      transform: 'rotate(-90deg) scale(var(--chevron-scale))',
    },
  },

  '&.slider-button-disabled': {
    cursor: 'unset !important',
    opacity: '0 !important',
  },

  '&:hover': {
    '--chevron-scale': 1.3,
  },

  '.no-touch &': {
    display: 'flex',
  },
})

export const Container = styled.div({
  position: 'relative',
  overflow: 'hidden',
  width: '100vw',
  left: '-5vw',
  marginTop: -7,
  marginBottom: -7,
  '--arrow-inset': `${-SPACE_BETWEEN / 2}px`,
  '--arrow-width': '5vw',

  '.swiper': {
    zIndex: 0,
    paddingLeft: '5vw',
    paddingRight: '5vw',
    paddingTop: 7,
    paddingBottom: 7,

    '.swiper-wrapper': {
      zIndex: 0,
    },

    '&.swiper-horizontal': {
      overflow: 'visible',
    },
  },
  [`&:hover ${NavigationButton}`]: {
    opacity: 1,
  },

  [tablet]: {
    '.no-touch & .swiper': {
      ...multiMaskBaseObj,
    },
    '.no-touch &:hover .swiper': {
      maskImage: `linear-gradient(
          90deg,
          black ${maskStartRight}%,
          ${maskTransparency} ${maskStartRight}%,
          ${maskTransparency}
        ),
        linear-gradient(-90deg, black ${maskStartLeft}%, ${maskTransparency} ${maskStartLeft}%, ${maskTransparency})`,
    },
  },

  [desktop]: {
    '.no-touch &:hover .swiper': {
      maskImage: `linear-gradient(90deg, black ${maskStartDesktopRight}, ${maskTransparency} ${maskStartDesktopRight}, ${maskTransparency}), linear-gradient(-90deg, black ${maskStartDesktopLeft}, ${maskTransparency} ${maskStartDesktopLeft}, ${maskTransparency})`,
    },
  },
})
