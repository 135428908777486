import type { Props } from 'react-infinite-scroll-component'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircleSpinner } from '@nordic-web/ui-components'

export const InfiniteScrollContainer = (props: Omit<Props, 'loader'>) => {
  return (
    <InfiniteScroll
      {...props}
      scrollThreshold="800px"
      loader={<CircleSpinner nwPaddingTop />}
      // Overflow visible to make sure the card hover effect is not cut off
      style={{ overflow: 'visible' }}
    />
  )
}
