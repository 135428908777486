import { useApolloClient } from '@apollo/client'
import { useEvictOldCacheEntry } from './use-evict-old-cache-entry'
import { useOnNavigateToCurrentUrl } from './use-on-navigate-to-current-url'

const __typename = 'Page'

export const usePageCacheLogic = (pageId: string, ttl = 5000 * 60) => {
  useEvictOldCacheEntry({ id: pageId, type: __typename, ttl })

  const { cache } = useApolloClient()

  // If the user for example presses the Home menu item, or the brand logo, we want to refresh the content
  useOnNavigateToCurrentUrl({
    onNavigate: () => {
      const cacheId = cache.identify({
        __typename,
        id: pageId,
      })
      cache.evict({ id: cacheId })
    },
  })
}
