import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import { FormLayoutRows } from '../form-layout-rows/form-layout-rows'

type FormProps = PropsWithChildren<ComponentPropsWithoutRef<'form'>>

/**
 * Wrap your inputs in this component to space them out vertically.
 * In an ideal scenario, this could also be be responsible for positioning submit buttons, handling multi-page forms etc.
 * The sky is the limit
 */
export const Form = ({ children, ...formProps }: FormProps) => {
  return (
    <FormLayoutRows as="form" {...formProps}>
      {children}
    </FormLayoutRows>
  )
}
