import type { EffectCallback } from 'react'
import { useEffect, useRef } from 'react'

export function useDidUpdateEffect<T>(effect: EffectCallback, value: T) {
  const effectRef = useRef(effect)
  const localValue = useRef(value)

  // Update the ref to the latest effect on every render
  effectRef.current = effect

  useEffect(() => {
    if (localValue.current !== value) {
      localValue.current = value
      return effectRef.current()
    }
  }, [value])
}
