import React from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import type { SinglePanelFieldsFragment } from '@nordic-web/gql'
import { Button, ResponsiveImage, TypographyText } from '@nordic-web/ui-components'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { ButtonLoading } from '@/components/button/button-loading'
import { GlobalContentColorVarStyle } from '@/components/global-content-color-var-style/global-content-color-var-style'
import { Hero } from '@/components/hero/hero'
import { HeroButtonGroup } from '@/components/hero/hero-button-group'
import { HeroCtaButton } from '@/components/hero/hero-cta-button'
import { LabelFactory } from '@/components/label-factory'
import { MaxWidthContainerLg } from '@/components/max-width-container/max-width-container-lg'
import { useAuthenticationStore } from '@/features/auth/authentication-store'
import { FavoriteButton } from '@/features/favorite/favorite-button'
import { useParseLink } from '@/features/panel-factory/hooks/single-module-link-parser'
import { useIsBelowTablet } from '@/hooks/use-breakpoint'
import { useUpsellClickHandler } from '@/hooks/use-upsell-click-handler'
import { AssetTracking } from '@/tracking/asset-tracking'

type SinglePanelProps = {
  index: number
  panel: SinglePanelFieldsFragment
  hideCdpButton: boolean
  onTrailerEnd?: () => void
  onTrailerPlay?: () => void
}

export const SinglePanel = ({
  index,
  hideCdpButton,
  onTrailerEnd,
  onTrailerPlay,
  panel: { linkText, link, images, title, pitch, shortPitch, trailers, secondaryLinkText },
}: SinglePanelProps) => {
  const {
    ctaHref,
    secondaryCtaHref,
    favoriteId,
    trackingId,
    favoriteType,
    mediaLabelData,
    isLoading,
    shouldAlwaysShowCdpButton,
    upsellData,
  } = useParseLink(link)
  const t = useTranslations()
  const { isLoggedIn } = useAuthenticationStore()
  const isClientSide = useIsClientSide()
  const brandLogo = images?.brandLogo ?? null
  const shouldShowCompactView = useIsBelowTablet()
  const shouldShowCompactSecondaryButton = shouldShowCompactView && ctaHref
  const shouldShowSecondaryButton = (!hideCdpButton || shouldAlwaysShowCdpButton) && secondaryCtaHref
  const upsellClickHandler = useUpsellClickHandler(upsellData?.packageTierLink)
  const upsellLinkText = t(isLoggedIn ? 'general__upsell_cta_logged_in' : 'general__upsell_cta_logged_out', {
    tierName: upsellData?.tierName,
  })

  return (
    <>
      {index === 0 && (
        <GlobalContentColorVarStyle
          desktopColor={images?.image16x9?.meta?.muteBgColor?.hex}
          mobileColor={images?.image2x3?.meta?.muteBgColor?.hex}
        />
      )}
      <AssetTracking content_media_id={trackingId ?? ''}>
        {({ trackOnAssetClick }) => (
          <Hero
            image16x9={images?.image16x9 ?? undefined}
            image2x3={images?.image2x3 ?? undefined}
            trailers={trailers}
            imagePriority={index === 0}
            onTrailerEnd={onTrailerEnd}
            onTrailerPlay={onTrailerPlay}
          >
            {mediaLabelData && <LabelFactory media={mediaLabelData} />}
            {brandLogo?.sourceEncoded && (
              <ResponsiveImage height={24} width={100} src={brandLogo.sourceEncoded} alt="" />
            )}
            <MaxWidthContainerLg>
              <TypographyText as="h2" nwVariant="title1">
                {title}
              </TypographyText>
            </MaxWidthContainerLg>
            <MaxWidthContainerLg>
              <TypographyText nwVariant="body2">{shouldShowCompactView ? shortPitch : pitch}</TypographyText>
            </MaxWidthContainerLg>
            <HeroButtonGroup>
              {!isLoading && ctaHref && (
                <Link href={ctaHref} passHref legacyBehavior>
                  <HeroCtaButton
                    forwardedAs="a"
                    nwIconFirst={upsellData ? undefined : 'player-play-filled'}
                    onClick={(event) => {
                      trackOnAssetClick()
                      upsellClickHandler(event)
                    }}
                  >
                    {upsellData ? upsellLinkText : linkText}
                  </HeroCtaButton>
                </Link>
              )}
              {!ctaHref && isLoading && <ButtonLoading />}
              {shouldShowSecondaryButton && (
                <Link href={secondaryCtaHref} passHref legacyBehavior>
                  <Button
                    as="a"
                    onClick={() => trackOnAssetClick()}
                    nwVariant="secondary"
                    aria-label={secondaryLinkText}
                    nwIconFirst={shouldShowCompactSecondaryButton ? 'alert-info-outlined' : undefined}
                  >
                    {shouldShowCompactSecondaryButton ? null : secondaryLinkText}
                  </Button>
                </Link>
              )}
              {isClientSide && favoriteId && favoriteType && (
                <FavoriteButton mediaId={favoriteId} mediaType={favoriteType} nwVariant="secondary" />
              )}
            </HeroButtonGroup>
          </Hero>
        )}
      </AssetTracking>
    </>
  )
}
