import styled, { keyframes } from 'styled-components'
import { tablet } from '@nordic-web/ui-styles'
import { spaceBase } from '@nordic-web/ui-tokens'
import { Icon } from '../../icon/icon/icon'
import { TypographyText } from '../../typography/typography-text/typography-text'
import type { SnackbarMessage } from './notification-snackbar-context'
import { snackbarAnimationSpeed } from './notification-snackbar-context'

const enterAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const exitAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`

// See https://stretchingcharlie.medium.com/animating-styled-components-using-style-objects-or-tagged-template-literals-5e18436ec9ad
const Animation = styled.div<{ isOpen: boolean }>`
  animation: ${({ isOpen }) => (isOpen ? enterAnimation : exitAnimation)} ${snackbarAnimationSpeed}ms ease-out forwards;
`

const Wrapper = styled(Animation)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'rgba(38, 37, 36, 0.7)', // --misc-banner-background in the design but we don't have it yet
  padding: theme.space(4),
  zIndex: 120,
  backdropFilter: 'blur(50px)',
  borderRadius: theme.radii.border_radius,
  display: 'flex',
  alignItems: 'center',
  width: `calc(100vw - ${spaceBase * 4 * 2}px)`,
  gap: theme.space(4),
  right: theme.space(4),
  bottom: theme.space(4),
  [tablet]: {
    width: 460,
    right: theme.space(8),
    bottom: theme.space(8),
  },
}))

const IconContainer = styled.div({
  width: 24,
  height: 24,

  '&:hover': {
    cursor: 'pointer',
  },
})

export type NotificationSnackbarProps = {
  message: SnackbarMessage
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const NotificationSnackbar = ({ message, isOpen, setIsOpen }: NotificationSnackbarProps) => {
  return (
    <Wrapper isOpen={isOpen}>
      <IconContainer onClick={() => setIsOpen(false)}>
        <Icon nwVariant={message.type !== 'negative' ? 'check' : 'close-x'} />
      </IconContainer>
      <TypographyText nwVariant="info">{message.text}</TypographyText>
    </Wrapper>
  )
}
