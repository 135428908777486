import type { VoucherResponse, VoucherSuccess } from '@nordic-web/rest-codegen/generated/subscription'

export const isVoucherValid = (response?: VoucherResponse): response is VoucherSuccess => !!response?.valid
const isVoucherValidForPackage = (voucher: VoucherSuccess, entitlementId?: string) =>
  !voucher.products?.some((product) => product.productGroupId.toString() !== entitlementId)

export const voucherIsApplied = (
  voucher: VoucherResponse | undefined,
  entitlementId?: string
): voucher is VoucherSuccess => isVoucherValid(voucher) && isVoucherValidForPackage(voucher, entitlementId)
