import type { HtmlHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import styled from 'styled-components'
import { generateResponsiveStyles } from '@nordic-web/ui-styles'
import { Stack } from '../layout/stack-redux/stack'
import { TypographyText } from '../typography/typography-text/typography-text'

export type FilterProps = {
  children: string
  nwSelected?: boolean
  as?: 'button' | 'a'
} & HtmlHTMLAttributes<HTMLButtonElement>

export const Filter = forwardRef(function Filter(
  { children, nwSelected, ...props }: FilterProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <StyledButton nwSelected={nwSelected} {...props} ref={ref}>
      <Inner nwAlignItems="center" nwJustifyContent="center">
        <TypographyText nwVariant="nav" nwColor="primary">
          {children}
        </TypographyText>
      </Inner>
    </StyledButton>
  )
})

const StyledButton = styled.button<{ nwSelected?: boolean }>(({ theme, nwSelected }) => ({
  paddingInline: theme.space(4),
  paddingBlock: theme.space(2),
  backgroundColor: nwSelected ? theme.color.filter.selected : theme.color.filter.default,
  appearance: 'none',
  border: 'none',
  borderRadius: theme.radii.border_radius,
  ...generateResponsiveStyles('height', [32, 40]),
  ...(!nwSelected && {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.color.filter.hover,
    },
  }),
}))

const Inner = styled(Stack)({
  height: '100%',
  width: '100%',
})
