import Link from 'next/link'
import styled from 'styled-components'
import { ResponsiveImage } from '@nordic-web/ui-components'
import { cardHoverEffect } from '@nordic-web/ui-components'

type LogoCardProps = {
  selected?: boolean
}

export const LogoCard = styled(Link)<LogoCardProps>(({ theme, selected }) => ({
  position: 'relative',
  borderRadius: theme.radii.radiiX(5),
  background: selected ? theme.color.surface.cardHoverFocused : theme.color.surface.cardDefault,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: '1 / 1',
  '&:hover': {
    background: theme.color.surface.cardHoverFocused,
    textDecoration: 'none',
  },
  ...cardHoverEffect,
}))

export const LogoCardImage = styled(ResponsiveImage).attrs({
  width: 200,
})({
  width: '60% !important',
})
