import styled from 'styled-components'

export const ListElement = styled.li(({ theme }) => ({
  cursor: 'pointer',
  ['button, a']: {
    color: theme.color.text.primary,
    padding: 0,
    border: 'none',
    background: 'none',
    ...theme.typography.title3,
    verticalAlign: 'middle',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ['& svg']: {
      marginRight: 16,
      verticalAlign: 'middle',
    },
  },
}))

export const MobileMenuProfileButton = styled.button({
  padding: 0,
  margin: 0,
  background: 'none',
  border: 'none',
  color: 'inherit',
})
