import Bugsnag from '@bugsnag/js'

export function safeLocalStorageGetItem(key: string) {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    Bugsnag.notify(`Error getting item ${key} from localStorage`)
    return null
  }
}

export function safeLocalStorageSetItem(key: string, value: string) {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    Bugsnag.notify(`Error setting item ${key} in localStorage`)
  }
}

export function safeLocalStorageRemoveItem(key: string) {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    Bugsnag.notify(`Error removing item ${key} from localStorage`)
  }
}
