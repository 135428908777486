import type { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'
import { LayoutColumns, type LayoutColumnsProps } from '../../layout/layout-columns/layout-columns'

export type FormLayoutColumnsProps = PropsWithChildren<Omit<LayoutColumnsProps, 'nwColumnGap' | 'nwRowGap'>>

/**
 * Create columns with form elements next to each other with a gap in between.
 * Uses LayoutColumns under the hood, but with a fixed column and row gap, and a default min-width.
 */
export const FormLayoutColumns = ({ children, nwMinWidth, ...forwardProps }: FormLayoutColumnsProps) => {
  const theme = useTheme()
  return (
    <LayoutColumns
      nwColumnGap={theme.space(4)}
      nwRowGap={theme.space(4)}
      nwMinWidth={nwMinWidth || theme.space(64)}
      {...forwardProps}
    >
      {children}
    </LayoutColumns>
  )
}
