import React, { useEffect, useId, useRef } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import type { UseFormRegisterReturn } from 'react-hook-form'
import styled from 'styled-components'
import { FormInputBase } from '../_base/form-input-base/form-input-base'
import { FormLabelBase } from '../_base/form-label-base/form-label-base'
import { FormWrapperBase } from '../_base/form-wrapper-base/form-wrapper-base'
import { FormValidationMessage } from '../form-validation-message/form-validation-message'

export type FormInputProps = {
  nwLabel: string
  nwErrorMessage?: string
  /**
   * Add extra action buttons at the end of the input. Primarily used by the password component, and only optimized for one icon button
   */
  nwActions?: React.ReactNode
  /**
   * Minimum requirement is to pass the name to the register function from react-hook-form: `validators={register('input-name')}`
   */
  validators: UseFormRegisterReturn
  nwAutoFocus?: boolean
} & Omit<ComponentPropsWithoutRef<'input'>, 'name'>

/**
 * A form input component that is optimized for use with react-hook-form.
 * It replaces the old FormField component, but has no support for textarea (which should be its own component)
 * TODO: Create a `FormInputTextArea` component
 */
export const FormInput = ({
  nwLabel,
  nwActions,
  nwErrorMessage,
  validators,
  nwAutoFocus,
  ...forwardProps
}: FormInputProps) => {
  const randomId = useId()
  const id = forwardProps.id ?? `form-input-${randomId}`
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (nwAutoFocus && inputRef?.current) {
      inputRef.current?.focus()
    }
  }, [nwAutoFocus])

  return (
    <FormWrapperBase disabled={forwardProps.disabled}>
      <Input
        {...validators}
        {...forwardProps}
        id={id}
        placeholder=" "
        withActions={!!nwActions}
        aria-invalid={nwErrorMessage ? 'true' : 'false'}
        ref={(e) => {
          validators?.ref(e)
          inputRef.current = e
        }}
      />
      <FormLabelBase htmlFor={id}>{nwLabel}</FormLabelBase>
      {nwActions && <Actions>{nwActions}</Actions>}
      <FormValidationMessage nwMessage={nwErrorMessage} />
    </FormWrapperBase>
  )
}

const Input = styled(FormInputBase)<{ withActions: boolean }>(({ theme, withActions = false }) => ({
  gridRow: 1,
  gridColumn: '1 / -1',
  height: 48,
  ...theme.typography.info,
  paddingInline: theme.space(3),
  paddingBlockStart: theme.space(4),
  ...(withActions && { paddingInlineEnd: theme.space(12) }),
  /* Add the styles to remove the spinner from number input fields */
  '&[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}))

const Actions = styled.div(({ theme }) => ({
  gridRow: 1,
  gridColumn: 2,
  display: 'flex',
  gap: theme.space(4),
}))
