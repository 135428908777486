import { useCallback, useEffect, useState } from 'react'
import packageJson from '@@/package.json'
import Bugsnag from '@bugsnag/js'
import { useReno } from '@nordic-web/reno/hooks/use-reno'
import * as RenoService from '@nordic-web/rest-codegen/generated/reno'
import { parseJwt } from '@nordic-web/utils/authentication/token'
import { brandConfig } from '@/config/brand'
import { authenticationStore, useAuthenticationStore } from '@/features/auth/authentication-store'
import type { RenoNotification } from '@/features/reno/types'
import { nextConfig } from '@/helpers/env'
import { useHandleRenoNotification } from './use-handle-reno-notification'

export const RENO_EVENT = 'reno-notification'

export const RenoNotificationListener = () => {
  const [lastSeen, setLastSeen] = useState(Date.now())
  const { isLoggedIn, accessToken } = useAuthenticationStore()
  const [publishedNotifications, setPublishedNotifications] = useState<string[]>([])
  const renoHandlers = useHandleRenoNotification()

  const onError = useCallback(
    (error: Error) => {
      if (error instanceof RenoService.ApiError && error.status === 401) {
        Bugsnag.notify('An error occurred when fetching reno notifications ', (event) => {
          event.addMetadata('details', {
            rawError: error,
            expiryTime: accessToken ? parseJwt(accessToken)?.exp : 'No access token',
          })
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken]
  )

  const getAccessToken = useCallback(() => authenticationStore.getValidAccessToken(), [])

  const { socketNotification, httpNotifications } = useReno({
    apiUrl: nextConfig.string('RENO_API'),
    clientName: brandConfig.clientName,
    clientVersion: packageJson.version,
    lastSeen,
    getAccessToken,
    onError,
    skip: !isLoggedIn,
  })

  const handleNotification = useCallback(
    (notification: RenoNotification) => {
      const isPublished = publishedNotifications.includes(notification?.id)
      if (isPublished) return

      switch (notification.type) {
        case 'LOGOUT_ALL':
          renoHandlers.handleLogoutAll()
          break
        case 'PROFILES_CHANGED':
          renoHandlers.handleProfileChange()
          break
        case 'SUBSCRIPTION_CHANGED':
          renoHandlers.handleSubscriptionChange()
          break
        case 'SERVICE_MESSAGES_UPDATED':
          renoHandlers.handleServiceMessagesUpdated()
          break
        default:
          console.error('Unhandled reno notification type', notification.type)
          break
      }

      setLastSeen(notification.timestamp)
      setPublishedNotifications([...publishedNotifications, notification.id])
    },
    [publishedNotifications, renoHandlers]
  )

  useEffect(() => {
    if (socketNotification) {
      handleNotification(socketNotification)
    }
  }, [socketNotification, handleNotification])

  useEffect(() => {
    if (httpNotifications) {
      httpNotifications.forEach((httpNotification) => handleNotification(httpNotification))
    }
  }, [httpNotifications, handleNotification])

  return null
}
