import type { PropsWithChildren } from 'react'
import React from 'react'
import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { useVideoLazyQuery } from '@nordic-web/gql'

type CdpLinkProps = {
  videoId?: string
} & LinkProps

export const usePrefetchVideoQuery = (videoId?: string) => {
  const [fetch] = useVideoLazyQuery()

  return () => {
    if (!videoId) return

    fetch({
      variables: {
        id: videoId,
      },
    })
  }
}

export const VideoPrefetchLink = ({ videoId, ...linkProps }: PropsWithChildren<CdpLinkProps>) => {
  const preloadVideo = usePrefetchVideoQuery(videoId)

  return <Link onMouseEnter={preloadVideo} {...linkProps} />
}
