import { Button } from '@nordic-web/ui-components'
import { BRAND, Brand } from '@/config/brand'

const cmsBase = 'https://app.contentful.com/spaces'
const space = BRAND === Brand.TV4 ? 'mwyr96ianpxb' : 'xftpzp4z9t2n'

const formatCmsLink = (contentType: string, filterField: string, filterValue: string) =>
  `${cmsBase}/${space}/views/entries?contentTypeIds=${contentType}&filters.0.key=fields.${filterField}&filters.0.val=${filterValue}`

const getAttr = (attr: string) => document.querySelector(`span[data-${attr}]`)?.getAttribute(`data-${attr}`)

const getCmsData = () => {
  const seriesName = getAttr('series-name')
  if (seriesName) {
    return {
      link: formatCmsLink('Series', 'name', seriesName),
      id: seriesName,
    }
  }
  const movieName = getAttr('movie-name')
  if (movieName) {
    return {
      link: formatCmsLink('Movie', 'name', movieName),
      id: movieName,
    }
  }
  const pageId = getAttr('page-id')
  if (pageId) {
    return {
      link: formatCmsLink('Page', 'id', pageId),
      id: pageId,
    }
  }
}

export const CmsLink = () => {
  const data = getCmsData()
  if (!data) return null
  return (
    <Button nwVariant="ghost" target="_blank" as="a" href={data.link}>
      Click here to open &quot;{data.id}&quot; in the CMS
    </Button>
  )
}
