import type { DependencyList, RefObject } from 'react'
import { useEffect, useMemo, useState } from 'react'

type Entry = ResizeObserverEntry | null
type Callback = (entry: ResizeObserverEntry) => void

let resizeObserver: ResizeObserver
const getResizeObserver = () => {
  resizeObserver ??= new ResizeObserver((entries) => {
    for (const entry of entries) {
      map.get(entry.target)?.forEach((callback) => callback(entry))
    }
  })
  return resizeObserver
}

const map = new Map<Element, Callback[]>()

export const useResizeObserver = <T>(
  ref: RefObject<HTMLElement>,
  project: (entry: Entry) => T,
  dependencies: DependencyList = []
): T => {
  const [state, setState] = useState<Entry>(null)

  useEffect(() => {
    if (!ref.current) return
    const observer = getResizeObserver()
    const current = ref.current
    const callback: Callback = (entry) => setState(entry)
    const callbacks = [...(map.get(current) ?? []), callback]
    observer.observe(current)
    map.set(current, callbacks)
    return () => {
      observer.unobserve(current)
      const currentCallbacks = map.get(current) ?? []
      const newCallbacks = currentCallbacks.filter((item) => item !== callback)
      if (newCallbacks.length === 0) {
        map.delete(current)
        return
      }
      map.set(current, newCallbacks)
    }
  }, [ref])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => project(state), [state, ...dependencies])
}
