import React from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { A11yVisuallyHidden, Icon } from '@nordic-web/ui-components'
import { brandConfig } from '@/config/brand'
import { paths } from '@/helpers/paths'
import { IconItem } from './style'

export const Search = () => {
  const router = useRouter()
  const t = useTranslations()
  const isSearchActive = router.asPath.indexOf(brandConfig.translatedPaths.search) !== -1

  return (
    <IconItem data-test="header-search-toggle">
      {isSearchActive ? (
        <button onClick={() => router.back()}>
          <Icon nwVariant="close-x" nwColor="primary" />
          <A11yVisuallyHidden>{t('main_menu__a11y__search_close')}</A11yVisuallyHidden>
        </button>
      ) : (
        <Link href={paths.search.urlString({ q: '' })}>
          <Icon nwVariant="search" nwColor="primary" />
          <A11yVisuallyHidden>{t('main_menu__a11y__search')}</A11yVisuallyHidden>
        </Link>
      )}
    </IconItem>
  )
}
