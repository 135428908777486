declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: LegitimateAny[]
  }
}

export const getDataLayer = () => {
  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = []
  }
  return window.dataLayer
}

export const dataLayerPush = (data: LegitimateAny) => {
  if (typeof window === 'undefined') {
    return
  }

  const push = () => getDataLayer().push(data)

  // on windows we've noticed it can take a lot of time to push events (because it reads cookies on every push)
  if (window.requestIdleCallback) {
    window.requestIdleCallback(push, { timeout: 8000 })
  } else {
    push()
  }
}
