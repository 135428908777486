import { brandConfig } from '@/config/brand'
import { paths } from '@/helpers/paths'
import type { FooterColumnConfig } from '.'

export const columnLinksMtv: FooterColumnConfig[] = [
  {
    header: 'Pikalinkit',
    links: [
      {
        title: 'Ohjelmat A-Ö',
        url: paths.allPrograms.urlString(),
      },
      {
        title: 'Uutiset',
        url: paths.news.urlString(),
      },
    ],
  },
  {
    header: 'Apua ja ohjeita',
    links: [
      {
        title: 'Asiakaspalvelu',
        url: paths.faq.urlString(),
      },
      {
        title: 'Evästeasetukset',
        isCookieSettings: true,
      },
    ],
  },
  {
    header: 'Minun MTV Katsomo',
    links: [
      {
        title: 'Tietosuojakäytäntö',
        url: paths.article.urlString({ articleId: brandConfig.gdprLinks.privacyPolicy }),
      },
      {
        title: 'Käyttöehdot',
        url: paths.article.urlString({ articleId: brandConfig.gdprLinks.termsOfUse }),
      },
      {
        title: 'Kirjaudu sisään TV:lläsi',
        url: 'https://aktivoi.mtv.fi',
      },
    ],
  },
  {
    header: 'MTV yhtiönä',
    links: [
      {
        title: 'Tietoa yhtiöstä',
        url: 'https://www.mtvuutiset.fi/yritys',
      },
      {
        title: 'Avoimet työpaikat',
        url: 'https://www.mtvyritys.fi/tyopaikkana',
      },
      {
        title: 'Mainosta MTV:ssä',
        url: 'https://www.mtvspotti.fi',
      },
    ],
  },
  {
    header: 'Katso myös',
    links: [
      {
        title: 'MTVuutiset.fi',
        url: 'https://www.mtvuutiset.fi',
      },
      {
        title: 'Facebook',
        url: 'https://www.facebook.com/mtvkatsomo',
      },
      {
        title: 'X',
        url: 'https://twitter.com/mtvkatsomo',
      },
      {
        title: 'Instagram',
        url: 'https://www.instagram.com/mtvkatsomo/',
      },
      {
        title: 'TikTok',
        url: 'https://tiktok.com/@mtvkatsomo',
      },
    ],
  },
]
