import type { MouseEventHandler, ReactNode, RefObject } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import useDraggableScroll from 'use-draggable-scroll'
import { useResizeObserver } from '../../../hooks/use-resize-observer'
import { Button } from '../../button/button/button'
import {
  FadeEdges,
  HorizontalScrollAreaContainer,
  ScrollArea,
  ScrollAreaWrapper,
} from './util-horizontal-scroll-area.styled'

const DRAG_THRESHOLD = 10

type HorizontalScrollAreaProps = {
  children: ReactNode
  nwFadeColor?: string
  nwFullWidth?: boolean
  nwScrollStartRef?: RefObject<HTMLButtonElement> | null
  nwHideButtons?: boolean
}

export const UtilHorizontalScrollArea = ({
  children,
  nwFadeColor: fadeColor = '',
  nwFullWidth: fullWidth,
  nwScrollStartRef: scrollStartRef,
  nwHideButtons = false,
}: HorizontalScrollAreaProps) => {
  const [position, setPosition] = useState<number>(0)
  const [scroll, setScroll] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const innerRef = useRef<HTMLDivElement>(null)
  const viewWidth = useResizeObserver(containerRef, (entry) => entry?.contentRect.width ?? 0, [])
  const scrollWidth = useResizeObserver(innerRef, (entry) => entry?.contentRect.width ?? 0, [])
  const { onMouseDown } = useDraggableScroll(containerRef)
  const theme = useTheme()

  useEffect(() => {
    if (scrollStartRef?.current?.offsetLeft) {
      const paddingLeft = window.innerWidth * 0.25 // Add some space to the left to show that there are more items to scroll to
      containerRef.current?.scrollTo(scrollStartRef.current?.offsetLeft - paddingLeft, 0)
    }
  }, [scrollStartRef])

  if (fadeColor === '') {
    fadeColor = `var(--content-color, ${theme.color.base.background})`
  }

  const scrollRight = () =>
    containerRef.current?.scrollBy({ left: containerRef.current.clientWidth, behavior: 'smooth' })

  const scrollLeft = () =>
    containerRef.current?.scrollBy({ left: -containerRef.current.clientWidth, behavior: 'smooth' })

  const handleClickCapture: MouseEventHandler = (event) => {
    const { clientX } = event
    const dx = Math.abs(position - clientX)

    if (dx >= DRAG_THRESHOLD) {
      event.preventDefault() // swallow the event so it doesn't cause an accidental click
    }
  }

  const scrollDistanceToLeftEdge = scroll
  const scrollDistanceToRightEdge = scrollWidth - (viewWidth + scroll)

  const isAtLeftEdge = scrollDistanceToLeftEdge === 0
  // We consider to be on the edge if the distance to the right edge is less than one pixel.
  // This situation happens because sizes of HTML elements are not guaranteed to be in whole pixels.
  const isAtRightEdge = scrollDistanceToRightEdge < 1

  return (
    <ScrollAreaWrapper fullWidth={fullWidth}>
      <HorizontalScrollAreaContainer
        fadeColor={fadeColor}
        leftFadeActive={!isAtLeftEdge}
        onMouseDown={onMouseDown}
        rightFadeActive={!isAtRightEdge}
      >
        {!nwHideButtons && (
          <Button nwVariant="ghost" className="left-button" onClick={scrollLeft} nwIconFirst="chevron-left" />
        )}

        <FadeEdges $fadeLeft={!isAtLeftEdge} $fadeRight={!isAtRightEdge}>
          <ScrollArea
            className="scroll-area"
            onMouseDown={(e) => setPosition(e.clientX)}
            onClickCapture={handleClickCapture}
            onScroll={(e) => setScroll((e.target as HTMLDivElement).scrollLeft ?? 0)}
            ref={containerRef}
          >
            <div ref={innerRef}>{children}</div>
          </ScrollArea>
        </FadeEdges>

        {!nwHideButtons && (
          <Button nwVariant="ghost" className="right-button" nwIconFirst="chevron-right" onClick={scrollRight} />
        )}
      </HorizontalScrollAreaContainer>
    </ScrollAreaWrapper>
  )
}
