import type { FC } from 'react'
import type { ChannelFieldsFragment } from '@nordic-web/gql'
import { Stack } from '@nordic-web/ui-components'
import { EpgChannelCard } from '@/components/epg-channel-card'
import { PageMargin } from '@/components/scaffolding/page-margin'
import { ChannelPanelGrid } from '@/features/channel-epg-panel/components/channel-panel-grid'
import { ChannelUpgradeBanner } from '@/features/channel-epg-panel/components/channel-upgrade-banner'

type TableauOverview = {
  channels: ChannelFieldsFragment[]
}
export const TableauOverview: FC<TableauOverview> = ({ channels }) => {
  const channelsWithAccess = channels.filter((channel) => channel.access.hasAccess)
  const channelsWithoutAccess = channels.filter((channel) => !channel.access.hasAccess)
  const hasChannelWithAccess = channelsWithAccess.length > 0

  return (
    <PageMargin>
      <Stack nwGap={5}>
        {hasChannelWithAccess && (
          <ChannelPanelGrid>
            {channelsWithAccess.map((channel) => (
              <EpgChannelCard key={channel.id} channel={channel} />
            ))}
          </ChannelPanelGrid>
        )}
        {!!channelsWithoutAccess.length && <ChannelUpgradeBanner />}
        <ChannelPanelGrid>
          {channelsWithoutAccess.map((channel) => (
            <EpgChannelCard key={channel.id} channel={channel} />
          ))}
        </ChannelPanelGrid>
      </Stack>
    </PageMargin>
  )
}
