import { css } from 'styled-components'
import type { Theme } from '@nordic-web/ui-theme'

export const base = (theme: Theme) => css`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: ${theme.color.base.background} !important;
  }
`
