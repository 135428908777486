import React from 'react'
import type { SwiperOptions } from 'swiper'
import type { PanelQuery } from '@nordic-web/gql'
import { DESKTOP, LARGE_DESKTOP } from '@nordic-web/ui-tokens'
import { isServerSide } from '@nordic-web/utils/misc/detect-side'
import type { Ratio } from '@nordic-web/utils/typescript/common-types'
import { CardFactory } from '@/components/cards/card-factory'
import type { CardItemTypes, OnCardClick } from '@/components/cards/types'
import { Slider } from '@/components/slider/slider'
import { usePanelTracking } from '@/tracking/panel-tracking'

export const HideModuleSpacer = () => <span className="hide-module-spacer" />

export const getSwipeModuleApiLimit = () => {
  if (isServerSide) return 12

  const windowWidth = window.innerWidth

  if (windowWidth > LARGE_DESKTOP) return 12
  if (windowWidth > DESKTOP) return 10

  return 4
}

type EpisodesPanelItem = Extract<PanelQuery['panel'], { __typename: 'EpisodesPanel' }>['content']['items'][0]

export type SwipeModuleItem = CardItemTypes | EpisodesPanelItem

export type SwipeModuleProps = {
  id?: string
  cardQueryParameters?: object
  items: SwipeModuleItem[]
  headerComponent?: React.JSX.Element
  ratio: Ratio
  hasMoreItems?: boolean
  fetchMoreData?: () => void
  onCardClick?: OnCardClick
  breakpoints?: SwiperOptions['breakpoints']
  showOnlyUpsellLabel?: boolean
}

export const SwipeModule = ({
  hasMoreItems,
  headerComponent,
  cardQueryParameters,
  items,
  ratio,
  fetchMoreData,
  onCardClick,
  breakpoints,
  showOnlyUpsellLabel,
}: SwipeModuleProps) => {
  const panelTracking = usePanelTracking()

  const onPanelLoadMore = () => {
    panelTracking?.trackOnPanelLoadMore()
  }

  return (
    <div data-test="swipe-module">
      {headerComponent}

      <Slider
        breakpoints={breakpoints}
        hasMoreItems={hasMoreItems}
        onLoadMore={onPanelLoadMore}
        fetchMoreData={fetchMoreData}
      >
        {items.map((item, index) => (
          <CardFactory
            cardData={item}
            ratio={ratio}
            cardQueryParameters={cardQueryParameters}
            key={index}
            index={index}
            onCardClick={onCardClick}
            showOnlyUpsellLabel={showOnlyUpsellLabel}
          />
        ))}
      </Slider>
    </div>
  )
}
