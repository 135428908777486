import type { Dispatch, ReactNode } from 'react'
import { createContext, useContext, useReducer } from 'react'
import { PlayerAction } from '@/features/video/components/constants'

type PlayerState = {
  isFullscreen: boolean
  isPlayerSkinVisible: boolean
  hasVideoStarted: boolean
  hasVideoEnded: boolean
  hasCreditsStarted: boolean
  hasDismissedAutomaticEndAction: boolean
  shouldShowEndscreen: boolean
  shouldLoadEndscreenData: boolean
  creditsStartTime: number
  isBeforeCreditStart: boolean
}

type EnterFullscreenAction = {
  type: PlayerAction.EnterFullscreen
}

type ExitFullscreenAction = {
  type: PlayerAction.ExitFullscreen
}

type SetFullscreenAction = {
  type: PlayerAction.SetFullscreen
  isFullscreen: boolean
}

type AdEndedAction = {
  type: PlayerAction.AdEnded
}

type CreditsStartedAction = {
  type: PlayerAction.CreditsStarted
  startTime: number
}

type VideoStartedAction = {
  type: PlayerAction.VideoStarted
}

type VideoEndedAction = {
  type: PlayerAction.VideoEnded
}

type ResetAction = {
  type: PlayerAction.Reset
}

type DismissAutomaticEndAction = {
  type: PlayerAction.DismissAutomaticEnd
}

type ShowEndscreenAction = {
  type: PlayerAction.ShowEndscreen
}

type ShouldLoadEndscreenDataAction = {
  type: PlayerAction.ShouldLoadEndscreenData
}

type PlayerSkinVisibilityChangeAction = {
  type: PlayerAction.PlayerSkinVisibilityChange
  visible: boolean
}

type SetIsBeforeCreditStartAction = {
  type: PlayerAction.SetIsBeforeCreditStart
  isBeforeCreditStart: boolean
}

type Action =
  | EnterFullscreenAction
  | ExitFullscreenAction
  | SetFullscreenAction
  | AdEndedAction
  | CreditsStartedAction
  | VideoEndedAction
  | VideoStartedAction
  | ResetAction
  | DismissAutomaticEndAction
  | ShowEndscreenAction
  | ShouldLoadEndscreenDataAction
  | PlayerSkinVisibilityChangeAction
  | SetIsBeforeCreditStartAction

const PlayerStateContext = createContext(undefined as unknown as PlayerState)
const PlayerDispatchContext = createContext(undefined as unknown as Dispatch<Action>)

const reducer = (state: PlayerState, action: Action): PlayerState => {
  const { type } = action

  switch (type) {
    case PlayerAction.EnterFullscreen: {
      return {
        ...state,
        isFullscreen: true,
      }
    }

    case PlayerAction.ExitFullscreen: {
      return {
        ...state,
        isFullscreen: false,
      }
    }

    case PlayerAction.SetFullscreen: {
      const { isFullscreen } = action

      return {
        ...state,
        isFullscreen,
      }
    }

    case PlayerAction.CreditsStarted: {
      return {
        ...state,
        hasCreditsStarted: true,
        creditsStartTime: action.startTime,
      }
    }

    case PlayerAction.VideoStarted: {
      return {
        ...state,
        hasVideoStarted: true,
        hasVideoEnded: false,
      }
    }

    case PlayerAction.VideoEnded: {
      return {
        ...state,
        hasVideoEnded: true,
      }
    }

    case PlayerAction.DismissAutomaticEnd: {
      return {
        ...state,
        hasDismissedAutomaticEndAction: true,
      }
    }

    case PlayerAction.ShowEndscreen: {
      return {
        ...state,
        shouldShowEndscreen: true,
      }
    }

    case PlayerAction.ShouldLoadEndscreenData: {
      return {
        ...state,
        shouldLoadEndscreenData: true,
      }
    }

    case PlayerAction.PlayerSkinVisibilityChange: {
      return {
        ...state,
        isPlayerSkinVisible: action.visible,
      }
    }

    case PlayerAction.SetIsBeforeCreditStart: {
      return {
        ...state,
        isBeforeCreditStart: action.isBeforeCreditStart,
      }
    }

    case PlayerAction.Reset: {
      return {
        ...state,
        hasCreditsStarted: false,
        hasVideoStarted: false,
        hasVideoEnded: false,
        hasDismissedAutomaticEndAction: false,
        shouldShowEndscreen: false,
        shouldLoadEndscreenData: false,
        creditsStartTime: Infinity,
        isBeforeCreditStart: false,
      }
    }

    default: {
      return state
    }
  }
}

type PlayerContextProviderProps = {
  children: ReactNode
}

const getInitialState = (): PlayerState => ({
  isFullscreen: false,
  hasVideoStarted: false,
  hasVideoEnded: false,
  hasCreditsStarted: false,
  hasDismissedAutomaticEndAction: true,
  shouldShowEndscreen: false,
  shouldLoadEndscreenData: false,
  isPlayerSkinVisible: false,
  creditsStartTime: Infinity,
  isBeforeCreditStart: false,
})

export const PlayerContextProvider = ({ children }: PlayerContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  return (
    <PlayerStateContext.Provider value={state}>
      <PlayerDispatchContext.Provider value={dispatch}>{children}</PlayerDispatchContext.Provider>
    </PlayerStateContext.Provider>
  )
}

export const usePlayerState = () => {
  const state = useContext(PlayerStateContext)

  if (typeof state === 'undefined') {
    throw new Error('usePlayerState must be used within a PlayerContext')
  }

  return state
}

export const usePlayerDispatch = () => {
  const dispatch = useContext(PlayerDispatchContext)

  if (typeof dispatch === 'undefined') {
    throw new Error('usePlayerDispatch must be used within a PlayerContext')
  }

  return dispatch
}
