import { css } from 'styled-components'

export function mediaCondition(condition: string) {
  return (...args: Parameters<typeof css>) => css`
    @media ${condition} {
      ${css(...args)}
    }
  `
}

export function mediaMinWidth(breakpoint: number) {
  return mediaCondition(`(min-width: ${breakpoint}px)`)
}
