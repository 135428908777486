import type { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import type { ChannelFieldsFragment, EpgFieldsFragment } from '@nordic-web/gql'
import { Dialog, Image, Stack, TypographyText } from '@nordic-web/ui-components'
import { FadeInImage } from '@/components/fade-in-image'
import { formatStartEndTime } from '@/utils/date'

const LogoImage = styled(Image)({
  width: 20,
  height: 'fit-content',
})

type SharedDetailsProps = {
  channel: ChannelFieldsFragment
  epgItem: EpgFieldsFragment
  title: string
  synopsis?: string | null
  image?: string
  metaSection?: React.JSX.Element
}

const TimeText = styled(TypographyText)({
  whiteSpace: 'nowrap',
})

export const SharedDetails: FC<PropsWithChildren<SharedDetailsProps>> = ({
  channel,
  title,
  epgItem,
  synopsis,
  image,
  metaSection,
  children,
}) => {
  const channelLogo = channel.images.logo?.sourceEncoded
  return (
    <>
      {image ? (
        <Dialog.ImageTop>
          <FadeInImage width={800} height={450} source={image} />
        </Dialog.ImageTop>
      ) : (
        <Dialog.Top />
      )}
      <Dialog.Body>
        <Stack nwDirection="row" nwGap={3} nwAlignItems="center" nwJustifyContent="space-between">
          <TypographyText nwVariant="title3">{title}</TypographyText>
          <Stack nwDirection="row" nwGap={2} nwAlignItems="center">
            {channelLogo && <LogoImage width={50} height={0} src={channelLogo} alt={channel.title} />}
            <TimeText nwColor="secondary" nwVariant="body3Strong">
              {formatStartEndTime(epgItem.start, epgItem.end)}
            </TimeText>
          </Stack>
        </Stack>
        {metaSection}
        {synopsis && <TypographyText nwVariant="body3">{synopsis}</TypographyText>}
        {children}
      </Dialog.Body>
    </>
  )
}
