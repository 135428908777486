import { useRef, useState } from 'react'
import { detectTouch } from '@/utils/detect-touch'

type useHoveredProps = {
  delay?: number
  enable?: boolean
}

export const useHovered = ({ delay = 0, enable = !detectTouch() }: useHoveredProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const timerRef = useRef<ReturnType<typeof setTimeout>>()

  const onMouseEnter = () => {
    if (!enable) {
      return
    }

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setIsHovered(true)
    }, delay)
  }

  const onMouseLeave = () => {
    if (!enable) {
      return
    }

    clearTimeout(timerRef.current)
    timerRef.current = undefined
    setIsHovered(false)
  }

  return {
    onMouseEnter,
    onMouseLeave,
    isHovered: enable && isHovered,
  }
}
