import type { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { type TextAlign } from '../../../types/component-props'
import { TypographyText } from '../typography-text/typography-text'

export type TypographyPreambleProps = TextAlign

/**
 * Used by TypographyTitle, which is the preferred way to create headings and preambles,
 * but this can be used standalone in special cases.
 */
export const TypographyPreamble = ({
  children,
  nwTextAlign = 'center',
}: PropsWithChildren<TypographyPreambleProps>) => {
  return (
    <Text as="p" nwVariant="body3" nwTextAlign={nwTextAlign === 'center' ? 'center' : 'initial'}>
      {children}
    </Text>
  )
}

const Text = styled(TypographyText)(({ theme }) => ({
  color: theme.color.text.secondary,
}))
