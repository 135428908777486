import type { OpenGraphMedia } from 'next-seo/lib/types'
import { ImageProxyService } from '@/helpers/image-proxy-service'

// Im not 100% sure what sizes we should send here, but 1200 is one recommended size
const sizes = [[1200, 630]] as const

export const getOgImages = (imageSource?: string): OpenGraphMedia[] => {
  if (!imageSource) {
    return []
  }

  return sizes.map(([width, height]) => ({
    url: ImageProxyService.url({ url: imageSource, width }),
    width,
    height,
  }))
}
