import type { PropsWithChildren } from 'react'
import React, { createContext, useCallback, useState } from 'react'

type TrailersMutedState = ReturnType<typeof useTrailersMutedState>

const MUTED_STATE_LOCALSTORAGE_KEY = 'muted'

export const TrailersMutedStateContext = createContext<TrailersMutedState | undefined>(undefined)

const getMuted = () => {
  try {
    const muted = localStorage.getItem(MUTED_STATE_LOCALSTORAGE_KEY)
    if (!muted) {
      return true
    }
    return muted === 'true'
  } catch (e) {
    // If we cannot read from the storage, default to being muted.
    return true
  }
}

function useTrailersMutedState() {
  const [isMuted, setMuted] = useState(getMuted())

  const onSetMuted = useCallback(
    (muted: boolean) => {
      setMuted(muted)

      try {
        localStorage.setItem(MUTED_STATE_LOCALSTORAGE_KEY, `${muted}`)
      } catch (e) {
        // Ignore this error. Not critical if we cannot store it to session.
      }
    },
    [setMuted]
  )

  return {
    isMuted,
    setIsMuted: onSetMuted,
  }
}

export function TrailersMutedStateProvider({ children }: PropsWithChildren<unknown>) {
  const value = useTrailersMutedState()

  return <TrailersMutedStateContext.Provider value={value}>{children}</TrailersMutedStateContext.Provider>
}
