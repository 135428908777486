import React from 'react'
import { useTranslations } from 'next-intl'
import { useForm } from 'react-hook-form'
import {
  Button,
  ButtonGroup,
  FormInputPassword,
  Stack,
  TypographyText,
  useFormInputNumberMaxLength,
} from '@nordic-web/ui-components'

type PinCodeForm = {
  pinCode: string
}

type EditPinCodeFormProps = {
  errorMessage?: string
  loading: boolean
  pinCode: string
  disabled: boolean
  isCancellable: boolean
  onSubmit: (params: { pinCode: string }) => void
  onCancel?: () => void
}

export const EditPinCode = ({
  errorMessage,
  loading,
  onSubmit,
  onCancel,
  disabled,
  isCancellable,
}: EditPinCodeFormProps) => {
  const t = useTranslations()
  const { register, handleSubmit, watch, setValue } = useForm<PinCodeForm>()
  const watchPinCode = watch('pinCode')

  useFormInputNumberMaxLength<PinCodeForm>({
    fieldName: 'pinCode',
    maxLength: 4,
    watchValue: watchPinCode,
    setValue,
  })

  return (
    <form
      data-test="edit-childlock"
      onSubmit={handleSubmit((data) => {
        onSubmit(data)
      })}
    >
      <Stack nwGap={4}>
        <FormInputPassword
          nwLabel={t('settings__child_lock__input_label')}
          disabled={disabled}
          validators={register('pinCode')}
          required
        />
        {!!errorMessage && !loading && (
          <TypographyText nwVariant="smallText" nwColor="error">
            {errorMessage}
          </TypographyText>
        )}

        <ButtonGroup nwAxis="y">
          <Button data-test="save-form" disabled={loading} nwLoading={loading} type="submit">
            {t('general__save_cta')}
          </Button>
          {isCancellable && (
            <Button data-test="cancel-form" onClick={onCancel} nwVariant="subtle">
              {t('general__cancel')}
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    </form>
  )
}
