import React from 'react'
import { useTranslations } from 'next-intl'
import type { SportEventFieldsLightFragment } from '@nordic-web/gql'
import {
  CardBrandLogoContainer,
  CardContainer,
  CardFooterContainer,
  CardFooterTextContainer,
  CardImageContainer,
  CardLabelContainer,
  CardMainText,
  CardSecondaryText,
  ResponsiveImage,
} from '@nordic-web/ui-components'
import { useSportEventMeta } from '@nordic-web/utils/hooks/use-sport-event-meta'
import { isUpsellMedia } from '@nordic-web/utils/misc/is-upsell-media'
import type { OnCardClick } from '@/components/cards/types'
import { DotRow } from '@/components/dot-row'
import { FadeInImage } from '@/components/fade-in-image'
import { LabelFactory } from '@/components/label-factory'
import { usePrefetchCdpQuery } from '@/components/prefetch-links/cdp-prefetch-link'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type SportEventCardProps = {
  content: SportEventFieldsLightFragment
  onCardClick?: OnCardClick
}

export const SportEventCard = ({ content, onCardClick }: SportEventCardProps) => {
  const t = useTranslations()
  const isUpsell = isUpsellMedia(content)

  const handleClick = () => {
    if (!onCardClick) return

    onCardClick(content)
  }

  const meta = useSportEventMeta(t('general__studio'), content)

  const prefetch = usePrefetchCdpQuery(content.id)

  const logo = content.images.brandLogo?.sourceEncoded

  return (
    <AssetTracking
      content_media_id={content.id}
      upsell={isUpsell ? { item: 'card', package: content.upsell?.packageTierLink.packageId } : null}
    >
      {({ trackOnAssetClick }) => (
        <CardContainer
          href={paths.program.urlString({ id: content.id, slug: content.slug })}
          onMouseEnter={prefetch}
          onClick={() => {
            trackOnAssetClick()
            handleClick()
          }}
        >
          <CardImageContainer>
            <FadeInImage alt={content.title} source={content.images.main16x9.sourceEncoded} />
            <CardLabelContainer>
              <LabelFactory media={content} nwPlacement="corner" />
            </CardLabelContainer>
            {logo && (
              <CardBrandLogoContainer>
                <ResponsiveImage height={16} width={100} alt="" src={logo} />
              </CardBrandLogoContainer>
            )}
          </CardImageContainer>
          <CardFooterContainer>
            <CardFooterTextContainer>
              <CardMainText>{content.title}</CardMainText>
              <CardSecondaryText>
                <DotRow items={[content.league, content.round, meta]} />
              </CardSecondaryText>
            </CardFooterTextContainer>
          </CardFooterContainer>
        </CardContainer>
      )}
    </AssetTracking>
  )
}
