import { useCallback, useState } from 'react'
import { useEpgLazyQuery } from '@nordic-web/gql'
import { useInterval } from '@nordic-web/utils/hooks/use-interval'
import { useGlobalEventListener } from '@/hooks/use-global-events'

/** 10 minutes */
const POLL_INTERVAL = 1000 * 60 * 10

export const useUpdateEpg = (channelId: string, enabled = true) => {
  const [isTabActive, setIsTabActive] = useState(true)
  const [fetch] = useEpgLazyQuery()

  const fetchEpg = useCallback(() => {
    if (!enabled) return

    fetch({
      fetchPolicy: 'network-only',
      context: { batch: true },
      variables: {
        id: channelId,
        fullBroadcastDay: false,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, enabled])

  useGlobalEventListener('visibilitychange', () => {
    const isActive = !document.hidden
    setIsTabActive(isActive)
    if (isActive) fetchEpg()
  })

  useInterval(fetchEpg, isTabActive ? POLL_INTERVAL : null)
}
