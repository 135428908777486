// https://www.30secondsofcode.org/react/s/use-interval-explained/
import { useEffect, useRef } from 'react'

type Callback = () => void | Promise<void>

/**
 * useInterval() is disabled when `delay === null`
 */
export const useInterval = (callback: Callback, delay: number | null) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    if (delay === null) {
      // No interval when delay === null
      return
    }

    async function tick() {
      const result = savedCallback.current()
      // Check if it's a promise
      if (result instanceof Promise) {
        await result
      }
    }

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
