export const spaceFactorMap = [
  0, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 24, 26, 32, 48, 64, 128,
] as const

export type SpaceFactor = (typeof spaceFactorMap)[number]

export const spaceBase = 4

export const spacePx = (value: SpaceFactor) => `${value * spaceBase}px`

export const space = (value: SpaceFactor) => value * spaceBase
