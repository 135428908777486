import type { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import ErrorComponent from '@/pages/_error.page'

type ErrorHandlerProps = {
  readonly statusCode: number | string
  readonly error?: ApolloError
}

class ErrorWithStatusCode extends Error {
  statusCode: string | number
  constructor(message: string, statusCode: string | number) {
    super(message)
    this.statusCode = statusCode
  }
}

export const ErrorHandler = ({ statusCode, error }: ErrorHandlerProps): React.JSX.Element => {
  const router = useRouter()

  if (isClientSide) {
    return <ErrorComponent statusCode={typeof statusCode === 'string' ? parseInt(statusCode) : statusCode} />
  }

  const err = error || new ErrorWithStatusCode(`${statusCode} ${router.asPath}`, statusCode)

  throw err
}
