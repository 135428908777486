import Bugsnag from '@bugsnag/js'
import { type UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import {
  ApiError,
  OpenAPI,
  type PaymentDetailsBody,
  type PaymentDetailsRequest,
  type ResultCodeEnum,
  type UserOrder,
} from '@nordic-web/rest-codegen/generated/subscription'
import * as SubscriptionService from '@nordic-web/rest-codegen/generated/subscription'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { authenticationStore, useAuthenticationStore } from '@/features/auth/authentication-store'
import { useCheckoutState } from '@/features/checkout/context/checkout-state-context'
import { nextConfig } from '@/helpers/env'
import { stringFromQueryParam } from '@/utils/query-string'

OpenAPI.BASE = nextConfig.string('SUBSCRIPTION_API')

export type PayFunction = UseMutateFunction<
  { action: string; resultCode: ResultCodeEnum; order: UserOrder },
  unknown,
  Record<string, unknown>,
  unknown
>

export type Pay3dsFunction = UseMutateFunction<
  { action: string; resultCode: ResultCodeEnum; order: UserOrder },
  unknown,
  PaymentDetailsRequest,
  unknown
>

export type PayWithSavedCardFunction = UseMutateFunction<
  { action: string; resultCode: ResultCodeEnum; order: UserOrder },
  unknown,
  { vimondId: string; voucherCode?: string },
  unknown
>

const onError = (error: ApiError) => {
  console.error(`subscription api error: ${error}`)

  Bugsnag.notify('We got an error from the subscription API', (event) => {
    event.severity = 'warning'
    event.addMetadata('details', {
      errorCode: error?.status,
    })
  })
}

export function withSubscriptionApiErrorCallback<T extends (...args: unknown[]) => unknown>(queryFn: T) {
  return (...args: Parameters<T>) => {
    try {
      return queryFn(...args) as ReturnType<T>
    } catch (error) {
      if (error instanceof ApiError) {
        onError(error)
      }
      throw error
    }
  }
}

export const subscriptionQueryKey = 'subscription'
export const voucherQueryKey = [subscriptionQueryKey, 'voucher']
export const packageKey = ['packages']

export const useSubscription = (entitlementId?: string, voucherCodeOverride?: string) => {
  const { isLoggedIn } = useAuthenticationStore()
  const router = useRouter()
  const packageId = entitlementId || (stringFromQueryParam(router.query?.packageId) ?? '')
  const voucherCode = voucherCodeOverride ?? stringFromQueryParam(router.query?.voucherCode)

  const queryClient = useQueryClient()
  const updateSubscriptionData = () => {
    queryClient.fetchQuery({ queryKey: [subscriptionQueryKey] })
    queryClient.fetchQuery({ queryKey: [subscriptionQueryKey, ...packageKey] })
  }

  const { isCheckingOutWithBinding } = useCheckoutState()

  const subscriptionState = useQuery({
    queryKey: [subscriptionQueryKey],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getSubscriptionStateForUser({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: isLoggedIn,
    staleTime: Infinity,
    gcTime: Infinity,
  })

  const subscriptionPackageDetails = useQuery({
    queryKey: [subscriptionQueryKey, ...packageKey],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getSubscriptionPackageDetails({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: isLoggedIn,
    staleTime: Infinity,
    gcTime: Infinity,
  })

  const subscriptionName = useQuery({
    queryKey: [subscriptionQueryKey, ...packageKey, 'name'],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getSubscriptionName({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  const paymentMethods = useQuery({
    queryKey: [subscriptionQueryKey, 'paymentMethods'],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getPaymentMethods({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  const activePaymentMethod = useQuery({
    queryKey: [subscriptionQueryKey, 'activePaymentMethod'],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getActivePaymentMethod({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  const receipts = useQuery({
    queryKey: [subscriptionQueryKey, 'receipts'],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getReceipts({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  const allReceipts = useQuery({
    queryKey: [subscriptionQueryKey, 'receipts', 'all'],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getAllReceipts({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  const latestReceipt = useQuery({
    queryKey: [subscriptionQueryKey, 'latestReceipt'],
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getLatestReceipt({
        authorization: formatAuthorizationHeader(token),
      })
    }),
    enabled: false,
  })

  const voucher = useQuery({
    queryKey: voucherQueryKey,
    queryFn: withSubscriptionApiErrorCallback(async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.getSingleVoucher({
        authorization: formatAuthorizationHeader(token),
        voucherCode: voucherCode ?? '',
        vimondId: packageId,
      })
    }),
    enabled: false,
    retry: false,
  })

  const purchaseMutation = useMutation({
    mutationFn: async (data: Record<string, unknown>) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.postPayment({
        authorization: formatAuthorizationHeader(token),
        requestBody: {
          paymentMethod: data.paymentMethod,
          browserInfo: data.browserInfo,
          riskData: data.riskData,
          vimondId: packageId,
          voucherCode: voucher.data?.valid ? voucher.data?.code : undefined,
          withBinding: isCheckingOutWithBinding,
        },
      })
    },
    onError,
  })

  const completePurchaseWithFullPriceMutation = useMutation({
    mutationFn: async (transactionId: string) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.postPaymentAcceptFullPrice({
        authorization: formatAuthorizationHeader(token),
        requestBody: {
          transactionId,
        },
      })
    },
  })

  const complete3DSMutation = useMutation({
    mutationFn: async (details: PaymentDetailsBody) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.postPaymentDetails({
        authorization: formatAuthorizationHeader(token),
        requestBody: details,
      })
    },
    onError,
  })

  const downgradeSubscriptionMutation = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.downgradeActiveSubscription({
        authorization: formatAuthorizationHeader(token),
        productGroupId: packageId,
      })
    },
    onError,
    onSuccess: updateSubscriptionData,
  })

  const reactivateSubscriptionMutation = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.reactivateSubscription({
        authorization: formatAuthorizationHeader(token),
      })
    },
    onError,
    onSuccess: updateSubscriptionData,
  })

  const zeroPaymentMutation = useMutation({
    mutationFn: async (data: Record<string, unknown>) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.addUserPaymentMethod({
        authorization: formatAuthorizationHeader(token),
        requestBody: {
          paymentMethod: data.paymentMethod,
          browserInfo: data.browserInfo,
          riskData: data.riskData,
          vimondId: packageId,
        },
      })
    },
  })

  const zeroPayment3DsMutation = useMutation({
    mutationFn: async (details: PaymentDetailsRequest) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.addPaymentMethodDetails({
        authorization: formatAuthorizationHeader(token),
        requestBody: details,
      })
    },
    onError,
  })

  const setActivePaymentMethod = useMutation({
    mutationFn: async (paymentMethodId: string) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.changePaymentMethod({
        authorization: formatAuthorizationHeader(token),
        paymentMethodId,
      })
    },
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [subscriptionQueryKey, 'activePaymentMethod'] })
    },
  })

  const deletePaymentMethod = useMutation({
    mutationFn: async (paymentMethodId: string) => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.removePaymentMethod({
        authorization: formatAuthorizationHeader(token),
        paymentMethodId,
      })
    },
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [subscriptionQueryKey, 'paymentMethods'] })
    },
  })

  const addBindingToSubscription = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return SubscriptionService.addBindingToActiveSubscription({
        authorization: formatAuthorizationHeader(token),
      })
    },
    onError,
    onSuccess: updateSubscriptionData,
  })

  return {
    subscriptionState,
    subscriptionPackageDetails,
    subscriptionName,
    purchaseMutation,
    complete3DSMutation,
    completePurchaseWithFullPriceMutation,
    activePaymentMethod,
    paymentMethods,
    downgradeSubscriptionMutation,
    receipts,
    allReceipts,
    latestReceipt,
    reactivateSubscriptionMutation,
    zeroPaymentMutation,
    zeroPayment3DsMutation,
    setActivePaymentMethod,
    deletePaymentMethod,
    voucher,
    addBindingToSubscription,
  }
}
