import { brandConfig } from '@/config/brand'
import { paths } from '@/helpers/paths'
import type { FooterColumnConfig } from '.'

export const columnLinksTv4: FooterColumnConfig[] = [
  {
    header: 'Snabblänkar',
    links: [
      {
        title: 'Program A-Ö',
        url: paths.allPrograms.urlString(),
      },
      {
        title: 'TV4-Tolken',
        url: paths.page.urlString({ id: 'tv4-tolken' }),
      },
      {
        title: 'Alla kategorier',
        url: paths.page.urlString({ id: 'alla' }),
      },
    ],
  },
  {
    header: 'Hjälp',
    links: [
      {
        title: 'Allmänna användarvillkor',
        url: paths.article.urlString({ articleId: brandConfig.gdprLinks.termsOfUse }),
      },
      {
        title: 'Programfrågor',
        url: paths.faq.urlString({
          section: 'humany-tv4play=/c9093-konto-och-inloggning&humany-tv4-play=/browse/c376-program-och-innehall',
        }),
      },
      {
        title: 'Tekniska frågor',
        url: paths.faq.urlString({
          section: 'humany-tv4play=/c9093-konto-och-inloggning&humany-tv4-play=/browse/c132-teknik',
        }),
      },
      {
        title: 'Inloggningsfrågor',
        url: paths.faq.urlString({
          section: 'humany-tv4play=/c9093-konto-och-inloggning&humany-tv4-play=/browse/c133-inloggning',
        }),
      },
    ],
  },
  {
    header: 'Mitt TV4 Play',
    links: [
      {
        title: 'Skapa konto',
        url: `/?${brandConfig.authQueryParameters.signUp}`,
        isSignup: true,
      },
      {
        title: 'Logga in på din TV',
        url: 'https://aktivera.tv4play.se',
      },
      {
        title: 'Glömt lösenord',
        url: paths.forgotPassword.urlString(),
      },
    ],
  },
  {
    header: 'Övrigt',
    links: [
      {
        title: 'Annonsera i TV4',
        url: 'https://annonsera.tv4.se',
      },
      {
        title: 'Om oss',
        url: paths.article.urlString({ articleId: 'om-oss' }),
      },
      {
        title: 'Om cookies',
        url: paths.article.urlString({ articleId: brandConfig.gdprLinks.cookiePolicy }),
      },
      {
        title: 'Cookie-inställningar',
        isCookieSettings: true,
      },
      {
        title: 'Personuppgiftspolicy',
        url: paths.article.urlString({ articleId: brandConfig.gdprLinks.privacyPolicy }),
      },
      {
        title: 'Kontakta TV4',
        url: paths.faq.urlString({ section: 'humany-tv4-play=/g1016-hur-kontaktar-jag-tv4' }),
      },
      {
        title: 'Lediga jobb',
        url: 'https://jobb.tv4.se/',
      },
    ],
  },
  {
    header: 'Besök också',
    links: [
      {
        title: 'tv4.se',
        url: 'https://www.tv4.se',
      },
      {
        title: 'köket.se',
        url: 'https://www.koket.se',
      },
      {
        title: 'fotbollskanalen.se',
        url: 'https://www.fotbollskanalen.se',
      },
    ],
  },
]
