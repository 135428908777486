import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

const ttlMap: Record<string, number | null | undefined> = {}

const isOld = (key: string, ttl: number) => {
  const storedAt = ttlMap[key]
  if (storedAt) {
    return new Date().getTime() - storedAt > ttl
  }

  return false
}

type UseRefreshOldCacheEntryProps = {
  id: string
  type: string
  ttl: number
}

export const useEvictOldCacheEntry = ({ id, type, ttl }: UseRefreshOldCacheEntryProps) => {
  const { cache } = useApolloClient()

  useEffect(() => {
    const cacheId = cache.identify({
      __typename: type,
      id,
    })

    if (!cacheId) return

    if (isOld(cacheId, ttl)) {
      ttlMap[cacheId] = null
      cache.evict({ id: cacheId })
    }

    if (!ttlMap[cacheId]) {
      ttlMap[cacheId] = new Date().getTime()
    }
  }, [cache, id, ttl, type])
}
