import { getPageName } from '@/helpers/get-page-name'
import { dataLayerPush } from '@/tracking/tracking'

type DevEvent = {
  label1: 'impression' | 'click' | 'change' | 'statistics'
  /**
   * The place/component where the event is sent from
   * @example 'SearchModal'
   */
  label2?: string
  label3?: string | number | null
}
export const trackDevEvent = (event: DevEvent) => {
  dataLayerPush({
    event: 'dev_event',
    page_name: getPageName(),
    ...event,
  })
}
