import styled from 'styled-components'
import { TypographyText } from '../../typography/typography-text/typography-text'
import { FormInputBase } from '../_base/form-input-base/form-input-base'

export const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(4),
  ...theme.typography.info,
}))

export const Label = styled(TypographyText)({
  alignSelf: 'center',
  cursor: 'pointer',
})

export const Input = styled(FormInputBase)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  borderWidth: 2,
  padding: 3,
  borderColor: theme.color.border['50'],
  borderStyle: 'solid',
  margin: '3px 3px 3px 4px',
  appearance: 'none',
  height: 24,
  width: 24,
  cursor: 'pointer',
  borderRadius: '50%',

  '&:active': {
    background: theme.color.form.active,
  },

  '&:before': {
    content: '""',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: '50ms transform ease-in-out',
    backgroundColor: theme.color.surface.white100,
  },

  '&:hover, &:focus': {
    borderColor: theme.color.border['100'],
  },

  '&:before:[aria-invalid="true"]': {
    backgroundColor: theme.color.base.primaryLight,
  },

  '&:checked:before': {
    transform: 'scale(1)',
  },
}))
