import { useEffect } from 'react'

const publishEvent = (eventName: string, detail: LegitimateAny) => {
  const event = new CustomEvent(eventName, { detail })
  document.dispatchEvent(event)
}

const useGlobalEventListener = (eventName: string, listener: EventListenerOrEventListenerObject) => {
  useEffect(() => {
    document.addEventListener(eventName, listener)
    return () => document.removeEventListener(eventName, listener)
  }, [eventName, listener])
}

export { useGlobalEventListener, publishEvent }
