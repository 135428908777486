export const nwSizeStyles = {
  mini: {
    width: 24,
    height: 24,
  },
  small: {
    width: 32,
    height: 32,
  },
  large: {
    width: 88,
    height: 88,
  },
}

export const ImageExample = 'https://nordic-static-assets.a2d.tv/tv4/avatars/1.png'
