import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useWebSocket from 'react-use-websocket'
import type { Options as WebsocketOptions } from 'react-use-websocket'
import * as RenoService from '@nordic-web/rest-codegen/generated/reno'
import { useInterval } from '@nordic-web/utils/hooks/use-interval'

type RenoResponse = Awaited<ReturnType<typeof RenoService.getNotifications>>
type RenoNotification = RenoResponse['notifications'][number]

/** The time between two long polling requests */
const RENO_POLLING_INTERVAL = 20_000

type RenoConfig = {
  apiUrl: string
  clientName: string
  clientVersion: string
  userAgent?: string
  getAccessToken: () => Promise<string | undefined>
}

function configureRenoApi(config: RenoConfig) {
  RenoService.OpenAPI.TOKEN = async () => {
    const token = await config.getAccessToken()
    return token ?? ''
  }
  RenoService.OpenAPI.BASE = config.apiUrl
  RenoService.OpenAPI.HEADERS = {
    'client-name': config.clientName,
    'client-version': config.clientVersion,
  }
}

type Options = {
  /** The timestamp of the last seen notifications, it's used to only fetch newer notifications */
  lastSeen: number
  getAccessToken: () => Promise<string | undefined>
  onError?: (error: Error) => void
  skip?: boolean
} & RenoConfig

export const useReno = (options: Options) => {
  const { lastSeen, apiUrl, clientName, clientVersion, getAccessToken, onError, skip } = options

  configureRenoApi({ apiUrl, clientName, clientVersion, getAccessToken })

  const [isSocketConnected, setIsSocketConnected] = useState(false)

  const shouldPoll = !skip && !isSocketConnected

  const {
    data: renoData,
    error,
    refetch,
  } = useQuery({
    queryKey: ['reno'],
    enabled: shouldPoll,
    staleTime: Infinity,
    queryFn: () => RenoService.getNotifications({ since: lastSeen })
  })

  // If we use the built in poll interval in react-query, it ends up in a state sometimes when it
  // fetches even though it doesn't have a valid access token.
  useInterval(async () => {
    if (shouldPoll) {
      refetch()
    }
  }, RENO_POLLING_INTERVAL)

  useEffect(() => {
    if (error && onError) {
      onError(error)
    }
  }, [error, onError])

  const socketUrl = renoData?.realtimeStream?.url ?? null
  const shouldConnect = !!socketUrl

  const websocketOptions: WebsocketOptions = {
    headers: {
      'User-Agent': options.userAgent ?? '',
    },
    onOpen: () => {
      setIsSocketConnected(true)
    },
    onClose: () => {
      setIsSocketConnected(false)
    },
    retryOnError: true,
    reconnectAttempts: 15,
    heartbeat: {
      interval: 60_000 * 5,
      timeout: 60_000 * 10,
    },
    // Filter out heartbeat messages so that we don't get them in lastMessage
    filter: (message) => message.data !== 'pong',
    reconnectInterval: (lastAttemptNumber: number) => Math.min(1000 * 2 ** lastAttemptNumber),
  }

  const getUrl = useCallback(async () => {
    const token = await getAccessToken()
    return socketUrl + `?token=${token}`
  }, [getAccessToken, socketUrl])

  const socket = useWebSocket<RenoNotification>(getUrl, websocketOptions, shouldConnect)

  return { socketNotification: socket.lastJsonMessage, httpNotifications: renoData?.notifications }
}
