import type { MovieFieldsLightFragment } from '@nordic-web/gql'
import { Label, LabelGroup, type LabelPropsOptionals } from '@nordic-web/ui-components'
import { VideoLiveLabels } from '@/components/video-live-labels'

type Upsell = {
  upsell?: {
    tierId?: string
    labelText: string
  } | null
}

// We can pick it from movie since it contains all the fields needed for all content types
type InputMedia = Partial<
  Pick<MovieFieldsLightFragment, 'label' | 'playableFrom' | 'liveEventEnd' | 'isLiveContent' | 'playableUntil'>
> &
  Upsell

export type LabelFactoryProps = {
  media: InputMedia
  showOnlyUpsellLabel?: boolean
} & LabelPropsOptionals

export const LabelFactory = ({ media, nwPlacement = 'default', showOnlyUpsellLabel }: LabelFactoryProps) => {
  const isUpsell = !!media.upsell
  const upsellText = media.upsell?.labelText
  const editorialLabels = media.label
  const { isLiveContent, playableFrom, playableUntil } = media
  const labelProps = { nwPlacement }

  const isLiveEvent = isLiveContent && playableFrom

  if (showOnlyUpsellLabel) {
    return isUpsell ? (
      <Label nwVariant="plus" {...labelProps}>
        {upsellText}
      </Label>
    ) : null
  }

  if (isUpsell) {
    if (editorialLabels?.airtime || isLiveEvent) {
      return (
        <LabelGroup {...labelProps}>
          <Label nwVariant="plus">{upsellText}</Label>
          {isLiveEvent ? (
            <VideoLiveLabels playableFrom={playableFrom} liveEventEnd={media.liveEventEnd} />
          ) : (
            <Label nwVariant="airtime">{editorialLabels?.airtime}</Label>
          )}
        </LabelGroup>
      )
    }

    return (
      <Label nwVariant="plus" {...labelProps}>
        {upsellText}
      </Label>
    )
  }

  if (isLiveContent && playableFrom) {
    return <VideoLiveLabels playableFrom={playableFrom} liveEventEnd={media.liveEventEnd} labelProps={labelProps} />
  }

  if (playableUntil?.readableRemaining) {
    return (
      <Label nwVariant="remaining" {...labelProps}>
        {playableUntil.readableRemaining}
      </Label>
    )
  }

  if (editorialLabels?.airtime) {
    return (
      <Label nwVariant="airtime" {...labelProps}>
        {editorialLabels.airtime}
      </Label>
    )
  }

  if (editorialLabels?.announcement) {
    return (
      <Label nwVariant="announcement" {...labelProps}>
        {editorialLabels.announcement}
      </Label>
    )
  }
  if (editorialLabels?.recurringBroadcast) {
    return (
      <Label nwVariant="reoccurring" {...labelProps}>
        {editorialLabels.recurringBroadcast}
      </Label>
    )
  }

  return null
}
