import type { ComponentPropsWithRef } from 'react'
import { forwardRef } from 'react'
import type { DefaultTheme } from 'styled-components'
import styled from 'styled-components'
import type { IconVariant } from '../../icon/icon/icon'
import { Button, type ButtonElements } from '../button/button'

export const buttonTimedVariants = ['primary', 'secondary'] as const
export type ButtonTimedVariant = (typeof buttonTimedVariants)[number]

export type ButtonTimedProps = {
  nwIcon?: IconVariant
  children: string
  nwVariant?: ButtonTimedVariant
  nwProgress: number
  className?: string
  as?: ButtonElements
} & ComponentPropsWithRef<'button'>

export const ButtonTimed = forwardRef<HTMLButtonElement, ButtonTimedProps>(function ButtonTimed(
  { nwProgress, nwVariant = 'primary', children, nwIcon, as, className, ...forwardProps },
  ref
) {
  return (
    <StyledButton
      ref={ref}
      nwIconFirst={nwIcon}
      nwVariant={nwVariant}
      className={className}
      aria-label={forwardProps['aria-label'] || children}
      forwardedAs={as}
      nwProgress={nwProgress}
      {...forwardProps}
    >
      {children}
    </StyledButton>
  )
})

function getVariantVars(
  theme: DefaultTheme,
  nwVariant: ButtonTimedVariant
): {
  backgroundHighlightColor: string
  backgroundDefaultColor: string
  backgroundActiveColor: string
  textColor: string
} {
  switch (nwVariant) {
    case 'primary':
      return {
        backgroundHighlightColor: theme.color.base.white,
        backgroundDefaultColor: theme.color.surface.white50,
        backgroundActiveColor: theme.color.surface.white50,
        textColor: theme.color.text.primaryInverted,
      }

    default:
      return {
        backgroundHighlightColor: theme.color.button[nwVariant].hover,
        backgroundDefaultColor: theme.color.button[nwVariant].default,
        backgroundActiveColor: theme.color.button[nwVariant].pressed,
        textColor: theme.color.button[nwVariant].text,
      }
  }
}

const StyledButton = styled(Button).attrs<
  { nwProgress: number; nwVariant: ButtonTimedVariant } & Partial<ReturnType<typeof getVariantVars>>
>(({ theme, nwVariant, nwProgress }) => {
  const variantVars = getVariantVars(theme, nwVariant)
  return {
    ...variantVars,
    style: {
      // since progress changes often the recommendation is to place the often-changing style using `.attrs()`
      background: `linear-gradient(to right, ${variantVars.backgroundHighlightColor} ${nwProgress * 100}%, ${variantVars.backgroundDefaultColor} ${nwProgress * 100}%)`,
    },
  }
})(({ backgroundHighlightColor, backgroundActiveColor, textColor }) => ({
  color: textColor,

  '&:hover': {
    // !important so this will override the `.attrs()` background style
    background: `${backgroundHighlightColor} !important`,
  },

  '&:active': {
    // !important so this will override the `.attrs()` background style
    backgroundColor: `${backgroundActiveColor} !important`,
  },

  '& svg': {
    fill: textColor,
  },
}))
