import getConfig from 'next/config'

type VariableType = boolean | number | string

type Variable = {
  name: string
  value: undefined | VariableType
}

const get = (env: Record<string, VariableType>, variable: string): Variable => ({
  name: variable,
  value: env[variable],
})

const toBool = ({ name, value }: Variable, fallback?: boolean) => {
  if (typeof value === 'boolean') {
    return value
  }

  try {
    return Boolean(JSON.parse('' + value))
  } catch (error) {
    if (typeof fallback === 'undefined') {
      throw new Error(`
        environment variable '${name}' is either:
          1) not convertible to boolean, or
          2) undefined and no fallback value was provided

          value => '${value}'
      `)
    }

    return fallback
  }
}

const toNumber = ({ name, value }: Variable, fallback?: number) => {
  if (typeof value === 'number') {
    return value
  }

  const num = parseInt('' + value)

  if (isNaN(num)) {
    if (typeof fallback === 'undefined') {
      throw new Error(`
        environment variable '${name}' is either:
          1) not convertible to number, or
          2) undefined and no fallback value was provided

          value => '${value}'
      `)
    }

    return fallback
  }

  return num
}

const toString = ({ name, value }: Variable, fallback?: string) => {
  if (typeof value === 'undefined') {
    if (typeof fallback === 'undefined') {
      throw new Error(`environment variable '${name}' is undefined and no fallback value was provided`)
    }

    return fallback
  }

  return '' + value
}

type Environment = {
  bool: (variable: string, fallback?: boolean) => boolean
  number: (variable: string, fallback?: number) => number
  string: (variable: string, fallback?: string) => string
}

const wrap = (getEnv: () => Record<string, string>): Environment => ({
  bool: (variable: string, fallback?: boolean): boolean => toBool(get(getEnv(), variable), fallback),
  number: (variable: string, fallback?: number): number => toNumber(get(getEnv(), variable), fallback),
  string: (variable: string, fallback?: string): string => toString(get(getEnv(), variable), fallback),
})

// We want to be able to setConfig in our storybook, so defer calling getConfig() as long as possible.
export const nextConfig = wrap(() => {
  return getConfig().publicRuntimeConfig
})

export const env = wrap(() => (process?.env as Record<string, string>) ?? {})
