import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { TypographyText } from '../../typography/typography-text/typography-text'
import { FormLayoutRows } from '../form-layout-rows/form-layout-rows'

export type FormFieldsetProps = {
  nwLegend: string
} & PropsWithChildren<ComponentPropsWithoutRef<'fieldset'>>

/**
 * Group related form controls together. This should wrap radio button groups, related checkboxes etc.
 * See this for more info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset
 */
export const FormFieldset = ({ children, nwLegend, ...fieldsetProps }: FormFieldsetProps) => {
  return (
    <fieldset {...fieldsetProps}>
      <Legend as="legend" nwVariant="body2Strong">
        {nwLegend}
      </Legend>
      <FormLayoutRows>{children}</FormLayoutRows>
    </fieldset>
  )
}

const Legend = styled(TypographyText)(({ theme }) => ({
  marginBlockEnd: theme.space(2),
}))
