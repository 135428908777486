import { dataLayerPush } from '@/tracking/tracking'

export const AuthTracking = {
  overlayDisplay: (page_name: string) => {
    return dataLayerPush({
      event: 'overlay',
      // i18n tracking
      overlay_title: 'Välkommen till TV4 Play!',
      page_name,
    })
  },
  onSignupFailed: (msg: string, page_name: string) => {
    return dataLayerPush({
      event: 'signup_fail',
      signup_message: msg,
      page_name,
    })
  },
  onSignupSuccess: (userId: string, page_name: string) => {
    return dataLayerPush({
      event: 'create_account',
      user_id: userId,
      page_name,
    })
  },
  onLoginSuccess: (userId: string, page_name: string) => {
    return dataLayerPush({
      event: 'login_success',
      user_id: userId,
      page_name,
    })
  },
  onLoginFail: (error: string, page_name: string) => {
    return dataLayerPush({
      event: 'login_fail',
      login_message: error,
      page_name,
    })
  },
  onLogoutSuccess: (userId: string, page_name: string) => {
    return dataLayerPush({
      event: 'logout',
      user_id: userId,
      page_name,
    })
  },
  onForgotPasswordSuccess: (page_name: string) => {
    return dataLayerPush({
      event: 'reset_password',
      page_name,
    })
  },
}
