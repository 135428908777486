import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../../icon/icon/icon'
import { FormInput, type FormInputProps } from '../form-input/form-input'

export type FormInputPasswordProps = {
  nwLabel: string
  nwShowPasswordToggle?: boolean
  /**
   * The status of the password input. This is used to determine the autocomplete value.
   * [Read more here](https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password)
   */
  nwPasswordType?: 'current' | 'new'
} & Omit<FormInputProps, 'type'>

/**
 * Password input component that uses FormInput under the hood.
 */
export const FormInputPassword = ({
  nwShowPasswordToggle = true,
  nwPasswordType = 'current',
  ...forwardProps
}: FormInputPasswordProps) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false)
  const clickHandlerTogglePassword = () => setShouldShowPassword(!shouldShowPassword)

  return (
    <FormInput
      {...forwardProps}
      type={shouldShowPassword ? 'text' : 'password'}
      autoComplete={nwPasswordType === 'current' ? 'current-password' : 'new-password'}
      nwActions={
        nwShowPasswordToggle ? (
          <TogglePasswordVisibilityButton
            type="button"
            onClick={clickHandlerTogglePassword}
            aria-label={shouldShowPassword ? 'Dölj lösenord' : 'Visa lösenord'}
          >
            <Icon nwVariant={shouldShowPassword ? 'eye-closed' : 'eye'} aria-hidden />
          </TogglePasswordVisibilityButton>
        ) : null
      }
    />
  )
}

const TogglePasswordVisibilityButton = styled.button(({ theme }) => ({
  gridRow: 1,
  gridColumn: 2,
  color: theme.color.text.primary,
  background: 'transparent',
  border: 'none',
  padding: 0,
  marginInlineEnd: theme.space(3),
  cursor: 'pointer',
}))
