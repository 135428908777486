import type { FC, PropsWithChildren } from 'react'
import Link from 'next/link'
import type { CSSObject } from 'styled-components'
import styled from 'styled-components'
import { theme } from '@nordic-web/ui-theme'
import { Button } from '../../button/button/button'
import { TypographyText, type TypographyTextProps } from '../../typography/typography-text/typography-text'

const hoverEffect = (scale: number): CSSObject => ({
  willChange: 'transform',
  transition: 'transform 150ms ease-in-out',
  '.no-touch &&:hover': {
    transform: `scale(${scale})`,
  },
})

export const cardHoverEffect = hoverEffect(1.05)
export const cardHoverEffectSmall = hoverEffect(1.01)

type HoverEffect = 'small' | 'large' | 'none'

const EffectMap: Record<HoverEffect, CSSObject> = {
  small: cardHoverEffectSmall,
  large: cardHoverEffect,
  none: {},
}

type TextProps = PropsWithChildren & {
  className?: string
  color?: TypographyTextProps['nwColor']
}

export const CardMainText: FC<TextProps> = ({ children, className, color = 'primary' }) => (
  <TypographyText nwVariant="cardDetail" nwColor={color} className={className}>
    {children}
  </TypographyText>
)

export const CardSecondaryText: FC<TextProps> = ({ children, className }) => (
  <TypographyText nwVariant="cardDetail" nwColor="secondary" className={className}>
    {children}
  </TypographyText>
)

export const CardTertiaryText: FC<TextProps> = ({ children, className }) => (
  <TypographyText nwVariant="cardDetail" nwColor="tertiary" className={className}>
    {children}
  </TypographyText>
)

export const CardProgressBarContainer = styled.div({
  left: theme.space(2),
  right: theme.space(2),
  bottom: theme.space(2),
  position: 'absolute',
})

export const CardContainer = styled(Link)({})

type CardImageContainerProps = {
  nwHoverEffect?: HoverEffect
}

export const CardImageContainer = styled.div<CardImageContainerProps>(({ theme, nwHoverEffect = 'large' }) => ({
  ...EffectMap[nwHoverEffect],
  position: 'relative',
  borderRadius: theme.radii.border_radius,
  overflow: 'hidden',
  height: '100%',
  // Prevent corner flicker on transition (Safari only)
  // https://stackoverflow.com/questions/14383632/webkit-border-radius-and-overflow-bug-when-using-any-animation-transition/16681137
  '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
}))

export const CardHoverContent = styled.div({
  visibility: 'hidden',
  zIndex: 100,
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
  [`.no-touch ${CardImageContainer}:hover &&`]: {
    opacity: 1,
    visibility: 'visible',
  },
})

export const CardButtonContainer = styled(CardHoverContent)(({ theme }) => ({
  position: 'absolute',
  top: theme.space(2),
  right: theme.space(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: theme.space(2),
}))

type CardMetaOverlayContainerProps = {
  nwBackgroundColor?: string
}

export const CardMetaOverlayContainer = styled(CardHoverContent)<CardMetaOverlayContainerProps>(
  ({ nwBackgroundColor, theme }) => ({
    width: '100%',
    backgroundColor: nwBackgroundColor || theme.color.base.background,
    bottom: 0,
    padding: theme.space(2),
    position: 'absolute',
  })
)

export const CardLabelContainer = styled.div({
  display: 'flex',
  zIndex: 5,
  pointerEvents: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'hidden',
})

export const CardBrandLogoContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  top: theme.space(2),
  right: theme.space(2),
  zIndex: 1,
}))

type CardFooterTextContainerProps = {
  nwMarginLeft?: boolean
}

export const CardFooterContainer = styled.div<CardFooterTextContainerProps>(({ theme, nwMarginLeft }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.space(2),
  marginTop: theme.space(1),
  marginLeft: nwMarginLeft ? theme.space(3) : 0,
}))

export const CardFooterTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(1),
})

type CardOverlayContainerProps = {
  nwBackgroundColor?: string
}

export const CardOverlayContainer = styled.div<CardOverlayContainerProps>(
  ({ theme, nwBackgroundColor = '#000000' }) => ({
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderBottomLeftRadius: theme.radii.border_radius,
    borderBottomRightRadius: theme.radii.border_radius,
    paddingLeft: theme.space(3),
    paddingRight: theme.space(3),
    paddingBottom: theme.space(2),
    color: theme.color.text.primary,
    background: `linear-gradient(180deg, #00000000 0%, #00000000 0.01%, #00000000 26.33%, ${nwBackgroundColor}E6 100%)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    gap: theme.space(1),
    width: '100%',
    height: '100%',
  })
)

const TouchButton = styled(Button)({
  marginLeft: 'auto',
  '.no-touch &': {
    display: 'none',
  },
})

type CardMobileMenuToggleProps = {
  nwOnClick: () => void
}
export const CardMobileMenuToggle = ({ nwOnClick }: CardMobileMenuToggleProps) => {
  return (
    <TouchButton
      nwVariant="ghost"
      nwIconFirst="more"
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        nwOnClick()
      }}
    />
  )
}
