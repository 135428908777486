export const breakpoints = {
  max_width_breakpoint: 1276,
  extra_large_desktop: 1800,
  large_desktop: 1280,
  desktop: 1023,
  tablet: 767,
} as const

export const MAX_WIDTH_BREAKPOINT = breakpoints.max_width_breakpoint
export const EXTRA_LARGE_DESKTOP = breakpoints.extra_large_desktop
export const LARGE_DESKTOP = breakpoints.large_desktop
export const DESKTOP = breakpoints.desktop
export const TABLET = breakpoints.tablet

export const breakpointList = [`${TABLET}px`, `${DESKTOP}px`, `${LARGE_DESKTOP}px`, `${EXTRA_LARGE_DESKTOP}px`]
