import { nextConfig } from '@/helpers/env'

const IMAGE_PROXY_URL: string = nextConfig.string('IMAGE_PROXY_URL')

type ImageConversionArgs = {
  url: string
  width: number
  format?: string
}

export const ImageProxyService = {
  url: function ({ url, width }: ImageConversionArgs) {
    if (!url) return ''

    return `${IMAGE_PROXY_URL}/?width=${width}&source=${url}`
  },
}
