import { useCallback, useState } from 'react'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'

const safelyParseJson = <T>(jsonString: string): T => {
  try {
    return JSON.parse(jsonString) as T
  } catch (e) {
    return jsonString as T
  }
}

const writeToLocalStorage = <T>(key: string, value: T) => {
  try {
    localStorage?.setItem(key, typeof value === 'string' ? (value as unknown as string) : JSON.stringify(value))
  } catch (err) {
    console.error(`An error occurred while setting item to localStorage: ${err}`)
  }
}

const removeFromLocalStorage = (key: string) => {
  try {
    localStorage?.removeItem(key)
  } catch (err) {
    console.error(`An error occurred while removing item from localStorage: ${err}`)
  }
}

export const useLocalStorage = <T>(key: string, initialValue: T): [T, (value: T) => void] => {
  const fetchFromLocalStorage = () => {
    if (isClientSide) {
      try {
        const storedValue = localStorage?.getItem(key)
        return storedValue ? safelyParseJson<T>(storedValue) : initialValue
      } catch (err) {
        console.error(`An error occurred while getting item from localStorage: ${err}`)
        return initialValue
      }
    }
    return initialValue
  }

  const [value, setValue] = useState(fetchFromLocalStorage)

  const updateAndSyncValue = useCallback(
    (value: T) => {
      if (value !== undefined) {
        writeToLocalStorage(key, value)
      } else {
        removeFromLocalStorage(key)
      }
      setValue(value)
    },
    [key, setValue]
  )

  return [value, updateAndSyncValue]
}
