import styled from 'styled-components'
import type { EpgFieldsFragment } from '@nordic-web/gql'
import type { IconVariant } from '@nordic-web/ui-components'
import { Icon, ProgressBar, Stack, TypographyText } from '@nordic-web/ui-components'
import { isFuture } from '@nordic-web/utils/date'
import { getEpgProgressPercentage, isPlaying, mapEpgMedia } from '@nordic-web/utils/epg'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { formatTime } from '@/utils/date'

const EpgGrid = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 30px 1fr',
  rowGap: theme.space(3),
  alignItems: 'center',
}))

type EpgColumnProps = {
  clickable: boolean
}

const EpgColumn = styled(TypographyText)<EpgColumnProps>(({ theme, clickable }) => ({
  display: 'contents',
  cursor: clickable ? 'pointer' : 'default',
  '&:hover': {
    color: clickable ? theme.color.text.primary : undefined,
  },
}))

const EpgIcon = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const getEpgIconVariant = (item: EpgFieldsFragment): IconVariant | undefined => {
  if (item.media) {
    const epgMedia = mapEpgMedia(item)
    const startTime = epgMedia?.playableFrom?.isoString
    const isUpcoming = startTime && isFuture(startTime)
    return isUpcoming ? 'player-play-outlined' : 'player-play-filled'
  }
}

type EpgListProps = {
  epg: EpgFieldsFragment[]
  onClick?: (item: EpgFieldsFragment) => void
}

export const EpgList = ({ epg, onClick }: EpgListProps) => {
  return (
    <EpgGrid>
      {epg.map((item, index) => {
        const isItemPlaying = isPlaying(item)
        const color = isItemPlaying ? 'primary' : 'secondary'
        const iconVariant = getEpgIconVariant(item)
        return (
          <EpgColumn
            tabIndex={onClick ? 0 : undefined}
            role={onClick ? 'button' : undefined}
            clickable={!!onClick}
            onClick={() => onClick?.(item)}
            title={item.title}
            key={index}
            nwVariant="body4"
            nwColor={color}
          >
            {formatTime(item.start)}
            <EpgIcon>{iconVariant && <Icon nwVariant={iconVariant} nwSize="tiny" />}</EpgIcon>
            <Stack nwGap={1} nwJustifyContent="center">
              <TruncateLines lines={1}>{item.title}</TruncateLines>
              {isItemPlaying && <ProgressBar percentage={getEpgProgressPercentage(item)} />}
            </Stack>
          </EpgColumn>
        )
      })}
    </EpgGrid>
  )
}
