import styled from 'styled-components'

export const FormLabelBase = styled.label(({ theme }) => ({
  gridRow: 1,
  gridColumn: 1,
  alignSelf: 'center',
  marginInline: theme.space(3),
  pointerEvents: 'none',
  ...theme.typography.body4,
  transformOrigin: 'top left',
  transition: 'transform 0.12s ease-out',
  '*:focus-within > &, *:not(:placeholder-shown) + &': {
    transform: 'scale(.85) translateY(-50%)',
    opacity: 0.5,
  },
}))
