import type { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Button } from '../../button/button/button'
import { useDialog } from './dialog-context'

const DialogHeader = styled.div(({ theme }) => ({
  paddingTop: theme.space(4),
  paddingInline: theme.space(4),
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'sticky',
  top: 0,
}))

export const DialogTop = () => {
  const { handleClose } = useDialog()
  return (
    <DialogHeader>
      <Button nwVariant="ghost" nwIconLast="close-x" onClick={handleClose} />
    </DialogHeader>
  )
}

export const DialogImageTop: FC<PropsWithChildren> = ({ children }) => {
  const { handleClose } = useDialog()
  return (
    <div>
      {children}
      <AbsoluteCloseButton nwVariant="dark" nwIconLast="close-x" onClick={handleClose} />
    </div>
  )
}

const AbsoluteCloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.space(4),
  right: theme.space(4),
}))
