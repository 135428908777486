import styled from 'styled-components'
import { nwSizeStyles } from './constants'

export const AvatarWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.space(4),
}))

export const AvatarCircle = styled.div({
  position: 'relative',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  ...nwSizeStyles.large,
})

export const AvatarText = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
