import type {
  ClipFieldsLightFragment,
  EpisodeFieldsFragment,
  MovieFieldsLightFragment,
  PageLinkFieldsFragment,
  PageListFieldsFragment,
  SeriesFieldsLightFragment,
  SportEventFieldsLightFragment,
  UpcomingEpisodeFieldsFragment,
} from '@nordic-web/gql/generated/graphql.generated'
import { isTypeInUnion } from '../typescript'

type Movie = Pick<MovieFieldsLightFragment, '__typename' | 'upsell'>
type SportEvent = Pick<SportEventFieldsLightFragment, '__typename' | 'upsell'>
type Episode = Pick<EpisodeFieldsFragment, '__typename' | 'upsell'>
type Clip = Pick<ClipFieldsLightFragment, '__typename'>
type Channel = { __typename: 'Channel' }
type Series = Pick<SeriesFieldsLightFragment, '__typename' | 'upsell'>
type UpcomingEpisode = Pick<UpcomingEpisodeFieldsFragment, '__typename' | 'upsell'>
type PageReference = Pick<PageListFieldsFragment, '__typename'>
type PageLink = Pick<PageLinkFieldsFragment, '__typename'>

export const isUpsellMedia = (
  media: Movie | Episode | Clip | Channel | SportEvent | Series | UpcomingEpisode | PageReference | PageLink
) => {
  if (isTypeInUnion(media, 'Movie', 'Series', 'SportEvent', 'Episode', 'UpcomingEpisode')) {
    return media?.upsell !== null
  }

  return false
}
