import type { ComponentProps, PropsWithChildren } from 'react'
import { forwardRef } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import type { TextColors } from '../typography-text/typography-text'

type TypographyLinkProps = {
  nwColor?: TextColors
} & ComponentProps<typeof Link>

export const TypographyLink = forwardRef<HTMLAnchorElement, PropsWithChildren<TypographyLinkProps>>(
  function TypographyLink({ nwColor = 'primary', ...props }, ref) {
    return <StyledTypographyLink $nwColor={nwColor} {...props} ref={ref} />
  }
)

/**
 * TODO: Add focus states to this component.
 */
const StyledTypographyLink = styled(Link)<{ $nwColor: TextColors }>(({ theme, $nwColor }) => ({
  color: theme.color.text[$nwColor],
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: theme.fontWeightBold,
  '&:hover': {
    color: theme.color.text.highlight,
  },
}))
