import { createContext, useContext } from 'react'

type DialogContextType = {
  handleClose: () => void
}

export const DialogContext = createContext<DialogContextType | undefined>(undefined)

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext)
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider')
  }
  return context
}
