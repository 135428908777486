import React from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { Button } from '@nordic-web/ui-components'
import { TypographyText, TypographyTitle } from '@nordic-web/ui-components'
import { useAccountApi } from '@/features/auth/hooks/use-account-api'

const ErrorText = styled(TypographyText)(({ theme }) => ({
  color: theme.color.text.error,
}))

export const LogoutOthersConfirm = ({ onCancelLogoutOther }: { onCancelLogoutOther: () => void }) => {
  const t = useTranslations()
  const { logoutAllMutation } = useAccountApi()

  const hasLoggedOutAll = logoutAllMutation.isSuccess
  return (
    <>
      <TypographyTitle
        as="h2"
        nwPreamble={hasLoggedOutAll ? t('log_out_devices__success') : t('log_out_devices__description')}
      >
        {hasLoggedOutAll ? `${t('general__done')}!` : t('log_out_devices__headline')}
      </TypographyTitle>
      {logoutAllMutation.isError && <ErrorText nwVariant="smallText">{t('error_message__unknown_error')}</ErrorText>}
      {!hasLoggedOutAll && (
        <Button
          onClick={() => logoutAllMutation.mutate()}
          nwLoading={logoutAllMutation.isPending}
          nwLayout="full-width"
        >
          {t('log_out_devices__yes')}
        </Button>
      )}
      <Button onClick={onCancelLogoutOther} nwVariant={hasLoggedOutAll ? 'primary' : 'ghost'} nwLayout="full-width">
        {hasLoggedOutAll ? t('log_out_devices__go_back') : t('log_out_devices__no')}
      </Button>
    </>
  )
}
