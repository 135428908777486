import styled from 'styled-components'
import { PagePrefetchLink } from '@/components/prefetch-links/page-prefetch-link'
import { aboveLargeDesktopHeader } from '@/layouts/header/style'

export const iconSizing = {
  '& svg': {
    width: 24,
    height: 24,
    [aboveLargeDesktopHeader]: {
      width: 32,
      height: 32,
    },
  },
} as const

const hoverUnderline = {
  position: 'absolute',
  bottom: -10,
  left: 0,
  width: '100%',
  content: '" "',
  height: 4,
  opacity: 0,
  transformOrigin: 'left center',
  transform: `scaleX(0)`,
  transition: 'all 200ms cubic-bezier(0.2, 0.46, 0.45, 0.94) 0s',
} as const

export const IconItem = styled.li(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  height: '100%',
  zIndex: 1,
  svg: {
    display: 'block',
    transition: 'all 250ms ease',
  },
  button: {
    appearance: 'none',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
  },
  '> button, > a': {
    position: 'relative',
  },
  '.no-touch & > *:after': {
    ...hoverUnderline,
    background: theme.color.base.primaryLight,
  },
  '.no-touch &:hover > button:after': {
    opacity: 1,
    transform: `scaleX(1)`,
  },
  ...iconSizing,
}))

export const MenuLink = styled(PagePrefetchLink)(({ theme }) => ({
  color: theme.color.text.primary,
  margin: 0,
  height: 'var(--header-height)',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  paddingInline: 8,
  fontWeight: 400,

  '&[aria-current]': {
    fontWeight: 500,
  },

  '.no-touch & > span': {
    position: 'relative',

    '&:after': {
      ...hoverUnderline,
      background: theme.color.base.primaryLight,
    },
  },

  '.no-touch &:hover, &[aria-current]': {
    textDecoration: 'none',

    '& > span': {
      '&:after': {
        opacity: 1,
        transform: `scaleX(1)`,
      },
    },
  },
}))
