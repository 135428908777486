import type { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { generateResponsiveStyles } from '@nordic-web/ui-styles'
import type { StackGap } from '../../layout/stack-redux/stack'
import { Stack } from '../../layout/stack-redux/stack'

const gap: StackGap = 4

export const DialogBody: FC<PropsWithChildren> = ({ children }) => {
  return <StyledDialogBody nwGap={gap}>{children}</StyledDialogBody>
}

const StyledDialogBody = styled(Stack)(({ theme }) => ({
  ...generateResponsiveStyles('padding', [theme.space(4), theme.space(4), theme.space(6)]),
  flex: 1,
}))

export const DialogDrawerBody: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledDialogDrawerBody nwJustifyContent="center" nwGap={gap}>
      {children}
    </StyledDialogDrawerBody>
  )
}

const StyledDialogDrawerBody = styled(Stack)(({ theme }) => ({
  ...generateResponsiveStyles('padding', [theme.space(4), theme.space(6), theme.space(18)]),
  flex: 1,
}))
