import Link from 'next/link'
import styled from 'styled-components'
import type { ChannelFieldsFragment } from '@nordic-web/gql'
import { ResponsiveImage, Stack, cardHoverEffect } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'
import { isPast } from '@nordic-web/utils/date'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'
import { useUpdateEpg } from '@/components/epg-channel-card/use-update-epg'
import { EpgList } from '@/components/epg-list'
import { MissingEpgMessage } from '@/features/channel-epg-panel/components/missing-epg-message'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type Variant = 'light' | 'dark'

type ContainerProps = {
  variant: Variant
  disabled: boolean
}

const Container = styled(Link)<ContainerProps>(({ theme, variant, disabled }) => ({
  height: '100%',
  borderRadius: theme.radii.border_radius,
  background: variant === 'light' ? theme.color.surface.white5 : theme.color.surface.black40,
  display: 'flex',
  paddingBlock: theme.space(4),
  '&:hover': {
    background: variant === 'light' ? theme.color.surface.white20 : theme.color.surface.black50,
    textDecoration: 'none',
  },
  ...(disabled && {
    opacity: 0.5,
    pointerEvents: 'none',
  }),
  ...cardHoverEffect,
  [tablet]: {
    minHeight: 180,
  },
}))

const Section = styled(Stack)(({ theme }) => ({
  paddingInline: theme.space(4),
}))

const Divider = styled.div(({ theme }) => ({
  height: 1,
  background: theme.color.base.background,
}))

type EpgChannelCardProps = {
  channel: ChannelFieldsFragment
  variant?: Variant
  replace?: boolean
  className?: string
}

export const EpgChannelCard = ({ channel, variant = 'light', replace = false, className }: EpgChannelCardProps) => {
  useUpdateEpg(channel.id)
  useReRenderWithInterval()

  const epg = channel.epg?.filter((item) => !isPast(item.end))
  const hasEpg = !!epg?.length
  const logo = channel.images.horizontalLogo?.sourceEncoded

  return (
    <AssetTracking content_media_id={channel.id}>
      {({ trackOnAssetClick }) => (
        <Container
          replace={replace}
          href={paths.video.urlString({ assetId: channel.id, slug: channel.slug })}
          variant={variant}
          disabled={!channel.access.hasAccess}
          className={className}
          onClick={trackOnAssetClick}
        >
          <Stack nwFullWidth nwGap={4}>
            {logo && (
              <Section nwAlignItems="flex-start">
                <ResponsiveImage height={28} width={300} alt={channel.title} src={logo} />
              </Section>
            )}
            <Divider />
            <Section nwJustifyContent="center">
              {hasEpg ? <EpgList epg={epg.slice(0, 3)} /> : <MissingEpgMessage channel={channel} />}
            </Section>
          </Stack>
        </Container>
      )}
    </AssetTracking>
  )
}
