import type { CSSObject } from 'styled-components'

/**
 * Base CSS to be used for when multiple mask-image properties are used. This will make sure
 * that all specified mask-images will be used to mask the background.
 */
export const multiMaskBaseObj: CSSObject = {
  '-webkit-mask-composite': 'source-in, source-over;',
  maskComposite: 'intersect',
}
