import { useMediaQuery } from '@react-hook/media-query'
import { DESKTOP, EXTRA_LARGE_DESKTOP, LARGE_DESKTOP, TABLET } from '@nordic-web/ui-tokens'

/**
 * returns true if x < breakpoint
 */
const useIsBelowBreakpoint = (breakpoint: number) => useMediaQuery(`only screen and (max-width: ${breakpoint - 1}px)`)

/**
 * returns true if x >= breakpoint
 */
export const useIsAboveBreakpoint = (breakpoint: number) =>
  useMediaQuery(`only screen and (min-width: ${breakpoint}px)`)

// ts-prune-ignore-next
export const useIsBelowTablet = () => useIsBelowBreakpoint(TABLET)
export const useIsBelowDesktop = () => useIsBelowBreakpoint(DESKTOP)
// ts-prune-ignore-next
export const useIsBelowLargeDesktop = () => useIsBelowBreakpoint(LARGE_DESKTOP)
// ts-prune-ignore-next
export const useIsBelowExtraLargeDesktop = () => useIsBelowBreakpoint(EXTRA_LARGE_DESKTOP)
export const useIsAboveTablet = () => useIsAboveBreakpoint(TABLET)
// ts-prune-ignore-next
export const useIsAboveDesktop = () => useIsAboveBreakpoint(DESKTOP)
// ts-prune-ignore-next
export const useIsAboveLargeDesktop = () => useIsAboveBreakpoint(LARGE_DESKTOP)
// ts-prune-ignore-next
export const useIsAboveExtraLargeDesktop = () => useIsAboveBreakpoint(EXTRA_LARGE_DESKTOP)
