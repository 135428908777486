import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import type { AbstractIntlMessages } from 'next-intl'
import { NextIntlClientProvider } from 'next-intl'
import styled from 'styled-components'
import { BRAND, Brand, brandConfig } from '@/config/brand'

type TranslationProviderProps = {
  translationsFromServer?: AbstractIntlMessages
}

export const TextHighlighted = styled.span(({ theme }) => ({
  color: theme.color.text.primary,
}))

const TranslationProvider = ({ children, translationsFromServer }: PropsWithChildren<TranslationProviderProps>) => {
  // Used as a cache since messages are only available on first render
  const [messages] = useState(translationsFromServer)

  return (
    <NextIntlClientProvider
      locale={brandConfig.language}
      defaultTranslationValues={{
        bold: (text) => <TextHighlighted>{text}</TextHighlighted>,
        b: (text) => <TextHighlighted>{text}</TextHighlighted>,
      }}
      messages={messages}
      timeZone={BRAND === Brand.MTV ? 'Europe/Helsinki' : 'Europe/Stockholm'}
    >
      {children}
    </NextIntlClientProvider>
  )
}

export { TranslationProvider }
