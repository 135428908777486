import nookies from 'nookies'
import { nextConfig } from '@/helpers/env'

const isSecureEnvironment = nextConfig.bool('IS_SECURE_ENVIRONMENT')

export const removeCookie = nookies.destroy
export const getCookie = nookies.get
export const setCookie = nookies.set

export const withDefaultCookieOptions = (options: Record<string, string | number | boolean> = {}) => {
  return {
    secure: isSecureEnvironment,
    // Always set cookies on the root path to make them available on all pages
    path: '/',
    ...options,
  }
}
