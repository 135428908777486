import { createGlobalStyle, useTheme } from 'styled-components'
import { GlobalStyles as NordicWebGlobalStyles } from '@nordic-web/ui-styles'
import { globalAnimations } from '@/styles/animations/global'
import { routerProgressBar } from './router-progress-bar'

const ApplicationGlobalStyles = createGlobalStyle`
  /* wizard... */
  #__next {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
  }

  ${routerProgressBar}
  ${globalAnimations}
`

export const GlobalStyles = () => {
  const theme = useTheme()

  return (
    <>
      <NordicWebGlobalStyles theme={theme} />
      <ApplicationGlobalStyles />
    </>
  )
}
