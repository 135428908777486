import styled from 'styled-components'
import { DESKTOP, EXTRA_LARGE_DESKTOP, LARGE_DESKTOP, TABLET } from '@nordic-web/ui-tokens'

export const minBreakpoint = (breakpoint: number) => `@media screen and (min-width: ${breakpoint}px)`
export const maxBreakpoint = (breakpoint: number) => `@media screen and (max-width: ${breakpoint - 1}px)`

export const tablet = minBreakpoint(TABLET)
export const belowTablet = maxBreakpoint(TABLET)
export const desktop = minBreakpoint(DESKTOP)
export const belowDesktop = maxBreakpoint(DESKTOP)
export const largeDesktop = minBreakpoint(LARGE_DESKTOP)
export const extraLargeDesktop = minBreakpoint(EXTRA_LARGE_DESKTOP)
export const landscapeOrientation = `${belowDesktop} and (orientation: landscape) and (min-aspect-ratio: 16/9)`

type BreakpointComponentProps = {
  mediaQuery: string
}

export const HideInBreakpoint = styled.div<BreakpointComponentProps>(({ mediaQuery }) => ({
  [mediaQuery]: {
    display: 'none',
  },
}))

export const ShowInBreakpoint = styled.div<BreakpointComponentProps>(({ mediaQuery }) => ({
  display: 'none',
  [mediaQuery]: {
    display: 'flex',
  },
}))
