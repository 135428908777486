import type { FC, PropsWithChildren } from 'react'
import { useEpgDetailsQuery } from '@nordic-web/gql'
import { CircleSpinner } from '@nordic-web/ui-components'
import { ChannelUpgradeBanner } from '@/features/channel-epg-panel/components/channel-upgrade-banner'
import { SharedDetails } from '@/features/channel-epg-panel/components/details-dialog/shared-details'
import type { ChannelEpgBase } from '@/features/channel-epg-panel/components/details-dialog/types'

export const MediaDetailsMissing: FC<PropsWithChildren<ChannelEpgBase>> = ({ channel, epgItem, children }) => {
  const { data, loading: isLoading } = useEpgDetailsQuery({
    variables: {
      broadcastId: epgItem.broadcastId,
      channelId: channel.id,
    },
  })

  if (isLoading) return <CircleSpinner nwPaddingTop />

  return (
    <SharedDetails
      channel={channel}
      title={epgItem.title}
      epgItem={epgItem}
      synopsis={data?.epgItem?.synopsis.medium}
      image={data?.epgItem?.images.main16x9.sourceEncoded}
    >
      {!channel.access.hasAccess && <ChannelUpgradeBanner />}
      {children}
    </SharedDetails>
  )
}
