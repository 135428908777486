type DateValue = Date | string | number

export const isPast = (date: DateValue) => new Date(date).getTime() < new Date().getTime()

export const isFuture = (date: DateValue) => new Date(date).getTime() > new Date().getTime()

export const isBefore = (date1: DateValue, date2: DateValue) => {
  return new Date(date1).getTime() < new Date(date2).getTime()
}

export const addSecondsToDate = (date: Date, seconds: number) => {
  return new Date(date.getTime() + seconds * 1000)
}

export const formatDuration = (totalMilliSeconds: number) => {
  const totalSeconds = Math.floor(totalMilliSeconds / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

type IsCurrentlyLiveOptions = {
  eventStartTime?: DateValue
  eventEndTime?: DateValue
}

export const isCurrentlyLive = ({ eventStartTime, eventEndTime }: IsCurrentlyLiveOptions) => {
  const now = new Date()

  if (!eventStartTime) {
    return false
  }

  if (eventEndTime) {
    return isBefore(eventStartTime, now) && isBefore(now, eventEndTime)
  }

  return isBefore(eventStartTime, now)
}

type Language = 'fi' | 'sv'

export const formatTime = (language: Language, date: string, withSeconds = false) => {
  return new Date(date)
    .toLocaleTimeString(language, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      second: withSeconds ? '2-digit' : undefined,
    })
    .replace(/\./g, language === 'fi' ? ':' : '.')
}

export const formatDate = (
  language: Language,
  date: string | Date,
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
) => {
  return new Date(date).toLocaleDateString(language, options)
}

export const formatStartEndTime = (language: Language, startTime: string, endTime?: string) => {
  return `${formatTime(language, startTime)}${endTime ? ' - ' + formatTime(language, endTime) : ''}`
}
