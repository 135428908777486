import { useEffect } from 'react'

const useMuteTrailer = (videoElement: HTMLVideoElement | null, muted: boolean, videoLoaded: boolean) => {
  useEffect(() => {
    if (!videoElement || !videoLoaded) {
      return
    }

    videoElement.muted = muted

    if (muted) {
      // Historically React haven't worked that well with the "muted" attribute of video elements. So we ensure it is muted
      // by also setting it manually.
      videoElement.setAttribute('muted', 'muted')
    } else {
      videoElement.removeAttribute('muted')
    }
  }, [videoElement, muted, videoLoaded])
}

export { useMuteTrailer }
