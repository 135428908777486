import styled from 'styled-components'

const Progress = styled.progress(({ theme }) => ({
  appearance: 'none',
  height: theme.space(1),
  width: '100%',
  zIndex: 4,
  '&::-webkit-progress-bar': {
    backgroundColor: theme.color.progressBar.background,
    borderRadius: theme.radii.border_radius_small,
  },
  '&::-webkit-progress-value': {
    borderRadius: theme.radii.border_radius_small,
    backgroundColor: theme.color.progressBar.default,
    transition: 'width 300ms',
  },
}))

type ProgressProps = {
  percentage: number
  className?: string
}

export const ProgressBar = ({ percentage, className }: ProgressProps) => {
  if (percentage === 0) return null
  return <Progress value={percentage} max={100} className={className}></Progress>
}
