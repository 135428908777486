import styled from 'styled-components'
import { Icon } from '@nordic-web/ui-components'
import { aboveTabletHeader } from '@/layouts/header/style'
import { IconItem } from './style'

type HamburgerProps = {
  onClick: () => void
}

export const Hamburger = ({ onClick }: HamburgerProps) => {
  return (
    <StyledIconItem>
      <button onClick={onClick}>
        <Icon nwVariant="menu" nwColor="primary" />
      </button>
    </StyledIconItem>
  )
}

const StyledIconItem = styled(IconItem)({
  [aboveTabletHeader]: {
    display: 'none',
  },
})
