import { useTranslations } from 'next-intl'

export const EMAIL_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i

export const useValidators = () => {
  const t = useTranslations()

  const validationTexts = {
    email: t('error_message__invalid_email'),
    required: t('error_message__required'),
    emailNotMatching: t('error_message__email_not_matching'),
    zipCode: t('error_message__invalid_zipcode'),
    firstName: t('error_message__firstname'),
    lastName: t('error_message__lastname'),
    password: t('error_message__password_length'),
    passwordNotMatching: t('error_message__password_not_matching'),
    yearOfBirth: t('error_message__year_of_birth'),
  }

  const emailValidator = (value: string) => {
    return EMAIL_REGEXP.test(value) ? undefined : validationTexts.email
  }

  const requiredValidator = (value: string) => {
    return value ? undefined : validationTexts.required
  }

  const zipcodeValidator = (value: string) => {
    return /^(\s*\d\s*){5}$/.test(value) ? undefined : validationTexts.zipCode
  }

  const validateName = (value: string) => {
    return value.length >= 2 && value.length <= 40
  }

  const firstnameValidator = (value: string) => {
    return validateName(value) ? undefined : validationTexts.firstName
  }

  const lastnameValidator = (value: string) => {
    return validateName(value) ? undefined : validationTexts.lastName
  }

  const passwordLengthValidator = (value: string) => {
    return value.trim().length >= 8 ? undefined : validationTexts.password
  }

  const postalAddressValidator = (value: string) => {
    return requiredValidator(value.trim())
  }

  const yearOfBirthValidator = (year?: number) => {
    const errorMessage = validationTexts.yearOfBirth
    if (year && !isNaN(year)) {
      const age = new Date(Date.now()).getFullYear() - year
      return age >= 16 && age <= 115 ? undefined : errorMessage
    }
    return errorMessage
  }

  return {
    validationTexts,
    emailValidator,
    requiredValidator,
    zipcodeValidator,
    firstnameValidator,
    lastnameValidator,
    passwordLengthValidator,
    postalAddressValidator,
    yearOfBirthValidator,
  }
}
