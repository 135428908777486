import { type PageListFieldsFragment } from '@nordic-web/gql'
import { TypographyText } from '@nordic-web/ui-components'
import type { OnCardClick } from '@/components/cards/types'
import { LogoCard, LogoCardImage } from '@/components/logo-card'
import { usePrefetchPageQuery } from '@/components/prefetch-links/page-prefetch-link'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type LogoPageCardProps = {
  page: PageListFieldsFragment
  onCardClick?: OnCardClick
}

export const LogoPageCard = ({ page, onCardClick }: LogoPageCardProps) => {
  const image = page.images?.logo?.isFallback ? undefined : page.images?.logo?.sourceEncoded

  const handleClick = () => {
    if (!onCardClick) return

    onCardClick(page)
  }

  const prefetch = usePrefetchPageQuery(page.id)

  return (
    <AssetTracking content_media_id={page.id}>
      {({ trackOnAssetClick }) => (
        <LogoCard
          onMouseEnter={prefetch}
          aria-label={page.title}
          href={paths.page.urlString({ id: page.id })}
          data-testid="page"
          onClick={() => {
            trackOnAssetClick()
            handleClick()
          }}
        >
          {image ? (
            <LogoCardImage alt={page.title} src={image} />
          ) : (
            <TypographyText nwColor="primary" nwVariant="title3" nwTextAlign="center">
              {page.title}
            </TypographyText>
          )}
        </LogoCard>
      )}
    </AssetTracking>
  )
}
