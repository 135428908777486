import type { PropsWithChildren } from 'react'
import React from 'react'
import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { useContentDetailsPageLazyQuery } from '@nordic-web/gql'

type CdpLinkProps = {
  mediaId: string
} & LinkProps

export const usePrefetchCdpQuery = (mediaId?: string) => {
  const [fetch] = useContentDetailsPageLazyQuery()

  return () => {
    if (!mediaId) return

    fetch({
      variables: {
        mediaId,
      },
    })
  }
}

export const CdpPrefetchLink = ({ mediaId, ...linkProps }: PropsWithChildren<CdpLinkProps>) => {
  const prefetch = usePrefetchCdpQuery(mediaId)
  return <Link onMouseEnter={prefetch} {...linkProps} />
}
