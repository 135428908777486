import type { ComponentPropsWithRef } from 'react'
import { forwardRef, useState } from 'react'
import styled from 'styled-components'
import type { IconVariant } from '../../icon/icon/icon'
import { Button, type ButtonElements } from '../button/button'

export const buttonCollapseVariants = ['secondary', 'dark', 'subtle'] as const
export type ButtonCollapseVariant = (typeof buttonCollapseVariants)[number]

export type ButtonCollapseProps = {
  nwIcon: IconVariant
  children: string
  nwVariant?: ButtonCollapseVariant
  className?: string
  as?: ButtonElements
} & ComponentPropsWithRef<'button'>

export const ButtonCollapse = forwardRef<HTMLButtonElement, ButtonCollapseProps>(function ButtonCollapse(
  { nwVariant = 'dark', children, nwIcon, as, className, ...forwardProps },
  ref
) {
  const [shouldShowChildren, setShouldShowChildren] = useState(false)

  return (
    <StyledButton
      onMouseEnter={() => setShouldShowChildren(true)}
      onMouseLeave={() => setShouldShowChildren(false)}
      ref={ref}
      nwIconLast={nwIcon}
      nwVariant={nwVariant}
      className={className}
      aria-label={forwardProps['aria-label'] || children}
      forwardedAs={as}
      {...forwardProps}
    >
      {shouldShowChildren ? children : null}
    </StyledButton>
  )
})

const StyledButton = styled(Button)(({ theme }) => ({
  paddingInline: theme.space(3),
  '.touch & .nw-button-text': {
    display: 'none',
  },
}))
