import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div({
  display: 'flex',
  width: '100%',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
})

const Container = styled.div(({ theme }) => ({
  gap: theme.space(4),
  display: 'flex',
  transition: 'transform 0.02s linear',
}))

type HorizontalAutoScrollProps = {
  active: boolean
  children: React.ReactNode
  pauseDuration?: number
}

export const HorizontalAutoScroll = ({ active, children, pauseDuration = 1500 }: HorizontalAutoScrollProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [translate, setTranslate] = useState(0)
  const [scrollDirection, setScrollDirection] = useState(1)
  const [isPaused, setIsPaused] = useState(true)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (active) {
      timeoutId = setTimeout(() => {
        setIsPaused(false)
      }, pauseDuration)
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [active, pauseDuration])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null

    if (active && !isPaused && contentRef.current && wrapperRef.current) {
      const contentWidth = contentRef.current.offsetWidth
      const wrapperWidth = wrapperRef.current.offsetWidth
      if (contentWidth > wrapperWidth) {
        // only start scrolling if content is wider than wrapper
        intervalId = setInterval(() => {
          if (translate >= contentWidth - wrapperWidth && scrollDirection === 1) {
            setIsPaused(true)
            setTimeout(() => {
              setScrollDirection(-1)
              setIsPaused(false)
            }, pauseDuration)
          } else if (translate <= 0 && scrollDirection === -1) {
            setIsPaused(true)
            setTimeout(() => {
              setScrollDirection(1)
              setIsPaused(false)
            }, pauseDuration)
          }

          if (!isPaused) {
            setTranslate((prevTranslate) => prevTranslate + scrollDirection)
          }
        }, 20)
      }
    } else if (!active) {
      setTranslate(0)
      setIsPaused(true)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [translate, scrollDirection, active, isPaused, pauseDuration])

  return (
    <Wrapper ref={wrapperRef}>
      <Container ref={contentRef} style={{ transform: `translateX(-${translate}px)` }}>
        {children}
      </Container>
    </Wrapper>
  )
}
