import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { A11yVisuallyHidden } from '@nordic-web/ui-components'
import { formatDate } from '@/utils/date'

export const TableauSeoH1 = () => {
  const t = useTranslations()
  const router = useRouter()

  const displayDate = formatDate((router.query.d as string) ?? new Date(), {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
  })
  const title = t('channels_tableau_seo_title', { date: displayDate })

  return <A11yVisuallyHidden as="h1">{title}</A11yVisuallyHidden>
}
