import React from 'react'
import { useTranslations } from 'next-intl'
import { Breadcrumbs } from '@/components/_base/navigation'
import { paths } from '@/helpers/paths'

type BreadcrumbsCategoriesProps = {
  categoryName: string
}

export const CategoriesBreadcrumbs = ({ categoryName }: BreadcrumbsCategoriesProps) => {
  const t = useTranslations()

  return (
    <Breadcrumbs
      links={[{ title: t('breadcrumb__categories'), href: paths.categories.urlString() }]}
      current={categoryName}
      isCurrentHeading
    />
  )
}
