import React, { useCallback } from 'react'
import { useTranslations } from 'next-intl'
import { ButtonCollapse, type ButtonCollapseVariant } from '@nordic-web/ui-components'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { useFavorite } from '@/features/favorite/hooks/use-favorite'
import { toggleFavoriteTracking } from './tracking'
import type { FavoriteMediaTypes } from './utils'
import { mapTypenameToFavoriteType } from './utils'

type FavoriteProps = {
  mediaId: string
  mediaType: FavoriteMediaTypes
  isMobile?: boolean
  className?: string
  setRemoved?: (isFavorite: boolean) => void
  nwVariant?: ButtonCollapseVariant
  compact?: boolean
}

export const FavoriteButton = ({
  mediaId,
  mediaType,
  className,
  setRemoved,
  compact,
  nwVariant = 'dark',
}: FavoriteProps) => {
  const t = useTranslations()
  const isClientSide = useIsClientSide()

  const { isLoading, isFavorite, removeFavorite, addFavorite } = useFavorite({
    id: mediaId,
    type: mapTypenameToFavoriteType(mediaType),
  })

  const FavoriteIcon = isFavorite ? 'check' : 'plus'
  const FavoriteText = isFavorite ? t('my_list__remove_from_my_list') : t('my_list__add_to_list')
  const testId = `favorite-button-${isFavorite ? 'remove' : 'add'}`

  const toggleFavorite = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      toggleFavoriteTracking({ assetId: mediaId, isFavorite })
      setRemoved && setRemoved(isFavorite)

      isFavorite ? removeFavorite() : addFavorite()
    },
    [mediaId, isFavorite, setRemoved, removeFavorite, addFavorite]
  )

  if (!isClientSide || isLoading) {
    return null
  }

  return (
    <ButtonCollapse
      className={className}
      data-test={testId}
      nwIcon={FavoriteIcon}
      onClick={toggleFavorite}
      nwVariant={nwVariant}
      aria-label={compact ? FavoriteText : undefined}
    >
      {compact ? '' : FavoriteText}
    </ButtonCollapse>
  )
}
