import Bugsnag from '@bugsnag/js'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  CredentialsPayload,
  UpdateEmailPayload,
  UpdatePasswordPayload,
  UpdateTv4ChildLockPayload,
  UpdateTv4ChildLockWithPasswordPayload,
  UpdateUserPayload,
  User,
} from '@nordic-web/rest-codegen/generated/account'
import * as AccountService from '@nordic-web/rest-codegen/generated/account'
import type { ApiError } from '@nordic-web/rest-codegen/generated/account'
import { formatAuthorizationHeader } from '@nordic-web/utils/authentication/format-authorization-header'
import { brandConfig } from '@/config/brand'
import { useMenuState } from '@/context/menu-state-context'
import { authenticationStore, logout, useAuthenticationStore } from '@/features/auth/authentication-store'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { getStaleAndCacheTime } from '@/features/auth/helpers/get-stale-and-cache-time'
import { AuthTracking } from '@/features/auth/tracking-events'
import { nextConfig } from '@/helpers/env'
import { getPageName } from '@/helpers/get-page-name'

const aDayInMilliseconds = 1000 * 60 * 60 * 24

const ACCOUNT_HOST_API = nextConfig.string('ACCOUNT_HOST_API')

AccountService.OpenAPI.BASE = ACCOUNT_HOST_API

const queryKey = ['user']

export const useAccountApi = () => {
  const { dispatch: authModalDispatch } = useAuthModal()
  const { setIsMobileMenuOpen } = useMenuState()
  const { isLoggedIn } = useAuthenticationStore()
  const queryClient = useQueryClient()

  const onError = (error: ApiError) => console.error(`Use account api error: ${error}`)

  const onUserQuerySuccess = (user: User) => {
    authModalDispatch({ type: 'close-auth-modal' })
    setIsMobileMenuOpen(false)
    Bugsnag.setUser(user.userId)
  }

  const userQuery = useQuery({
    queryKey,
    retry: 1,
    queryFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      const data = await AccountService.accountWebUserControllerMe({
        authorization: formatAuthorizationHeader(token),
        client: brandConfig.clientName,
      })

      onUserQuerySuccess(data)
      return data
    },
    enabled: isLoggedIn,
    ...getStaleAndCacheTime(aDayInMilliseconds),
  })

  const userMutation = useMutation({
    mutationFn: async (payload: UpdateUserPayload) => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebUserControllerUpdateUser({
        authorization: formatAuthorizationHeader(token),
        payload,
      })
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
    onError,
  })

  // Since vimond is slow with updating these fields, we need to optimistically set the response data for the marketing settings
  const optimisticUserMutation = useMutation({
    mutationFn: async (payload: UpdateUserPayload) => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebUserControllerUpdateUser({
        authorization: formatAuthorizationHeader(token),
        payload,
      })
    },
    onMutate: async (marketingSettings) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey })

      // Save the previous state, in case of error
      const previousUser = queryClient.getQueryData(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData<UpdateUserPayload>(queryKey, (userPayload) => ({
        ...userPayload,
        ...marketingSettings,
      }))

      return { previousUser }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_error: ApiError, _variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousUser)
    },
  })

  const emailMutation = useMutation({
    mutationFn: async (payload: UpdateEmailPayload) => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebUserControllerUpdateEmail({
        authorization: formatAuthorizationHeader(token),
        payload,
      })
    },
    onError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const deleteUserMutation = useMutation({
    mutationFn: (payload: CredentialsPayload) =>
      AccountService.accountWebUserControllerDeleteUser({
        payload,
      }),
    onSuccess: () => {
      logout()
    },
    onError,
  })

  const passwordMutation = useMutation({
    mutationFn: async (payload: UpdatePasswordPayload) => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebPasswordControllerUpdatePassword({
        authorization: formatAuthorizationHeader(token),
        payload,
      })
    },
    onError,
  })

  const editChildLockMutation = useMutation({
    mutationFn: async (payload: UpdateTv4ChildLockPayload) => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebUserControllerUpdateTv4ChildLock({
        authorization: formatAuthorizationHeader(token),
        payload,
      })
    },
    onError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const disableChildLockWithPasswordMutation = useMutation({
    mutationFn: async (payload: UpdateTv4ChildLockWithPasswordPayload) => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebUserControllerUpdateTv4ChildLockWithPassword({
        authorization: formatAuthorizationHeader(token),
        payload,
      })
    },
    onError,
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data)
    },
  })

  const logoutOptions: UseMutationOptions = {
    onSuccess: () => {
      AuthTracking.onLogoutSuccess(userQuery.data?.userId ?? '', getPageName() ?? '')
    },
    onSettled: () => {
      logout()
    },
  }

  const logoutMutation = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebSessionControllerLogout({
        authorization: formatAuthorizationHeader(token),
      })
    },
    ...logoutOptions,
  })

  const logoutAllMutation = useMutation({
    mutationFn: async () => {
      const token = await authenticationStore.getValidAccessToken()
      return AccountService.accountWebSessionControllerLogoutAll({
        authorization: formatAuthorizationHeader(token),
      })
    },
    ...logoutOptions,
  })

  return {
    user: userQuery,
    userMutation,
    optimisticUserMutation,
    emailMutation,
    logoutMutation,
    logoutAllMutation,
    passwordMutation,
    deleteUserMutation,
    editChildLockMutation,
    disableChildLockWithPasswordMutation,
  }
}
