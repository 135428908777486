import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import type { Conversions } from '@nordic-web/rest-codegen/generated/subscription'
import { type PackageProductData } from '@nordic-web/rest-codegen/generated/subscription'
import { useCheckoutPage } from '@/features/checkout/hooks/use-checkout-page'

export type CheckoutState = {
  hasAcceptedTerms: boolean
  setHasAcceptedTerms: (arg0: boolean) => void
  isCheckingOutWithBinding: boolean
  toggleBinding: (arg0: boolean) => void
  isAdFree: boolean
  setAdFree: (arg0: boolean) => void
  productDetails?: PackageProductData
  resetCheckoutState: () => void
  conversionType?: Conversions
  isWithAdsQuery?: string
  isWithBindingQuery?: string
}

export const CheckoutStateContext = createContext<CheckoutState | undefined>(undefined)

export const CheckoutStateProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [isCheckingOutWithBinding, toggleBinding] = useState(false)
  const [isAdFree, setAdFree] = useState(false)
  const { data: checkoutPageData } = useCheckoutPage()

  const productDetails = useMemo(() => {
    if (!checkoutPageData) return
    if (isCheckingOutWithBinding) {
      return checkoutPageData.packageData.defaultBindingProduct
    }
    return checkoutPageData.packageData.defaultProduct
  }, [checkoutPageData, isCheckingOutWithBinding])

  const { withAds, withAdFree, withBinding } = router.query
  useEffect(() => {
    if (withAds) {
      setAdFree(!(withAds === 'true'))
    }
    if (withAdFree) {
      setAdFree(withAdFree === 'true')
    }
    if (withBinding) {
      toggleBinding(withBinding === 'true')
    }
  }, [withAds, withAdFree, withBinding])

  const resetCheckoutState = useCallback(() => {
    setHasAcceptedTerms(false)
    toggleBinding(false)
    setAdFree(false)
  }, [])

  return (
    <CheckoutStateContext.Provider
      value={{
        hasAcceptedTerms,
        setHasAcceptedTerms,
        isCheckingOutWithBinding,
        toggleBinding,
        isAdFree,
        setAdFree,
        productDetails,
        resetCheckoutState,
        conversionType: checkoutPageData?.conversion,
        isWithAdsQuery: typeof withAds === 'string' ? withAds : undefined,
        isWithBindingQuery: typeof withBinding === 'string' ? withBinding : undefined,
      }}
    >
      {children}
    </CheckoutStateContext.Provider>
  )
}

export const useCheckoutState = () => {
  const state = useContext(CheckoutStateContext)

  if (typeof state === 'undefined') {
    throw new Error('useCheckoutState must be used within a CheckoutStateContext')
  }

  return state
}
