import { isClientSide } from '@nordic-web/utils/misc/detect-side'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    safari: LegitimateAny
  }
}

export function isMobileClient(): boolean {
  const uaData = getUserAgentData()
  if (uaData) {
    return uaData.mobile
  }

  return /android|blackberry|windows phone|iemobile|wpdesktop/.test(getUserAgent().toLowerCase()) || isIOSClient()
}
export function isIOSClient(): boolean {
  const uaData = getUserAgentData()
  if (uaData?.platform) {
    const platform = uaData.platform.toLowerCase()
    // Newer iPads spoof Safari to mac. Unfortunately, we need to be client-side in order to detect this via navigator.maxTouchPoints.
    return platform === 'ios' || (isClientSide && platform === 'macos' && navigator.maxTouchPoints > 1)
  }

  const ua = getUserAgent().toLowerCase()
  return (
    /iphone|ipod|ipad/.test(ua) || // naive check
    // Newer iPads spoof Safari to mac. Unfortunately, we need to be client-side in order to detect this via navigator.maxTouchPoints.
    (isClientSide && /macintosh/i.test(ua) && navigator.maxTouchPoints > 1)
  )
}

export function isAndroidClient(): boolean {
  const uaData = getUserAgentData()
  if (uaData?.platform) {
    return uaData.platform.toLowerCase() === 'android'
  }

  return /android/.test(getUserAgent().toLowerCase())
}

function getUserAgent(): string {
  return window.navigator.userAgent
}

function getUserAgentData() {
  return window.navigator.userAgentData
}

export function isFirefox() {
  return typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().includes('firefox')
}

export function isSafariDesktop() {
  return window.safari !== undefined
}
