import type { PlayableVideoPageContent } from '@/features/video/types'

export const isLiveContent = (content?: PlayableVideoPageContent): boolean => {
  switch (content?.__typename) {
    case 'Channel':
      return true
    case 'Clip':
    case 'SportEvent':
    case 'Episode':
    case 'Movie': {
      return content.isLiveContent
    }
    case undefined:
      return false
  }
}
