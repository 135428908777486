import { useRef } from 'react'
import Link from 'next/link'
import { Filter, Stack, UtilHorizontalScrollArea } from '@nordic-web/ui-components'

type ButtonLinkBarProps = {
  items: Item[]
  replace?: boolean
}

type Item = {
  selected: boolean
  href: string
  label: string
}

export const ButtonLinkBar = ({ items, replace = false }: ButtonLinkBarProps) => {
  const ref = useRef<HTMLButtonElement | null>(null)

  return (
    <UtilHorizontalScrollArea nwFullWidth nwScrollStartRef={ref}>
      <Stack nwGap={2} nwDirection="row">
        {items.map(({ href, label, selected }) => (
          <Link passHref legacyBehavior key={href} href={href} scroll={false} replace={replace}>
            <Filter ref={selected ? ref : undefined} draggable="false" as="a" nwSelected={selected}>
              {label}
            </Filter>
          </Link>
        ))}
      </Stack>
    </UtilHorizontalScrollArea>
  )
}
