import { parseJwt } from '@nordic-web/utils/authentication/token'
import { getServerTime } from '@nordic-web/utils/date/get-server-time'

export const isTokenExpired = (token?: string) => {
  if (!token) {
    return false
  }

  const parsedJwt = parseJwt(token)

  if (!parsedJwt) {
    return false
  }

  const { exp } = parsedJwt

  const now = Math.floor(getServerTime().getTime() / 1000)

  return now > exp
}
