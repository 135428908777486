import React from 'react'
import { useTheme } from 'styled-components'
import { CardContainer, CardImageContainer, CardOverlayContainer, TypographyText } from '@nordic-web/ui-components'
import { FadeInImage } from '@/components/fade-in-image'
import { usePrefetchPageQuery } from '@/components/prefetch-links/page-prefetch-link'
import type { Url } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'

type PageCardProps = {
  id: string
  href: Url
  title: string
  imageSource: string
  gradientColor?: string | null
  onCardClick?: () => void
}

export const PageCard = ({ id, title, imageSource, gradientColor, href, onCardClick }: PageCardProps) => {
  const prefetch = usePrefetchPageQuery(id)
  const theme = useTheme()

  return (
    <AssetTracking content_media_id={id}>
      {({ trackOnAssetClick }) => (
        <CardContainer
          href={href}
          data-testid="page"
          onMouseEnter={prefetch}
          onClick={() => {
            onCardClick?.()
            trackOnAssetClick()
          }}
        >
          <CardImageContainer>
            <FadeInImage width={800} height={450} alt={title} source={imageSource} />
            <CardOverlayContainer nwBackgroundColor={gradientColor || theme.color.base.backgroundLight}>
              <TypographyText nwColor="primary" as="h3" nwVariant="category">
                {title}
              </TypographyText>
            </CardOverlayContainer>
          </CardImageContainer>
        </CardContainer>
      )}
    </AssetTracking>
  )
}
