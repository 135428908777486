import type { PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

type Context = {
  history: string[]
  previousPath?: string
}

const RouteHistoryContext = createContext<Context>({
  history: [],
  previousPath: undefined,
})

export function RouteHistoryProvider({ children }: PropsWithChildren) {
  const { asPath } = useRouter()
  const [history, setHistory] = useState<string[]>([])

  useEffect(() => {
    setHistory((prevHistory) => [...prevHistory, asPath])
  }, [asPath])

  return (
    <RouteHistoryContext.Provider
      value={{
        history,
        previousPath: history[history.length - 2],
      }}
    >
      {children}
    </RouteHistoryContext.Provider>
  )
}

export function useRouteHistory() {
  return useContext(RouteHistoryContext)
}
