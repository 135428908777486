// Can be fetched from https://tiering-api.clpl.mtv.a2d-dev.tv/v1/tiers
/*
Can be mapped with:
data.data.map(item => ({
  id: item.id,
  name: item.entitlements[0].vimond.name
}))
*/

export const mtvTiers = [
  {
    id: 'mtv-tier-0',
    name: 'Free without login',
  },
  {
    id: 'mtv-tier-1',
    name: 'Free with login',
  },
  {
    id: 'mtv-tier-2',
    name: 'MTV Katsomo+ (HVOD)',
  },
  {
    id: 'mtv-tier-3',
    name: 'MTV Katsomo+ (SVOD)',
  },
  {
    id: 'mtv-tier-4',
    name: 'MTV Katsomo+ (HVOD) Sport',
  },
  {
    id: 'mtv-tier-5',
    name: 'MTV Katsomo+ (SVOD) Sport',
  },
  {
    id: 'mtv-tier-6',
    name: 'MTV Katsomo Lapset (AVOD)',
  },
  {
    id: 'mtv-tier-7',
    name: 'MTV Katsomo Lapset (HVOD)',
  },
  {
    id: 'mtv-tier-8',
    name: 'MTV Katsomo Lapset (SVOD)',
  },
]
