import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Stack, TypographyText } from '@nordic-web/ui-components'

const Nav = styled.nav({
  textShadow: '1px 1px 5px #000',
})

const Li = styled.li((p) => ({
  [`&:not(:last-child)::after`]: {
    content: '"/"',
    paddingInlineStart: p.theme.space(2),
  },
}))

const LinkItem = styled(Link)(({ theme }) => ({
  color: theme.color.text.primary,
  ...theme.typography.body4,
}))

const Current = styled(TypographyText)({
  flexShrink: 0,
})

type BreadcrumbsLink = { title: string; href: string }

type BreadcrumbsProps = {
  links: BreadcrumbsLink[]
  current: string
  /**
   * If true the last breadcrumb will be rendered as an h1
   */
  isCurrentHeading?: boolean
}

export const Breadcrumbs = ({ links, current, isCurrentHeading }: BreadcrumbsProps) => {
  return (
    <Nav>
      <Stack nwGap={2} nwDirection="row" nwAlignItems="baseline" as="ol">
        {links.map((link, i) => (
          <Li key={i}>
            <LinkItem href={link.href}>{link.title}</LinkItem>
          </Li>
        ))}
        <Li aria-current="page">
          <Current as={isCurrentHeading ? 'h1' : 'p'} nwVariant="body4">
            {current}
          </Current>
        </Li>
      </Stack>
    </Nav>
  )
}
