import styled from 'styled-components'
import { ButtonGroup } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'

export const HeroButtonGroup = styled(ButtonGroup)({
  width: '100%',
  [tablet]: {
    width: 'auto',
  },
})
