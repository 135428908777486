import styled from 'styled-components'
import { Button } from '@nordic-web/ui-components'

type StyledButtonProps = {
  minWidth?: number
}

const StyledButton = styled(Button)<StyledButtonProps>((props) => ({
  minWidth: props.minWidth || 100,
}))

export function ButtonLoading() {
  return <StyledButton nwVariant="primary" nwLoading />
}
