import { isServerSide } from '@nordic-web/utils/misc/detect-side'

// https://www.codemzy.com/blog/react-query-cachetime-staletime
const fiveMinutes = 1000 * 60 * 5
export const getStaleAndCacheTime = (staleTime: number) => {
  // Dont cache on the server side, it can cause memory leaks
  if (isServerSide) {
    return {
      staleTime: 0,
      cacheTime: 0,
    }
  }

  return {
    staleTime,
    cacheTime: staleTime + fiveMinutes,
  }
}
