import { getPageName } from '@/helpers/get-page-name'
import { dataLayerPush } from '@/tracking/tracking'

export const toggleFavoriteTracking = ({ assetId, isFavorite }: { assetId: string; isFavorite: boolean }) => {
  return dataLayerPush({
    event: isFavorite ? 'remove_favorite' : 'add_favorite',
    content_media_id: assetId,
    page_name: getPageName(),
  })
}
