export enum PlayerAction {
  EnterFullscreen = 'PlayerAction/EnterFullscreen',
  ExitFullscreen = 'PlayerAction/ExitFullscreen',
  SetFullscreen = 'PlayerAction/SetFullscreen',
  CreditsStarted = 'PlayerAction/CreditsStarted',
  AdEnded = 'PlayerAction/AdEnded',
  VideoStarted = 'PlayerAction/VideoStarted',
  VideoEnded = 'PlayerAction/VideoEnded',
  Reset = 'PlayerAction/Reset',
  DismissAutomaticEnd = 'PlayerAction/DismissAutomaticEnd',
  ShowEndscreen = 'PlayerAction/ShowEndscreen',
  ShouldLoadEndscreenData = 'PlayerAction/ShouldLoadEndscreenData',
  PlayerSkinVisibilityChange = 'PlayerAction/PlayerSkinVisibilityChange',
  SetIsBeforeCreditStart = 'PlayerAction/SetIsBeforeCreditStart',
}
