import type { CSSProperties } from 'react'
import styled from 'styled-components'
import { combineResponsiveStyles, generateResponsiveStyles } from '@nordic-web/ui-styles'
import type { SpaceFactor } from '@nordic-web/ui-tokens'
import type { NonEmptyArray } from '@nordic-web/ui-tokens/src/utils/typescript'

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
type JustifyContent = 'start' | 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
type AlignItems = CSSProperties['alignItems']
type MaxWidth = CSSProperties['maxWidth']
type MinHeight = CSSProperties['minHeight']

export type StackGap = SpaceFactor | NonEmptyArray<SpaceFactor>

export type StackProps = {
  nwGap?: StackGap
  nwDirection?: Direction | NonEmptyArray<Direction>
  nwJustifyContent?: JustifyContent | NonEmptyArray<JustifyContent>
  nwAlignItems?: AlignItems | NonEmptyArray<AlignItems>
  nwMaxWidth?: MaxWidth | NonEmptyArray<MaxWidth>
  nwMinHeight?: MinHeight | NonEmptyArray<MinHeight>
  nwFlex?: number
  nwFullWidth?: boolean
}

export const Stack = styled.div<StackProps>(
  ({
    nwGap = 0,
    nwDirection = 'column',
    nwAlignItems = 'stretch',
    nwJustifyContent = 'flex-start',
    nwMaxWidth,
    nwMinHeight,
    nwFlex,
    nwFullWidth,
    theme,
  }) => {
    const nwGapValue = Array.isArray(nwGap) ? nwGap.map((gap) => theme.space(gap)) : [theme.space(nwGap)]
    const nwDirectionValue = Array.isArray(nwDirection) ? nwDirection : [nwDirection]
    const nwJustifyContentValue = Array.isArray(nwJustifyContent) ? nwJustifyContent : [nwJustifyContent]
    const nwAlignItemsValue = Array.isArray(nwAlignItems) ? nwAlignItems : [nwAlignItems]
    const nwMaxWidthValue = Array.isArray(nwMaxWidth) ? nwMaxWidth : [nwMaxWidth]
    const nwMinHeightValue = Array.isArray(nwMinHeight) ? nwMinHeight : [nwMinHeight]

    return {
      display: 'flex',
      flex: nwFlex,
      ...(nwFullWidth && { width: '100%' }),
      ...combineResponsiveStyles([
        generateResponsiveStyles('gap', nwGapValue),
        generateResponsiveStyles('flexDirection', nwDirectionValue),
        generateResponsiveStyles('justifyContent', nwJustifyContentValue),
        generateResponsiveStyles('alignItems', nwAlignItemsValue),
        generateResponsiveStyles('maxWidth', nwMaxWidthValue),
        generateResponsiveStyles('minHeight', nwMinHeightValue),
      ]),
    }
  }
)
