import { allPaths } from './paths'

export type PathKey = keyof typeof allPaths
export type TranslatedPaths = Record<PathKey, string>

const getPathsByBrand = (brand: 'mtv' | 'tv4') => {
  return Object.keys(allPaths).reduce((acc, key) => {
    const validKey = key as PathKey
    acc[validKey] = allPaths[validKey][brand]
    return acc
  }, {} as TranslatedPaths)
}
export const tv4Paths = getPathsByBrand('tv4')
export const mtvPaths = getPathsByBrand('mtv')
