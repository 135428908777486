import type { NextSeoProps } from 'next-seo'
import { NextSeo } from 'next-seo'
import { usePageMetaQuery } from '@nordic-web/gql'
import { getOgImages } from '@/components/seo/seo'
import { ErrorHandler } from '@/layouts/error-handler'

type Props = {
  pageId: string
  nextSeoProps?: NextSeoProps
}

export const PageMetaTags = ({ pageId, nextSeoProps }: Props) => {
  const { data, loading: isLoading } = usePageMetaQuery({
    variables: {
      pageId,
    },
  })

  if (isLoading) return null

  // This is where we trigger the 404 page, since we dont server side render the actual Page
  if (!data?.page?.id) {
    return <ErrorHandler statusCode={404} />
  }

  const page = data?.page
  const metaTitle = page?.seoContent?.metaTitle
  const metaDescription = page?.seoContent?.metaDescription
  const images = page?.images

  return (
    <NextSeo
      title={metaTitle ?? undefined}
      description={metaDescription ?? undefined}
      openGraph={{
        title: metaTitle ?? undefined,
        description: metaDescription ?? undefined,
        ...(!images?.image16x9?.isFallback ? { images: getOgImages(images?.image16x9?.sourceEncoded) } : {}),
      }}
      {...nextSeoProps}
    />
  )
}
