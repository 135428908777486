import type { PackageTierLinkFieldsFragment, SinglePanelFieldsFragment } from '@nordic-web/gql'
import { MediaClassification, useSuggestedEpisodeQuery } from '@nordic-web/gql'
import type { LabelFactoryProps } from '@/components/label-factory'
import type { FavoriteMediaTypes } from '@/features/favorite/utils'
import { paths } from '@/helpers/paths'

type ParseLinkResult = {
  ctaHref?: string
  secondaryCtaHref?: string
  isLoading?: boolean
  mediaLabelData?: LabelFactoryProps['media']
  favoriteType?: FavoriteMediaTypes
  favoriteId?: string
  trackingId?: string
  shouldAlwaysShowCdpButton?: boolean
  upsellData?: {
    tierName?: string
    packageTierLink: PackageTierLinkFieldsFragment
  } | null
}

export const useParseLink = (link: SinglePanelFieldsFragment['link']): ParseLinkResult => {
  const isSeriesLink = link?.__typename === 'SinglePanelSeriesLink'

  const { data: suggestedEpisodeData, loading: isLoading } = useSuggestedEpisodeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isSeriesLink,
    variables: { id: isSeriesLink ? link.series.id : '' },
    ssr: false,
  })

  switch (link?.__typename) {
    case 'SinglePanelMovieLink':
      return {
        trackingId: link.movie.id,
        favoriteType: link.movie.__typename,
        favoriteId: link.movie.id,
        ctaHref: paths.video.urlString({ assetId: link.movie.id, slug: link.movie.slug }),
        secondaryCtaHref: paths.program.urlString({ id: link.movie.id, slug: link.movie.slug }),
        upsellData: link.movie?.upsell,
        mediaLabelData: link.movie,
      }
    case 'SinglePanelSeriesLink': {
      const episode = suggestedEpisodeData?.series?.suggestedEpisode?.episode
      return {
        trackingId: link.series.id,
        favoriteType: link.series.__typename,
        favoriteId: link.series.id,
        ctaHref: episode ? paths.video.urlString({ assetId: episode.id, slug: episode.slug }) : undefined,
        secondaryCtaHref: paths.program.urlString({ id: link.series.id, slug: link.series.slug }),
        shouldAlwaysShowCdpButton: link.series.mediaClassification !== MediaClassification.News,
        upsellData: link.series?.upsell,
        mediaLabelData: {
          label: link.series.label ?? undefined,
          isLiveContent: episode?.isLiveContent,
          liveEventEnd: episode?.liveEventEnd,
          playableFrom: episode?.playableFrom,
          upsell: link.series.upsell,
        },
        isLoading,
      }
    }
    case 'SinglePanelEpisodeLink': {
      const seriesId = link.episode.series?.id
      return {
        favoriteType: 'Series',
        trackingId: link.episode.id,
        favoriteId: link.episode.series?.id,
        ctaHref: paths.video.urlString({ assetId: link.episode.id, slug: link.episode.slug }),
        upsellData: link.episode?.upsell,
        secondaryCtaHref: seriesId
          ? paths.program.urlString({ id: seriesId, slug: link.episode.series?.slug })
          : undefined,
        mediaLabelData: link.episode,
      }
    }

    case 'SinglePanelClipLink': {
      const parentId = link.clip.parent?.id
      return {
        trackingId: link.clip.id,
        favoriteId: link.clip.id,
        ctaHref: paths.clips.urlString({ assetId: link.clip.id, slug: link.clip.slug }),
        secondaryCtaHref: parentId
          ? paths.program.urlString({ id: parentId, slug: link.clip.parent?.slug })
          : undefined,
      }
    }
    case 'SinglePanelPageLink':
      return {
        trackingId: link.page.id,
        secondaryCtaHref: paths.page.urlString({ id: link.page.id }),
      }
    case 'SinglePanelSportEventLink':
      return {
        trackingId: link.sportEvent.id,
        ctaHref: paths.video.urlString({ assetId: link.sportEvent.id, slug: link.sportEvent.slug }),
        secondaryCtaHref: paths.program.urlString({ id: link.sportEvent.id, slug: link.sportEvent.slug }),
        upsellData: link.sportEvent?.upsell,
        mediaLabelData: link.sportEvent,
      }
    case 'SinglePanelChannelLink':
      return {
        trackingId: link.channel.id,
        ctaHref: paths.video.urlString({ assetId: link.channel.id, slug: link.channel.slug }),
      }
    default: {
      return {}
    }
  }
}
