import type { ReactElement, ReactNode } from 'react'
import React, { Children, cloneElement, isValidElement } from 'react'
import styled from 'styled-components'
import type { LabelPlacement, LabelProps } from '../label/label'

const LabelWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const childStyling = {
  start: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  middle: {
    borderRadius: 0,
  },
  end: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}

type ChildProps = Pick<LabelProps, 'groupStyling' | 'nwPlacement'>

export type LabelGroupProps = {
  children: ReactNode
  className?: string
} & LabelPlacement

export const LabelGroup = ({ children, nwPlacement = 'default', ...rest }: LabelGroupProps) => {
  const childrenArray = Children.toArray(children).filter(isValidElement) as ReactElement[]

  const childrenWithProps = childrenArray.map((child, index) => {
    let position: 'start' | 'middle' | 'end' = 'middle'

    if (index === 0) {
      position = 'start'
    } else if (index === childrenArray.length - 1) {
      position = 'end'
    }

    return isValidElement(child)
      ? cloneElement(child as ReactElement<ChildProps>, {
          groupStyling: childStyling[position],
          nwPlacement,
        })
      : child
  })
  return <LabelWrapper {...rest}>{childrenWithProps}</LabelWrapper>
}
