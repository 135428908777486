import styled from 'styled-components'
import { Image } from '@nordic-web/ui-components'
import { combineResponsiveStyles, generateResponsiveStyles } from '@nordic-web/ui-styles'

export const BaseCampaignImage = styled(Image)({
  ...combineResponsiveStyles([
    generateResponsiveStyles('width', [96, 96, 120]),
    generateResponsiveStyles('height', [96, 96, 120]),
  ]),
})

export const ThemePanelCampaignImage = styled(BaseCampaignImage)({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 50,
  transform: 'translate(20%, -20%)',
})
