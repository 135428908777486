import React from 'react'
import styled from 'styled-components'
import { generateResponsiveStyles } from '@nordic-web/ui-styles'
import { HeaderLogo } from '@/layouts/header/header-logo'
import { Search } from '@/layouts/header/header/search'
import { User } from '@/layouts/header/header/user'
import { useMainMenuItems } from '@/layouts/header/hooks'
import {
  aboveDesktopHeader,
  aboveLargeDesktopHeader,
  aboveTabletHeader,
  belowTabletHeader,
} from '@/layouts/header/style'
import { isFirefox } from '@/utils/detect-device'
import { Hamburger } from './hamburger'
import { MenuLink, iconSizing } from './style'

type HeaderProps = {
  onMenuToggle: () => void
  hasTransparentHeader: boolean
}

export const Header = ({ onMenuToggle, hasTransparentHeader }: HeaderProps) => {
  const mainMenuItems = useMainMenuItems()

  return (
    <Wrapper data-test="header-container" role="menubar">
      <BlurryHeaderBackground hasTransparentHeader={hasTransparentHeader}>
        <Inner>
          <First>
            <HeaderLogo />
            <Nav>
              <MenuItems>
                {mainMenuItems.map((item) => (
                  <li key={item.id}>
                    <MenuLink
                      pageId={item.pagePrefetchId}
                      href={item.route}
                      aria-current={item.active ? 'page' : undefined}
                      role="menuitem"
                    >
                      <span>{item.text}</span>
                    </MenuLink>
                  </li>
                ))}
              </MenuItems>
            </Nav>
          </First>
          <Last>
            <Search />
            <User />
            <Hamburger onClick={onMenuToggle} />
          </Last>
        </Inner>
      </BlurryHeaderBackground>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  '.hide-header &': {
    display: 'none',
  },
})

type BackgroundProps = {
  hasTransparentHeader: boolean
}

const transition = 'all 0.3s linear'

// Element holding background cause Safari has issues with rendering
// Transparent & Fixed elements.
export const BlurryHeaderBackground = styled.div<BackgroundProps>(({ theme, hasTransparentHeader }) => {
  // Firefox has performance issues with backdrop-filter
  const shouldAddBlur = !hasTransparentHeader && !isFirefox()

  return {
    position: 'relative',
    transition,

    ...(shouldAddBlur && {
      backdropFilter: 'blur(15px)',
    }),

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition,
      pointerEvents: 'none',
      zIndex: -1,
      ...(!hasTransparentHeader && {
        backgroundColor: `var(--header-bg, ${theme.color.base.background})`,
        opacity: 0.8,
      }),
    },
  }
})

const Inner = styled.div(({ theme }) => ({
  alignItems: 'center',
  height: 'var(--header-height)',
  margin: 'auto',
  justifyContent: 'space-between',
  display: 'flex',
  [aboveTabletHeader]: {
    maxWidth: '90%',
  },
  [belowTabletHeader]: {
    paddingInline: theme.space(6),
  },
}))

const First = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  height: 'var(--header-height)',
})

const Last = styled.ul(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  marginInlineStart: 'auto',
  height: 'var(--header-height)',
  ...generateResponsiveStyles('gap', [theme.space(5), theme.space(8)]),
}))

const Nav = styled.nav({
  display: 'none',
  [aboveTabletHeader]: {
    display: 'inline-block',
  },
})

const MenuItems = styled.ul(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  transition: 'width 0.5s ease-in',
  height: 'var(--header-height)',
  marginInlineStart: theme.space(6),
  [aboveDesktopHeader]: {
    marginInlineStart: theme.space(8),
  },
  [aboveLargeDesktopHeader]: {
    marginInlineStart: theme.space(10),
  },
  '> li:not(:last-child)': {
    marginInlineEnd: theme.space(4),
    [aboveDesktopHeader]: {
      marginInlineEnd: theme.space(5),
    },
    [aboveLargeDesktopHeader]: {
      marginInlineEnd: theme.space(8),
    },
  },
  '> li': {
    position: 'relative',
    height: 'var(--header-height)',
    ...theme.typography.nav,
    ...iconSizing,
  },
}))
