import type { FC } from 'react'
import styled from 'styled-components'
import { Button, Stack } from '@nordic-web/ui-components'
import { MediaDetails } from '@/features/channel-epg-panel/components/details-dialog/media-details'
import { MediaDetailsMissing } from '@/features/channel-epg-panel/components/details-dialog/media-details-missing'
import type { ChannelEpgBase } from '@/features/channel-epg-panel/components/details-dialog/types'
import { useGlobalEventListener } from '@/hooks/use-global-events'

type EpgDetailsProps = ChannelEpgBase & {
  onNext(): void
  onPrevious(): void
  hasPrevious: boolean
  hasNext: boolean
}

const DetailsStack = styled(Stack)({
  minHeight: 600,
})

export const EpgDetails: FC<EpgDetailsProps> = ({ channel, epgItem, onNext, onPrevious, hasNext, hasPrevious }) => {
  useGlobalEventListener('keydown', (event) => {
    const key = (event as KeyboardEvent).key
    if (key === 'ArrowRight') {
      onNext()
    } else if (key === 'ArrowLeft') {
      onPrevious()
    }
  })

  const nextPrevButtons = (
    <Stack nwJustifyContent="space-between" nwDirection="row">
      <Button disabled={!hasPrevious} nwVariant="ghost" nwIconFirst="arrow-left" onClick={onPrevious} />
      <Button disabled={!hasNext} nwVariant="ghost" nwIconFirst="arrow-right" onClick={onNext} />
    </Stack>
  )

  return (
    <DetailsStack nwJustifyContent="space-between">
      {epgItem.media ? (
        <MediaDetails channel={channel} epgItem={epgItem}>
          {nextPrevButtons}
        </MediaDetails>
      ) : (
        <MediaDetailsMissing channel={channel} epgItem={epgItem}>
          {nextPrevButtons}
        </MediaDetailsMissing>
      )}
    </DetailsStack>
  )
}
