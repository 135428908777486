import type { PropsWithChildren } from 'react'
import { useIsClientSide } from '../hooks/use-is-client-side'

export const RenderClientSide = ({ children }: PropsWithChildren) => {
  const isClientSide = useIsClientSide()

  if (!isClientSide) return null

  return children
}
