import React, { useId } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import type { UseFormRegisterReturn } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'
import { FormInputBase } from '../_base/form-input-base/form-input-base'
import { FormLabelBase } from '../_base/form-label-base/form-label-base'
import { FormWrapperBase } from '../_base/form-wrapper-base/form-wrapper-base'
import { FormValidationMessage } from '../form-validation-message/form-validation-message'

export type FormInputTextAreaProps = {
  nwLabel: string
  nwErrorMessage?: string
  nwRows?: number
  nwMaxRows?: number
  nwColumns?: number
  nwMaxLength?: number
  /**
   * nwShowInputGuide can show only maxLength or, if nwCharacterCount is provided, the ratio left
   */
  nwShowInputGuide?: boolean
  /**
   * nwCharacterCount needs to be updated outside of this component
   */
  nwCharacterCount?: number
  /**
   * Minimum requirement is to pass the name to the register function from react-hook-form: `validators={register('input-name')}`
   */
  validators: UseFormRegisterReturn
} & Omit<ComponentPropsWithoutRef<'textarea'>, 'name' | 'style'>

export const FormInputTextArea = ({
  nwLabel,
  nwErrorMessage,
  nwRows = 2,
  nwMaxRows,
  nwColumns = 50,
  nwMaxLength = 150,
  nwCharacterCount,
  nwShowInputGuide = false,
  validators,
  ...forwardProps
}: FormInputTextAreaProps) => {
  const randomId = useId()
  const id = forwardProps.id ?? `form-input-${randomId}`

  const textLengthComment = nwShowInputGuide
    ? nwCharacterCount
      ? `${nwCharacterCount}/${nwMaxLength}`
      : nwMaxLength
    : null

  return (
    <Wrapper disabled={forwardProps.disabled}>
      <Input
        as={TextareaAutosize}
        minRows={nwRows}
        maxRows={nwMaxRows ?? nwRows}
        cols={nwColumns}
        maxLength={nwMaxLength}
        id={id}
        placeholder=" "
        aria-invalid={nwErrorMessage ? 'true' : 'false'}
        {...validators}
        {...forwardProps}
      />
      <Label htmlFor={id} rows={nwRows}>
        {nwLabel}
      </Label>
      <CharacterCount>{textLengthComment}</CharacterCount>
      <FormValidationMessage nwMessage={nwErrorMessage} />
    </Wrapper>
  )
}

const Wrapper = styled(FormWrapperBase)({
  position: 'relative',
})

const Label = styled(FormLabelBase)<{ rows: number }>(({ theme, rows }) => ({
  alignSelf: 'normal',
  paddingTop: rows > 1 ? theme.space(4) : theme.space(3),
  '*:focus-within > &, *:not(:placeholder-shown) + &': {
    transform: 'scale(.75) translateY(-8px)',
  },
}))

const Input = styled(FormInputBase)(({ theme }) => ({
  gridRow: 1,
  gridColumn: '1 / -1',
  resize: 'none',
  ...theme.typography.info,
  paddingInline: theme.space(3),
  paddingBlockStart: theme.space(5),
}))

const CharacterCount = styled.span(({ theme }) => ({
  position: 'absolute',
  top: theme.space(2),
  right: theme.space(3),
  transform: `scale(.75) translateY(-8px)`, // Match label
  pointerEvents: 'none',
  ...theme.typography.body4,
  opacity: 0.5,
}))
