import type { FC } from 'react'
import { useState } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import styled from 'styled-components'
import type { ChannelFieldsFragment, EpgFieldsFragment } from '@nordic-web/gql'
import { useEpgQuery } from '@nordic-web/gql'
import { Button, CircleSpinner, Stack } from '@nordic-web/ui-components'
import { isPlaying } from '@nordic-web/utils/epg'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'
import { EpgList } from '@/components/epg-list'
import { ChannelEpgContent } from '@/features/channel-epg-panel/components/channel-epg-content'
import { DetailsDialog } from '@/features/channel-epg-panel/components/details-dialog'
import { MissingEpgMessage } from '@/features/channel-epg-panel/components/missing-epg-message'
import { paths } from '@/helpers/paths'

type SmallScreenChannelEpgProps = {
  channel: ChannelFieldsFragment
  date: number
}

const StickyButton = styled(Button)(({ theme }) => ({
  position: 'sticky',
  right: theme.space(4),
  bottom: theme.space(4),
  alignSelf: 'flex-end',
}))

export const SmallScreenChannelEpg: FC<SmallScreenChannelEpgProps> = ({ channel, date }) => {
  useReRenderWithInterval()

  const { id } = channel
  const t = useTranslations()
  const [shouldShowFullDayInternal, setShouldShowFullDayInternal] = useState(false)
  const [selectedEpgItem, setSelectedEpgItem] = useState<EpgFieldsFragment | null>(null)

  const { data, loading: isLoading } = useEpgQuery({
    variables: {
      id,
      fullBroadcastDay: true,
      date,
    },
  })

  const epg = (data?.media?.__typename === 'Channel' && data.media.epg) || []
  const hasEpgItems = epg.length > 0
  const liveProgramIndex = epg.findIndex(isPlaying)
  const startIndex = Math.max(0, liveProgramIndex - 2)
  const collapsedEpg = epg.slice(startIndex)
  const filteredEpgItems = shouldShowFullDayInternal ? epg : collapsedEpg
  const shouldShowExpandButton = epg.length > collapsedEpg.length

  if (isLoading) {
    return <CircleSpinner nwVariant="secondary" nwPaddingTop />
  }

  return (
    <>
      <Stack nwGap={5}>
        {shouldShowExpandButton && (
          <Button
            nwLayout="full-width"
            nwVariant="subtle"
            nwSize="small"
            onClick={() => setShouldShowFullDayInternal(!shouldShowFullDayInternal)}
            nwIconFirst={shouldShowFullDayInternal ? 'chevron-up' : 'chevron-down'}
          >
            {shouldShowFullDayInternal ? t('epg__hide_earlier') : t('epg__show_earlier')}
          </Button>
        )}
        <ChannelEpgContent>
          {hasEpgItems ? (
            <EpgList onClick={setSelectedEpgItem} epg={filteredEpgItems} />
          ) : (
            <MissingEpgMessage channel={channel} />
          )}
        </ChannelEpgContent>
        <Link href={paths.video.urlString({ assetId: channel.id, slug: channel.slug })} passHref legacyBehavior>
          <StickyButton nwIconFirst="play" nwVariant="secondary">
            {t('channels__play')}
          </StickyButton>
        </Link>
      </Stack>
      <DetailsDialog
        channel={channel}
        epgItems={filteredEpgItems}
        selectEpgItem={setSelectedEpgItem}
        selectedEpgItem={selectedEpgItem}
      />
    </>
  )
}
