import type { LivePanelQuery } from '@nordic-web/gql'
import { nonNullable } from '@nordic-web/utils/typescript'

export const mapLivePanelItems = (data?: LivePanelQuery) => {
  const panel = data?.panel
  if (!panel) return []

  const __typename = panel.__typename
  if (__typename !== 'LivePanel') return []

  return panel.content.items
    .map((item) => {
      if (item.__typename === 'LivePanelEpisodeItem') return item.episode
      if (item.__typename === 'LivePanelMovieItem') return item.movie
      if (item.__typename === 'LivePanelChannelItem') return item.channel
      if (item.__typename === 'LivePanelSportEventItem') return item.sportEvent
    })
    .filter(nonNullable)
}
