import styled from 'styled-components'
import { belowTablet } from '@nordic-web/ui-styles'

export const HorizontalScrollAreaContainer = styled.div<{
  rightFadeActive: boolean
  leftFadeActive: boolean
  fadeColor: string
}>(({ rightFadeActive, leftFadeActive }) => ({
  position: 'relative',

  '.left-button, .right-button': {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    transition: 'opacity 200ms ease-in-out, visibility 200ms ease-in-out',
    [belowTablet]: {
      display: 'none',
    },
  },

  '.right-button': {
    opacity: rightFadeActive ? 1 : 0,
    visibility: rightFadeActive ? 'visible' : 'hidden',
    right: 0,
  },

  '.left-button': {
    opacity: leftFadeActive ? 1 : 0,
    visibility: leftFadeActive ? 'visible' : 'hidden',
  },
}))

export const FadeEdges = styled.div<{
  $fadeLeft?: boolean
  $fadeRight?: boolean
}>(({ $fadeLeft, $fadeRight }) => {
  const fadeWidth = 50
  const maskGradientArgs = [
    'to right',
    'transparent 0px',
    `black ${fadeWidth}px`,
    `black calc(100% - ${fadeWidth}px)`,
    'transparent 100%',
  ].join(',')

  const props = {
    maskImage: `linear-gradient(${maskGradientArgs})`,
    transition: 'mask-size 400ms ease, mask-position 400ms ease',
    // start with a mask that's 100% plus two the width of two "fades"
    maskSize: `calc(100% + ${2 * fadeWidth}px) 100%`,
    // by default move one fade to the left so the mask fades are "just outside of view"
    maskPosition: `${-fadeWidth}px 0px`,
  }

  if ($fadeLeft && $fadeRight) {
    // both fades should show -> shrink the mask so both edges are in view
    props.maskSize = '100% 100%'
  } else if ($fadeLeft || $fadeRight) {
    // one fade should show -> shrink the mask so it can only move one fade out of view
    props.maskSize = `calc(100% + ${fadeWidth}px) 100%`
  }

  if ($fadeLeft) {
    // if the left fade is active, move the mask so it reveals the left fade
    props.maskPosition = '0px 0px'
  }

  return props
})

export const ScrollArea = styled.div({
  display: 'flex',
  overflowX: 'auto',
  overflowY: 'visible',

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  '-ms-overflow-style': 'none', // IE and Edge
  'scrollbar-width': 'none', // Firefox

  '> *': {
    flex: '0 auto',
    flexShrink: 0,
  },
})

export const ScrollAreaWrapper = styled.div<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  position: 'relative',

  [String(ScrollArea)]: fullWidth
    ? {
        paddingLeft: '5vw',
        paddingRight: '5vw',
      }
    : undefined,
}))
