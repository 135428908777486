import { useContext } from 'react'
import { TrailersMutedStateContext } from '@/components/trailer/trailers-muted-state'

export const useTrailersMutedState = () => {
  const state = useContext(TrailersMutedStateContext)
  if (typeof state === 'undefined') {
    throw new Error('useTrailersMutedState must have a provider')
  }
  return state
}
