import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { nextConfig } from '@/helpers/env'
import { pathsWithoutCookieConsent } from '@/helpers/paths'

const ONETRUST_SUFFIX = nextConfig.bool('USE_ONETRUST_TEST_SCRIPT') ? `-test` : ''
const ONETRUST_DOMAIN = nextConfig.string('ONETRUST_DOMAIN')

export const OneTrustScript = () => {
  const router = useRouter()

  // Use useState to avoid losing state when the query param disapears on navigation
  const [disableCookieConsentBanner] = useState(router.query.disableCookieConsentBanner)

  if (!nextConfig.bool('ENABLE_THIRD_PARTY_SERVICES')) {
    return null
  }

  if (pathsWithoutCookieConsent.some((p) => p.isActive(router))) {
    return null
  }

  if (disableCookieConsentBanner) {
    return null
  }

  return (
    <>
      <Script
        charSet="UTF-8"
        data-document-language="true"
        data-domain-script={`${ONETRUST_DOMAIN}${ONETRUST_SUFFIX}`}
        id="onetrust_sdk"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      />

      <Script id="onetrust_script" src="/static/onetrust-init.js" />
    </>
  )
}
