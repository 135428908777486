import { isClientSide } from '@nordic-web/utils/misc/detect-side'

export const getPageName = (suffix = '') => {
  if (isClientSide) {
    const p = window.location.pathname + window.location.search
    try {
      return decodeURI(p) + suffix
    } catch (_e) {
      // Having the path decoded is just a nice bonus. If it cannot be decoded, just send it as is.
      return p + suffix
    }
  }
}
