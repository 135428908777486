import { decode } from 'base-64'

export type AccessTokenPayload = {
  uid: string
  exp: number
  entitlements: string[]
  iat: number
  entitlement_source: string
}

export const parseJwt = (token?: string | null): AccessTokenPayload | undefined => {
  if (!token) return
  try {
    const [_, encodedPayload] = token.split('.')
    if (encodedPayload) {
      const payload: AccessTokenPayload = JSON.parse(decode(encodedPayload))
      return payload
    }
  } catch (e) {
    console.error('Failed to get payload from token', e)
  }
}
export const getUserIdFromToken = (token?: string): string => {
  if (!token) return ''
  const payload = parseJwt(token)
  return payload?.uid ?? ''
}
