import React, { useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { A11yVisuallyHidden } from '@nordic-web/ui-components'
import { useMenuState } from '@/context/menu-state-context'
import { useGlobalEventListener } from '@/hooks/use-global-events'
import { Header } from './header'
import { MobileMenu } from './mobile_menu/mobile-menu'

type HeaderWrapperProps = {
  useContentColor?: boolean
}

export const HeaderSpacing = styled.div({
  height: 'calc(var(--header-height) + var(--page-top-padding))',
})

const HeaderWrapper = styled.div<HeaderWrapperProps>(({ useContentColor, theme }) => ({
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 2,
  '--header-bg': useContentColor ? 'var(--content-color)' : theme.color.base.background,
  height: 'var(--header-height)',
}))

const HeaderInner = styled.div({
  position: 'absolute',
  width: '100%',
})

type HeaderContainerProps = {
  useContentColor?: boolean
}

export const HeaderContainer = ({ useContentColor }: HeaderContainerProps) => {
  const { isMobileMenuOpen, toggleMobileMenuOpen, setIsMobileMenuOpen } = useMenuState()
  const [hasTransparentHeader, setHasTransparentHeader] = useState(true)
  const { pathname } = useRouter()
  const t = useTranslations()
  const isStartPage = pathname === '/'

  const headerWrapperRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = () => {
    if (headerWrapperRef.current) {
      if (window.scrollY === 0) {
        setHasTransparentHeader(true)
      } else if (hasTransparentHeader) {
        setHasTransparentHeader(false)
      }
    }
  }

  useGlobalEventListener('scroll', handleScroll)

  return (
    <HeaderWrapper ref={headerWrapperRef} useContentColor={useContentColor}>
      {isStartPage && <A11yVisuallyHidden as="h1">{t('brand_signature')}</A11yVisuallyHidden>}
      <HeaderInner>
        <Header hasTransparentHeader={hasTransparentHeader} onMenuToggle={toggleMobileMenuOpen} />
        <MobileMenu
          isOpen={isMobileMenuOpen}
          onMenuToggle={toggleMobileMenuOpen}
          setMobileMenuOpen={setIsMobileMenuOpen}
        />
      </HeaderInner>
    </HeaderWrapper>
  )
}
