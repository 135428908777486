import React, { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import styled, { useTheme } from 'styled-components'
import type { ServiceMessage, level } from '@nordic-web/rest-codegen/generated/service-messages'
import { Button, Icon } from '@nordic-web/ui-components'
import { trackDevEvent } from '@/tracking/track-dev-event'
import { UNSUPPORTED_BROWSER_MESSAGE_ID } from './service-messages.constants'

type PartialServiceMessage = Pick<ServiceMessage, 'id' | 'content' | 'link' | 'level'>
type IdObj = Pick<PartialServiceMessage, 'id'>

type MessageComponentProps = PartialServiceMessage & {
  onClose: ({ id }: IdObj) => void
}

type MessageProps = Omit<MessageComponentProps, 'id' | 'onClose'>

const Message = ({ content, link }: MessageProps) => {
  return (
    <div>
      {content}{' '}
      {link && (
        <a href={link?.url} rel="noopener noreferrer" target="_blank">
          {link?.text}
        </a>
      )}
    </div>
  )
}

const UnsupportedBrowserMessage = () => {
  const theme = useTheme()
  const t = useTranslations()

  useEffect(() => {
    trackDevEvent({
      label1: 'impression',
      label2: 'UnsupportedBrowserMessage',
    })
  }, [])

  return <div style={{ color: theme.color.text.primaryInverted }}>{t('alert__unsupported_browser')}</div>
}

export const MessageComponent = ({ content, id, onClose, link, level }: MessageComponentProps) => {
  const isUnsupportedBrowserMessage = id === UNSUPPORTED_BROWSER_MESSAGE_ID

  return (
    <SiteMessageContainer level={level}>
      <div>
        {level === 'severe' ? (
          <Icon nwVariant="alert-warning-outlined" nwSize="smallMedium" />
        ) : (
          <Icon nwVariant="alert-info-outlined" nwSize="smallMedium" />
        )}
      </div>
      {isUnsupportedBrowserMessage ? (
        <UnsupportedBrowserMessage />
      ) : (
        <Message content={content} link={link} level={level} />
      )}
      <div>
        <Button
          nwIconFirst="close-x"
          nwIconFirstColor="primaryInverted"
          nwVariant="ghost"
          onClick={() => onClose({ id })}
        />
      </div>
    </SiteMessageContainer>
  )
}

const SiteMessageContainer = styled.div<{ level: level }>(({ theme, level }) => {
  return {
    display: 'flex',
    background: level === 'severe' ? theme.color.base.primaryLight : theme.color.alert.alert,
    color: theme.color.text.primaryInverted,
    padding: '12px 5%',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    svg: {
      width: '32px',
      height: '28px',
    },
    div: {
      padding: '0 12px',
      '&:first-child': {
        paddingLeft: '0',
        display: 'flex',
        alignItems: 'center',
      },
      '&:last-child': {
        paddingRight: '0',
        marginLeft: 'auto',
      },
      a: {
        color: theme.color.text.primaryInverted,
        textDecoration: 'underline',
      },
    },
  }
})
