import type { FC } from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import { Stack } from '../../layout/stack-redux/stack'

export const circleSpinnerSizes = ['small', 'medium', 'large', 'button'] as const
type Size = (typeof circleSpinnerSizes)[number]

export const circleSpinnerVariants = ['primary', 'secondary'] as const
type Variant = (typeof circleSpinnerVariants)[number]

export type CircleSpinnerProps = {
  nwSize?: Size
  nwVariant?: Variant
  nwPaddingTop?: boolean
}

type SpinnerStyleProps = {
  color: string
  size: string
  thickness: string
}

const spin = keyframes({
  '100%': { transform: 'rotate(1turn)' },
})

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
})

const sizes: Record<Size, string> = {
  small: '50px',
  medium: '90px',
  large: '160px',
  button: '1em',
}

const thickness: Record<Size, string> = {
  small: '4px',
  medium: '8px',
  large: '12px',
  button: '3px',
}

const Spinner = styled.div<SpinnerStyleProps>(
  ({ color, size, thickness }) => css`
    border-radius: 50%;
    animation:
      ${spin} 1s linear infinite,
      ${fadeIn} 1.5s forwards;
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - ${thickness}), #000 0);
    background:
      radial-gradient(farthest-side, ${color} 94%, #0000) top/ ${thickness} ${thickness} no-repeat,
      conic-gradient(#0000 30%, ${color});
    width: ${size};
    height: ${size};
  `
)

const Wrapper = styled(Stack)<{ paddingTop: boolean }>(({ theme, paddingTop }) => ({
  paddingTop: paddingTop ? theme.space(10) : 0,
}))

export const CircleSpinner: FC<CircleSpinnerProps> = ({
  nwSize = 'small',
  nwVariant = 'primary',
  nwPaddingTop = false,
}) => {
  const theme = useTheme()
  const color = nwVariant === 'primary' ? theme.color.base.primary : theme.color.surface.white70
  return (
    <Wrapper paddingTop={nwPaddingTop} nwJustifyContent="center" nwAlignItems="center">
      <Spinner size={sizes[nwSize]} color={color} thickness={thickness[nwSize]} />
    </Wrapper>
  )
}
