import { brandConfig } from '@/config/brand'
import { PageMetaTags } from '@/features/page/page-meta-tags'
import { PagePanelsFetcher } from '@/features/page/page-panels-fetcher'
import { TOP_PROMO_CONTENT_COLOR_VAR_NAME, TOP_PROMO_CONTENT_HEIGHT } from '@/layouts/app'
import { NordicApp } from '@/layouts/app'
import type { Page } from '@/pages/types'

export const STARTPAGE_ID = 'start'

const Startpage: Page = () => {
  // We cant server side render if logged in, because the panels are personalized (and not tier dependant)
  return (
    <>
      <PagePanelsFetcher id={STARTPAGE_ID} showBreadcrumbs={false} />
      <PageMetaTags
        pageId={STARTPAGE_ID}
        nextSeoProps={{
          canonical: brandConfig.domain,
        }}
      />
    </>
  )
}

Startpage.getLayout = (Page, props) => {
  return (
    <NordicApp
      contentColorVar={TOP_PROMO_CONTENT_COLOR_VAR_NAME}
      shouldFadeContentColor
      fadeHeight={`calc(var(${TOP_PROMO_CONTENT_HEIGHT}) + max(40vw, 350px))`}
      shouldOverrideHeaderMarginBehaviour
    >
      <Page {...props} />
    </NordicApp>
  )
}

export default Startpage
