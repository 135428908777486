import { useCallback, useState } from 'react'
import { useInterval } from './use-interval'

export const useReRenderWithInterval = (interval: number | null = 30_000) => {
  const [, forceUpdate] = useState({})

  const reRender = useCallback(() => {
    forceUpdate({})
  }, [])

  useInterval(reRender, interval)
}
