import type { ComponentPropsWithoutRef } from 'react'
import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { useTheme } from 'styled-components'
import type { iconVariant } from '@nordic-web/ui-svg-icons/icon-variant'
import type { Theme } from '@nordic-web/ui-theme'

/**
 * Matches the last part of svg source file names
 */
export type IconVariant = (typeof iconVariant)[number]

export type IconColors = keyof Theme['color']['icon']

export type IconSize = keyof Theme['size']['icon']

export type IconProps = {
  nwSize?: IconSize
  nwVariant: IconVariant
  nwColor?: IconColors
  nwClickable?: boolean
  className?: string
  nwUnset?: boolean
} & ComponentPropsWithoutRef<'svg'>

/**
 * Icon component that dynamically loads the correct svg file based on the nwVariant prop
 */
export const Icon: React.FC<IconProps> = ({
  nwSize = 'small',
  nwVariant,
  nwColor,
  nwClickable,
  nwUnset,
  ...forwardProps
}) => {
  const theme = useTheme()
  const size = { width: theme.size.icon[nwSize], height: theme.size.icon[nwSize] }
  const styles = {
    ...(nwUnset ? {} : size),
    color: nwColor ? theme.color.icon[nwColor] : 'currentColor',
    cursor: nwClickable ? 'pointer' : undefined,
  }

  const getIcon = () =>
    dynamic(() => import(`@nordic-web/ui-svg-icons/svg/icon-${nwVariant}.svg?dimensions`), {
      loading: () => <svg style={styles} />,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DynamicIcon = useMemo(getIcon, [nwVariant])

  return <DynamicIcon style={styles} {...forwardProps} />
}
