import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { useIsAboveBreakpoint, useIsAboveTablet } from '@/hooks/use-breakpoint'
import { isMobileClient, isSafariDesktop } from '@/utils/detect-device'

export function useShouldDevicePlayVideo() {
  const isClientSide = useIsClientSide()
  const isAboveTablet = useIsAboveTablet()

  // Safari desktop removes gradients over trailers when the video becomes too large, for example on tv screens with high resolution
  const isTvScreen = useIsAboveBreakpoint(2000)
  const isUnsupportedTvScreen = isTvScreen && isSafariDesktop()

  // ios clients have some issues with zindex and video elements. We can look into fixing that later on
  return isClientSide && isAboveTablet && !isMobileClient() && !isUnsupportedTvScreen
}
