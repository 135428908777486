const removeEmptyValues = (object: Record<string, string | null | undefined>) => {
  return Object.entries(object).reduce(
    (result, [key, value]) => {
      if (value) {
        result[key] = value
      }

      return result
    },
    {} as Record<string, string>
  )
}

export const createQueryStringFromObject = (paramsObj: Record<string, string | null | undefined>) => {
  const withNoEmptyValues = removeEmptyValues(paramsObj)

  const searchParams = new URLSearchParams(withNoEmptyValues)
  const result = searchParams.toString()

  if (result) {
    return '?' + result
  }

  return ''
}

export const stringFromQueryParam = (param: string | string[] | undefined) =>
  typeof param === 'string' ? param : undefined
