import type { ImageProps } from 'next/image'
import { Image } from '../image/image'

type Props = Omit<ImageProps, 'height'> & { height?: number }

/**
 * @param props If height is set, the width will be set to auto and the width will just be used to decide what size of image to fetch
 */
export const ResponsiveImage = (props: Props) => {
  const { height, width } = props

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      height={0}
      style={{
        height: height ? height : 'auto',
        width: height ? 'auto' : width,
      }}
      {...props}
    />
  )
}
