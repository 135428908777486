import styled from 'styled-components'

type CenterProps = {
  flexDirection?: 'column' | 'row'
}

export const Center = styled.div<CenterProps>(({ flexDirection = 'column' }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection,
  justifyContent: 'center',
  alignItems: 'center',
}))

export const CenterPage = styled(Center)({
  height: '80vh',
})
