import styled from 'styled-components'
import type { SwitchProps } from './switch'

type StyleProps = Omit<SwitchProps, 'onChange'>

export const SwitchInternal = styled.label<StyleProps>(({ disabled }) => ({
  position: 'relative',
  display: 'inline-block',
  width: 40,
  height: 21,
  borderRadius: 65,
  '&:focus': {
    outline: 0,
  },
  ...(disabled
    ? {
        opacity: 0.85,
      }
    : {}),
}))

export const Slider = styled.span<StyleProps>(({ checked, disabled, theme }) => ({
  borderRadius: '100px',
  position: 'absolute',
  cursor: disabled ? 'not-allowed' : 'pointer',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: checked ? theme.color.status.success : theme.color.surface.white20,
  transition: 'background-color, transform 0.4s',
}))

export const Dot = styled.div<StyleProps>(({ checked }) => ({
  position: 'absolute',
  content: '',
  height: 21,
  width: 21,
  transition: '0.4s',
  zIndex: 5,
  padding: 2,
  transform: checked ? 'translateX(19px)' : 'translateX(0)',
}))

export const Input = styled.input<StyleProps>(({ disabled }) => ({
  position: 'absolute',
  width: 40,
  height: 21,
  top: -3,
  left: -4,
  appearance: 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
}))
