import {
  formatDate as formatDateUtil,
  formatStartEndTime as formatStartEndTimeUtil,
  formatTime as formatTimeUtil,
} from '@nordic-web/utils/date'
import { brandConfig } from '@/config/brand'

export const formatTime = (date: string, withSeconds = false) => {
  return formatTimeUtil(brandConfig.language, date, withSeconds)
}

export const formatDate = (
  date: string | Date,
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
) => {
  return formatDateUtil(brandConfig.language, date, options)
}

export const formatStartEndTime = (startTime: string, endTime?: string) => {
  return formatStartEndTimeUtil(brandConfig.language, startTime, endTime)
}
