import React, { useCallback, useState } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { Button, Stack, TypographyLink, TypographyText } from '@nordic-web/ui-components'
import { Icon } from '@nordic-web/ui-components'
import { RenderClientSide } from '@nordic-web/utils/components/render-client-side'
import { PageMargin } from '@/components/scaffolding/page-margin'
import { BRAND, Brand } from '@/config/brand'
import { useAuthenticationStore } from '@/features/auth/authentication-store'
import { useAuthModal } from '@/features/auth/context/auth-modal-context'
import { AuthModalPaths } from '@/features/auth/types'
import { type Url, paths } from '@/helpers/paths'
import { columnLinksMtv } from './footer-links-mtv'
import { columnLinksTv4 } from './footer-links-tv4'
import { styles as Footer } from './style'

const CURRENT_YEAR = new Date().getFullYear()

export type FooterColumnConfig = {
  header: string
  links: {
    title: string
    url?: Url
    isSignup?: boolean
    isCookieSettings?: boolean
  }[]
}

const footerLinks: Record<Brand, FooterColumnConfig[]> = {
  [Brand.MTV]: columnLinksMtv,
  [Brand.TV4]: columnLinksTv4,
}

const SignupLink = ({ url, title }: { url: Url; title: string }) => {
  const { dispatch } = useAuthModal()
  const { isLoggedIn } = useAuthenticationStore()

  if (isLoggedIn) return null

  return (
    <Footer.ColumnItem>
      <Link legacyBehavior passHref href={url}>
        <TypographyText
          as="a"
          nwVariant="body4"
          nwColor="secondary"
          onClick={(e) => {
            e.preventDefault()
            dispatch({ type: 'show-auth-modal', path: AuthModalPaths.SIGNUP })
          }}
        >
          {title}
        </TypographyText>
      </Link>
    </Footer.ColumnItem>
  )
}

const Column = ({ column, id }: { column: FooterColumnConfig; id: number }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }
  const columnPanelId = `column-panel-${id}`
  const handleCookieSettingsClick = useCallback(() => {
    const globalCookieSettingsElement = document.getElementById('ot-sdk-btn')
    globalCookieSettingsElement?.click()
  }, [])
  return (
    <Footer.Column>
      <Footer.ColumnHeading>
        <Footer.ColumnHeadingButton
          onClick={handleOpen}
          aria-pressed={isOpen}
          aria-expanded={isOpen}
          aria-controls={columnPanelId}
        >
          <Footer.ColumnHeadingText nwVariant="body3Strong">{column.header}</Footer.ColumnHeadingText>
          <Footer.RotateIcon flip={!!isOpen}>
            <Icon nwVariant="chevron-down" aria-hidden />
          </Footer.RotateIcon>
        </Footer.ColumnHeadingButton>
        <Footer.ColumnHeadingText nwVariant="body3Strong" desktop as="span">
          {column.header}
        </Footer.ColumnHeadingText>
      </Footer.ColumnHeading>
      <Footer.ListContainer open={isOpen} id={columnPanelId}>
        {column.links.map((link, idx: number) => {
          if (link.isCookieSettings) {
            return (
              <Footer.ColumnItem key={idx}>
                <TypographyText nwVariant="body4" nwColor="secondary">
                  <Footer.CookieSettings onClick={handleCookieSettingsClick}>{link.title}</Footer.CookieSettings>
                </TypographyText>
              </Footer.ColumnItem>
            )
          }

          if (!link.url) return null

          if (link.isSignup) {
            return (
              <RenderClientSide key={idx}>
                <SignupLink url={link.url} title={link.title} />
              </RenderClientSide>
            )
          }

          return (
            <Footer.ColumnItem key={idx}>
              <Link href={link.url} passHref legacyBehavior>
                <TypographyText as="a" nwVariant="body4" nwColor="secondary">
                  {link.title}
                </TypographyText>
              </Link>
            </Footer.ColumnItem>
          )
        })}
      </Footer.ListContainer>
    </Footer.Column>
  )
}

export const FooterComponent = ({ useContentColor }: { useContentColor: boolean }) => {
  const handleClick = () => {
    window.scrollTo(0, 0)
  }

  const t = useTranslations()

  return (
    <Footer.Container useContentColor={useContentColor}>
      <Footer.InnerContainer>
        <PageMargin>
          <Footer.ColumnContainer>
            {footerLinks[BRAND].map((column, idx) => (
              <Column column={column} key={idx} id={idx} />
            ))}
          </Footer.ColumnContainer>
        </PageMargin>

        <Stack nwAlignItems="center" nwGap={3}>
          {BRAND === 'tv4' && (
            <TypographyLink
              href={paths.faq.urlString({
                section:
                  'humany-tv4play=/1179/ansvariga-utgivare-foer-innehall-pa-tv4-play&humany-tv4-play=/g850-ansvariga-utgivare-foer-innehall-pa-tv4-play-och-tv4-se;phrase:ansvariga utgivare;take:10',
              })}
            >
              Ansvariga utgivare
            </TypographyLink>
          )}
          <TypographyText nwVariant="body4" nwColor="secondary">
            {t('footer__organisation_info', { year: CURRENT_YEAR })}
          </TypographyText>
          <Button onClick={handleClick} nwIconLast="chevron-up" nwVariant="ghost">
            {t('footer__back_to_top')}
          </Button>
        </Stack>
      </Footer.InnerContainer>
    </Footer.Container>
  )
}
