/* eslint-disable react-hooks/rules-of-hooks */
// https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
import { useLayoutEffect, useMemo, useState } from 'react'
import { isServerSide } from '@nordic-web/utils/misc/detect-side'

export const useMatchMedia = (query: string, serverSideValue: boolean = false): boolean => {
  if (isServerSide) return serverSideValue

  const mediaQueryList = useMemo(() => matchMedia(query), [query])

  const [isMatch, setMatches] = useState(() => mediaQueryList.matches)

  useLayoutEffect(() => {
    const handle = () => setMatches(mediaQueryList.matches)
    mediaQueryList.addEventListener('change', handle)
    return () => mediaQueryList.removeEventListener('change', handle)
  }, [mediaQueryList])

  return isMatch
}
