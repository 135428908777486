import type { KeyboardEvent, PropsWithChildren } from 'react'
import { Icon } from '../../icon/icon/icon'
import { Image } from '../../image/image/image'
import { nwSizeStyles } from '../constants'
import { AvatarText } from '../shared-styles'
import { AvatarAcronym, AvatarContainer, AvatarImage } from './styles'

export const avatarSize = ['mini', 'small', 'large'] as const

type avatarSizeType = (typeof avatarSize)[number]

export type AvatarProps = {
  nwImageUrl: string
  nwName: string
  nwSize?: avatarSizeType
  nwAxis?: 'x' | 'y'
  nwSelected?: boolean
  nwEdit?: boolean
  onClick?: () => void
  role?: string
  tabIndex?: number
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void
  nwSelectable?: boolean
  nwDisabled?: boolean
}
export const Avatar = ({
  nwName,
  nwImageUrl,
  nwSize = 'large',
  nwAxis = 'y',
  nwSelected = false,
  nwEdit = false,
  onClick = () => {},
  role,
  tabIndex,
  onKeyDown = () => {},
  nwSelectable = true,
  nwDisabled = false,
  children,
}: PropsWithChildren<AvatarProps>) => {
  const acronym = nwName.slice(0, 2).toUpperCase()
  return (
    <AvatarContainer nwDisabled={nwDisabled} nwAxis={nwAxis} role={role} tabIndex={tabIndex} onKeyDown={onKeyDown}>
      <AvatarImage nwSize={nwSize} nwSelected={nwSelected} onClick={onClick} nwSelectable={nwSelectable}>
        <Image src={nwImageUrl} alt={nwName} {...nwSizeStyles[nwSize]} />
        <AvatarText>
          {nwEdit ? (
            <Icon nwVariant="edit" nwSize="medium" />
          ) : (
            <AvatarAcronym nwSize={nwSize} aria-hidden>
              {acronym}
            </AvatarAcronym>
          )}
        </AvatarText>
      </AvatarImage>
      {children}
    </AvatarContainer>
  )
}
