import React, { useId } from 'react'
import type { UseFormRegisterReturn } from 'react-hook-form'
import { Input, Label, Wrapper } from './form-input-radio-button.styled'

export type FormInputRadioButtonProps = {
  nwLabel?: string
  nwHasError?: boolean
  /**
   * The register function from react-hook-form: `validators={register('input-name')}`
   */
  validators?: UseFormRegisterReturn
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'name' | 'type'>

export const FormInputRadioButton = ({
  nwLabel,
  nwHasError,
  validators,
  ...forwardProps
}: FormInputRadioButtonProps) => {
  const randomId = useId()
  const id = forwardProps.id ?? `form-input-radiobutton-${randomId}`

  return (
    <Wrapper>
      <Input {...validators} {...forwardProps} id={id} type="radio" aria-invalid={nwHasError ? 'true' : 'false'} />
      {nwLabel && (
        <Label as="label" htmlFor={id} nwVariant="body2">
          {nwLabel}
        </Label>
      )}
    </Wrapper>
  )
}
