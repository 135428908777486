import type { FunctionComponent } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { PAUSE_PROMO, PAUSE_TRAILER_EVENT } from '@/components/trailer/events'
import { useMuteTrailer } from '@/components/trailer/use-mute-trailer'
import { useTrailersMutedState } from '@/components/trailer/use-trailers-muted-state'
import { publishEvent } from '@/hooks/use-global-events'

type CardVideoTrailerProps = {
  video16x9mp4?: string | null
  video16x9webm?: string | null
  playDelay?: number
  muted?: boolean
}

const Video = styled.video`
  object-fit: cover;
  height: 100%;
`

const VideoWrapper = styled.div({
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  opacity: 0,
  transition: 'opacity 500ms ease-in',
})

export const CardVideoTrailer: FunctionComponent<CardVideoTrailerProps> = ({
  video16x9mp4,
  video16x9webm,
  playDelay = 0,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const delayRef = useRef<ReturnType<typeof setTimeout>>()
  const videoElement = useRef<HTMLVideoElement>(null)
  const { isMuted } = useTrailersMutedState()
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)

  const mountTime = useMemo(() => {
    return Date.now()
  }, [])

  const play = async () => {
    try {
      // The .play() call might not work for different reasons. Browsers are picky about autoplaying videos, so they
      // might block it if the user haven't interacted with the document before.
      if (videoElement.current && containerRef.current) {
        await videoElement.current.play()
        containerRef.current.style.opacity = '1'
        publishEvent(PAUSE_TRAILER_EVENT, PAUSE_PROMO)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  useMuteTrailer(videoElement.current, isMuted, isVideoLoaded)

  useEffect(() => {
    return () => {
      clearTimeout(delayRef.current)
    }
  }, [])

  return (
    <VideoWrapper draggable={false} ref={containerRef}>
      <Video
        onLoadedData={() => {
          setIsVideoLoaded(true)

          // We need to respect the playDelay props, which delays the autoplay of the video from that its been mounted.
          const timeElapsedSinceMount = Date.now() - mountTime
          delayRef.current = setTimeout(play, Math.max(playDelay - timeElapsedSinceMount, 0))
        }}
        ref={videoElement}
      >
        <source src={video16x9mp4 ?? undefined} type="video/mp4" />
        <source src={video16x9webm ?? undefined} type="video/webm" />
      </Video>
    </VideoWrapper>
  )
}
