import styled from 'styled-components'
import type { ProfileFieldsFragment } from '@nordic-web/gql'
import { Avatar, Stack } from '@nordic-web/ui-components'
import { TypographyText } from '@nordic-web/ui-components'
import { useHasChildProfilesEnabled } from '@nordic-web/utils/hooks/use-has-child-profile-enabled'
import { handleEnterKeyDown } from '@nordic-web/utils/misc/keyboard'
import { authenticationStore } from '@/features/auth/authentication-store'
import { useProfileUpgradeMessage } from '@/features/profiles/hooks/use-profile-upgrade-message'
import { MIN_PROFILES } from '@/layouts/header/constants'
import { useSubMenuItems } from '@/layouts/header/hooks'

const Wrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  right: 0,
  paddingTop: theme.space(2),
}))

const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.color.surface.black80,
  borderRadius: theme.radii.border_radius,
  minWidth: 180,
  paddingBlock: theme.space(4),
  backdropFilter: 'blur(5px)',
}))

const ListItem = styled.div(({ theme }) => ({
  paddingBlock: theme.space(2),
  paddingInline: theme.space(4),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.color.surface.white10,
  },
}))

type HeaderDropdownMenuProps = {
  profiles: ProfileFieldsFragment[]
  activeProfileId?: string
  onClose: () => void
}

export const HeaderDropdownMenu = ({ profiles, activeProfileId, onClose }: HeaderDropdownMenuProps) => {
  const { showProfileUpgradeMessage } = useProfileUpgradeMessage()
  const { hasChildProfilesEnabled } = useHasChildProfilesEnabled()
  const subMenuItems = useSubMenuItems()
  const shouldShowProfiles = profiles.length > MIN_PROFILES

  return (
    <Wrapper>
      <Container onMouseLeave={onClose} role="menubar">
        <Stack nwGap={4}>
          {shouldShowProfiles && (
            <div>
              {profiles.map(({ id, name, avatarUrl, isChild }) => {
                const isDisabled = isChild && !hasChildProfilesEnabled
                const onClick = () => {
                  if (isDisabled) {
                    showProfileUpgradeMessage()
                    return
                  }

                  authenticationStore.changeProfile(id, isChild)
                  onClose()
                }

                return (
                  <ListItem key={id} onClick={onClick} tabIndex={0} onKeyDown={handleEnterKeyDown(onClick)}>
                    <Avatar
                      key={id}
                      nwName={name}
                      nwImageUrl={avatarUrl}
                      nwSelected={activeProfileId === id}
                      nwSize="mini"
                      nwAxis="x"
                      nwDisabled={isDisabled}
                    >
                      <TypographyText nwVariant="body3">{name}</TypographyText>
                    </Avatar>
                  </ListItem>
                )
              })}
            </div>
          )}
          <div>
            {subMenuItems.map(({ id, text, onClick }) => {
              const handleClick = () => {
                onClick()
                onClose()
              }
              return (
                <ListItem key={id} onClick={handleClick} tabIndex={0} onKeyDown={handleEnterKeyDown(onClick)}>
                  <TypographyText nwVariant="body3">{text}</TypographyText>
                </ListItem>
              )
            })}
          </div>
        </Stack>
      </Container>
    </Wrapper>
  )
}
