import type { MouseEventHandler } from 'react'
import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Icon, Stack } from '@nordic-web/ui-components'
import { PanelTitle } from '@/components/typography/panel-title'

type PanelTitleLinkProps = {
  title: string
  href: string
  onClick: MouseEventHandler<HTMLAnchorElement>
}

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.color.text.primary,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const PanelTitleLink = ({ href, onClick, title }: PanelTitleLinkProps) => {
  return (
    <StyledLink href={href} onClick={onClick}>
      <PanelTitle>
        <Stack nwDirection="row" nwGap={1} nwAlignItems="center">
          {title} <Icon nwVariant="arrow-right" />
        </Stack>
      </PanelTitle>
    </StyledLink>
  )
}
