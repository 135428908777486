export enum ChildLockFormViews {
  ENABLE_PIN = 'enable_pin',
  DISABLE_WITH_PIN = 'disable_with_pin',
  DISABLE_WITH_PASSWORD = 'disable_with_password',
  CHANGE_PIN = 'change_pin',
  CHANGE_WITH_PASSWORD = 'change_with_password',
}

export enum AuthModalPaths {
  LOGIN = 'login',
  LOGOUT_OTHERS = 'logout-others',
  FORGOT = 'forgot',
  SIGNUP = 'signup',
  CHILDLOCK = 'childlock',
}

export type ForgotPasswordReturnPaths = AuthModalPaths.LOGIN | AuthModalPaths.CHILDLOCK

export type TokenPair = { accessToken: string; refreshToken: string }
