import type { ChangeEventHandler } from 'react'
import { Icon } from '../icon/icon/icon'
import { Dot, Input, Slider, SwitchInternal } from './styles'

export type SwitchProps = {
  checked: boolean
  disabled?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const Switch = ({ checked, disabled = false, onChange }: SwitchProps) => {
  return (
    <SwitchInternal checked={checked} disabled={disabled}>
      <Slider checked={checked} disabled={disabled}>
        <Input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        <Dot checked={checked}>{<Icon nwVariant={checked ? 'check-filled' : 'close-x-filled'} nwUnset />}</Dot>
      </Slider>
    </SwitchInternal>
  )
}
