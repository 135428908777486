import styled from 'styled-components'
import { Image } from '@nordic-web/ui-components'

const imageSize = `
(min-resolution: 3dppx) 40vw,
(min-resolution: 2dppx) 60vw,
(min-resolution: 1dppx) 120vw
`

const ImageWrapper = styled.div({
  // Below is needed to squelch next/image warning
  position: 'absolute',
  width: '100%',
  height: '100%',
})

type BackgroundTrailerImageProps = {
  src: string
  imagePriority?: boolean
}

const StyledImage = styled(Image)`
  position: static !important;
  height: auto !important;
`

export const BackgroundImage = ({ src, imagePriority = false }: BackgroundTrailerImageProps) => {
  return (
    <ImageWrapper>
      <StyledImage alt="" fill sizes={imageSize} src={src} priority={imagePriority} />
    </ImageWrapper>
  )
}
