import type { EpgFieldsFragment } from '@nordic-web/gql'
import { isFuture, isPast } from '../date'

export const isPlaying = (epgItem: Pick<EpgFieldsFragment, 'start' | 'end'>) => {
  return isPast(epgItem.start) && isFuture(epgItem.end)
}

export const getEpgProgressPercentage = (epgItem: EpgFieldsFragment) => {
  const elapsed = new Date().getTime() - new Date(epgItem.start).getTime()
  const duration = new Date(epgItem.end).getTime() - new Date(epgItem.start).getTime()
  return (elapsed / duration) * 100
}

export const mapEpgMedia = (item: EpgFieldsFragment) => {
  const media = item.media
  if (media?.__typename === 'EpgItemEpisodeReference') return media.episode
  if (media?.__typename === 'EpgItemMovieReference') return media.movie
  if (media?.__typename === 'EpgItemSportEventReference') return media.sportEvent
}
