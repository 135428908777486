import React, { useId, useState } from 'react'
import styled from 'styled-components'
import { theme } from '@nordic-web/ui-theme'
import { Icon } from '../icon/icon/icon'

type AccordionProps = {
  /**
   * This element will be placed inside a <button> element.
   */
  nwHeadingLabel: React.JSX.Element
  /**
   * Sets the accordion heading's semantic level.
   *
   * The default heading level is `2`, which corresponds to a `<h2>`.
   */
  nwHeadingLevel?: React.AriaAttributes['aria-level']
  nwColor?: string
  nwActiveBackgroundColor?: string
  nwDefaultIsOpen?: boolean
}

export const ExpandableAccordion = ({
  nwHeadingLabel: heading,
  nwHeadingLevel: headingLevel = 2,
  nwColor: color,
  nwActiveBackgroundColor: activeBackgroundColor = 'transparent',
  children,
  nwDefaultIsOpen: defaultIsOpen,
}: React.PropsWithChildren<AccordionProps>) => {
  const headerButtonId = useId()
  const panelId = useId()

  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false)

  return (
    <div>
      <AccordionHeader aria-level={headingLevel}>
        <AccordionHeaderButton
          id={headerButtonId}
          aria-expanded={isOpen}
          aria-controls={panelId}
          $bgColor={activeBackgroundColor}
          $color={color}
          $isOpen={isOpen}
          onClick={() => {
            setIsOpen((open) => !open)
          }}
        >
          <div>{heading}</div>
          <IconRotator role="presentation" rotateIcon={isOpen}>
            <Icon nwVariant="chevron-down" />
          </IconRotator>
        </AccordionHeaderButton>
      </AccordionHeader>
      <AccordionContentWrapper
        id={panelId}
        role="region"
        aria-labelledby={headerButtonId}
        $isOpen={isOpen}
        $bgColor={activeBackgroundColor}
      >
        {children}
      </AccordionContentWrapper>
    </div>
  )
}

const AccordionHeader = styled.header({
  width: '100%',
})

const AccordionHeaderButton = styled.button<{ $isOpen: boolean; $bgColor?: string; $color?: string }>(
  ({ $isOpen, $bgColor, $color }) => ({
    fontFamily: 'inherit',
    backgroundColor: $isOpen ? $bgColor : 'transparent',
    padding: theme.space(6),
    color: $color ? $color : theme.color.text.primaryInverted,
    cursor: 'pointer',
    width: '100%',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    '&:hover, &:focus': {
      backgroundColor: $bgColor,
    },
  })
)

const IconRotator = styled.span<{ rotateIcon: boolean }>(({ rotateIcon, theme }) => ({
  display: 'block',
  position: 'relative',
  border: 'none',
  background: 'transparent',
  color: theme.color.text.primary,
  transition: 'transform ease-in-out 200ms, top ease-in-out 200ms',
  ...(rotateIcon
    ? {
        transform: 'rotate(180deg)',
        top: -5,
      }
    : {
        transform: 'rotate(0deg)',
        top: 0,
      }),
}))

const AccordionContentWrapper = styled.div<{ $isOpen: boolean; $bgColor?: string }>(({ $isOpen, $bgColor }) => ({
  display: $isOpen ? 'block' : 'none',
  backgroundColor: $bgColor ? $bgColor : 'transparent',
  padding: theme.space(6),
  paddingTop: 0,
}))
