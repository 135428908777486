import { handleEnterKeyDown } from '@nordic-web/utils/misc/keyboard'
import { HeadToggleText, ToggleButton, ToggleContainer } from './styles'

type ToggleProps = {
  value: boolean
  onToggle: (value: boolean) => void
  disabled?: boolean
  texts: {
    headToggleOff: string
    headToggleOn: string
  }
  tabIndex?: number
  className?: string
}

export const Toggle = ({ value, onToggle, disabled = false, texts, tabIndex = 0, className }: ToggleProps) => {
  const toggleFirstItem = () => onToggle(false)
  const toggleSecondItem = () => onToggle(true)
  return (
    <ToggleContainer disabled={disabled} nwDirection="row" className={className}>
      <ToggleButton
        isActive={!value}
        onClick={toggleFirstItem}
        tabIndex={tabIndex}
        onKeyDown={handleEnterKeyDown(toggleFirstItem)}
      >
        <HeadToggleText>{texts.headToggleOff}</HeadToggleText>
      </ToggleButton>
      <ToggleButton
        isActive={value}
        onClick={toggleSecondItem}
        tabIndex={tabIndex}
        onKeyDown={handleEnterKeyDown(toggleSecondItem)}
      >
        <HeadToggleText>{texts.headToggleOn}</HeadToggleText>
      </ToggleButton>
    </ToggleContainer>
  )
}
