import { createGlobalStyle } from 'styled-components'
import { heroImageRatioBreakpoint } from '@/components/hero/hero'
import { TOP_PROMO_CONTENT_COLOR_VAR_NAME } from '@/layouts/app'

type GlobalStyle = {
  mobileColor?: string
  desktopColor?: string
}

export const GlobalContentColorVarStyle = createGlobalStyle<GlobalStyle>(({ theme, desktopColor, mobileColor }) => ({
  ':root': {
    [TOP_PROMO_CONTENT_COLOR_VAR_NAME]: mobileColor || theme.color.base.background,
    [heroImageRatioBreakpoint]: {
      [TOP_PROMO_CONTENT_COLOR_VAR_NAME]: desktopColor || theme.color.base.background,
    },
  },
}))
