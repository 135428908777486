import type { PropsWithChildren } from 'react'
import React from 'react'
import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { usePageLazyQuery } from '@nordic-web/gql'
import { getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'
import { PAGE_PANEL_FETCH_LIMIT } from '@/features/page/page-panels-fetcher'

type Props = {
  pageId?: string
  role?: string
} & LinkProps

export const usePrefetchPageQuery = (pageId?: string) => {
  const [fetch] = usePageLazyQuery()

  return () => {
    if (!pageId) return

    fetch({
      variables: {
        pageId: pageId,
        input: {
          limit: PAGE_PANEL_FETCH_LIMIT,
          offset: 0,
        },
        limit: getSwipeModuleApiLimit(),
        offset: 0,
      },
    })
  }
}

export const PagePrefetchLink = ({ pageId, ...linkProps }: PropsWithChildren<Props>) => {
  const preload = usePrefetchPageQuery(pageId)

  return <Link onMouseEnter={preload} {...linkProps} />
}
