import styled from 'styled-components'
import { belowTablet } from '@nordic-web/ui-styles'
import { combineResponsiveStyles } from '@nordic-web/ui-styles'

export type LayoutColumnsProps = {
  nwColumnGap?: string | number
  nwRowGap?: string | number
  nwMinWidth?: string | number
  nwMaxWidth?: string | number
  nwSmallScreenColumns?: number
}

/**
 * Create even columns. If a min-width is set, the columns will wrap to the next row.
 */
export const LayoutColumns = styled.div<LayoutColumnsProps>(
  ({ nwColumnGap, nwRowGap, nwMaxWidth = '1fr', nwMinWidth, theme, nwSmallScreenColumns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(min(100%, ${nwMinWidth || 0}), ${nwMaxWidth}))`,
    alignItems: 'start',
    gridAutoRows: 'min-content',
    ...(nwSmallScreenColumns && {
      [belowTablet]: {
        gridTemplateColumns: `repeat(${nwSmallScreenColumns}, 1fr)`,
      },
    }),
    ...combineResponsiveStyles([
      nwColumnGap ? { gridColumnGap: nwColumnGap } : theme.grid.gridColumnGap,
      nwRowGap ? { gridRowGap: nwRowGap } : theme.grid.gridRowGap,
    ]),
  })
)
