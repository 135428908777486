import { createGlobalStyle } from 'styled-components'
import type { Theme } from '@nordic-web/ui-theme'
import { base } from './base'
import { form } from './form'
import { reset } from './reset'
import { typography } from './typography'

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  ${reset}
  ${(p) => base(p.theme)}
  ${(p) => form(p.theme)}
  ${(p) => typography(p.theme)}
`
