/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import type { ImageLoaderProps, ImageProps } from 'next/image'
import NextImage from 'next/image'

const IMAGE_PROXY_URL = 'https://imageproxy.a2d.tv'

const loader = ({ src, width }: ImageLoaderProps) => {
  if (!src.startsWith('http')) return src

  return `${IMAGE_PROXY_URL}?source=${src}&width=${width}`
}

export const Image = (props: ImageProps) => {
  return <NextImage loader={loader} {...props} />
}
