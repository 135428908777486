import styled from 'styled-components'

/**
 * Create rows for things inside of forms to space them out evenly.
 * Both Form and FormFieldset use this, and it's super-handy if you need to nest things deeper inside a Form
 * and still need the same spacing.
 */
export const FormLayoutRows = styled.div(({ theme }) => ({
  display: 'grid',
  gridRowGap: theme.space(4),
  width: '100%',
}))
