import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { usePageMetaQuery } from '@nordic-web/gql'
import { getOgImages } from '@/components/seo/seo'
import { ErrorHandler } from '@/layouts/error-handler'
import { formatDate } from '@/utils/date'

export const TableauMetaTags = ({ pageId: inputPageId }: { pageId: string }) => {
  const router = useRouter()
  const t = useTranslations()

  const { data, loading: isLoading } = usePageMetaQuery({
    variables: {
      pageId: inputPageId,
    },
  })

  if (isLoading) return null

  // This is where we trigger the 404 page, since we dont server side render the actual Page
  if (!data?.page?.id) {
    return <ErrorHandler statusCode={404} />
  }

  const page = data?.page
  const metaDescription = page?.seoContent?.metaDescription ?? t('seo__default_description')
  const images = page?.images

  const displayDate = formatDate((router.query.d as string) ?? new Date(), {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
  })

  const title = t('channels_tableau_seo_title', { date: displayDate })

  return (
    <NextSeo
      title={title}
      description={metaDescription}
      openGraph={{
        title,
        description: metaDescription,
        ...(!images?.image16x9?.isFallback ? { images: getOgImages(images?.image16x9?.sourceEncoded) } : {}),
      }}
    />
  )
}
