import React from 'react'
import { usePanelQuery } from '@nordic-web/gql'
import type { SwipeModuleProps } from '@/components/swipe-module/swipe-module'
import { SwipeModule, getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'
import { parsePanelResponse } from './parse-panel-response'

type PaginatedSwipeModuleProps = Omit<SwipeModuleProps, 'items' | 'hasMoreItems'> & {
  panelId: string
}

export const PanelQuerySwipeModule = ({ panelId, ...rest }: PaginatedSwipeModuleProps) => {
  const { data, fetchMore } = usePanelQuery({
    ssr: false,
    variables: {
      panelId,
      offset: 0,
      limit: getSwipeModuleApiLimit(),
    },
  })

  const panel = data?.panel
  const response = parsePanelResponse(panel)
  const pageInfo = response?.pageInfo
  const items = response?.content ?? []
  const hasNextPage = !!pageInfo?.hasNextPage

  return (
    <SwipeModule
      showOnlyUpsellLabel={response.showOnlyUpsellLabel}
      hasMoreItems={hasNextPage}
      fetchMoreData={() => {
        fetchMore({
          variables: {
            limit: getSwipeModuleApiLimit(),
            offset: pageInfo?.nextPageOffset,
          },
        })
      }}
      items={items}
      {...rest}
    />
  )
}
