import React, { useMemo } from 'react'
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import { useRouter } from 'next/router'
import { getClient } from '@/lib/bugsnag/bugsnag'
import { shouldIgnoreRoute } from './tracking-guard'

export type ErrorBoundaryProps = {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}

export const ErrorBoundary: BugsnagErrorBoundary = (props) => {
  const router = useRouter()
  const shouldIgnore = shouldIgnoreRoute(router.asPath)
  const ErrorBoundary = useMemo(() => {
    // TODO: remove asap, seem to be something wrong with the react types..
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return shouldIgnore ? null : getClient()?.getPlugin('react')?.createErrorBoundary(React)
  }, [shouldIgnore])

  return ErrorBoundary ? <ErrorBoundary {...props} /> : <>{props.children}</>
}
