import React from 'react'
import { useTranslations } from 'next-intl'
import styled from 'styled-components'
import { ButtonCollapse } from '@nordic-web/ui-components'
import { useTrailersMutedState } from '@/components/trailer/use-trailers-muted-state'

export const MuteTrailersButton = () => {
  const { isMuted, setIsMuted } = useTrailersMutedState()

  const t = useTranslations()
  return (
    <StyledButtonCollapse
      data-test="mute-button"
      nwIcon={isMuted ? 'volume-mute' : 'volume'}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsMuted(!isMuted)
      }}
    >
      {isMuted ? t('trailer__unmute_button') : t('trailer__mute_button')}
    </StyledButtonCollapse>
  )
}

const StyledButtonCollapse = styled(ButtonCollapse)({
  animation: 'fade-in 250ms ease 500ms',
  animationFillMode: 'both',
})
