import type { FC } from 'react'
import React from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { TypographyText } from '@nordic-web/ui-components'
import { pathsPlayingVideos } from '@/helpers/paths'

type IsPlaying = {
  assetId: string
}

export const IsPlaying: FC<IsPlaying> = ({ assetId }) => {
  const t = useTranslations()
  const router = useRouter()

  const isPlaying =
    router &&
    pathsPlayingVideos.some((path) => {
      // We know that there is a router.query.assetId, since the pathsPlayingVideos interface has the assetId property.
      // And params is used on the catch all route on the clips page.
      const routerAssetId = router.query.assetId || router.query.params?.[0]
      return path.isActive(router) && routerAssetId === assetId
    })

  if (!isPlaying) return null

  return (
    <IsPlayingWrapper>
      <TypographyText nwColor="primary" nwVariant="body3">
        {t('labels__playing_now')}
      </TypographyText>
    </IsPlayingWrapper>
  )
}

export const IsPlayingWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.color.surface.black60,
  height: '100%',
  width: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 2,
}))
