import { useTranslations } from 'next-intl'
import type { ParentalRatingFieldsFragment } from '@nordic-web/gql'
import { MtvParentalRating } from '@/components/mtv-parental-rating'
import { Tv4AgeRating } from '@/components/tv4-age-rating'
import { MetaPropertyList } from '.'

type StandardMetaPropertyListProps = {
  parentalRating?: ParentalRatingFieldsFragment | null
  genres?: string[]
  productionYear?: string | null
  productionCountries?: { name: string }[]
  league?: string | null
  round?: string | null
  arena?: string | null
  broadcastTime?: string | null
  compact?: boolean
  oneLine?: boolean
  numberOfAvailableSeasons?: number
  series?: {
    genres?: string[]
  } | null
}

export const StandardMetaPropertyList = ({
  parentalRating,
  genres,
  productionYear,
  productionCountries,
  arena,
  league,
  round,
  broadcastTime,
  numberOfAvailableSeasons,
  series,
  compact = false,
  oneLine = false,
}: StandardMetaPropertyListProps) => {
  const t = useTranslations()
  const mediaGenres = series?.genres ?? genres
  return (
    <MetaPropertyList compact={compact} oneLine={oneLine}>
      {typeof parentalRating?.sweden?.ageRecommendation === 'number' && (
        <Tv4AgeRating ageRating={parentalRating.sweden.ageRecommendation} />
      )}
      {parentalRating?.finland && <MtvParentalRating {...parentalRating.finland} height={compact ? 20 : 24} />}
      {productionYear && <div>{productionYear}</div>}
      {!!mediaGenres?.length && <div>{mediaGenres.join(', ')}</div>}
      {productionCountries && <div>{productionCountries.map((country) => country.name).join(', ')}</div>}
      {league && <div>{league}</div>}
      {round && <div>{round}</div>}
      {arena && <div>{arena}</div>}
      {broadcastTime && <div>{broadcastTime}</div>}
      {numberOfAvailableSeasons && numberOfAvailableSeasons > 0 ? (
        <div>{t('general__number_of_seasons', { number: numberOfAvailableSeasons })}</div>
      ) : null}
    </MetaPropertyList>
  )
}
