import type { FC } from 'react'
import { useTranslations } from 'next-intl'
import { capitalizeFirstLetter } from '@nordic-web/utils/misc/string'
import { ButtonLinkBar } from '@/components/button-link-bar'
import { formatDate } from '@/utils/date'

const range = (start: number, end: number): number[] => Array.from({ length: end - start + 1 }, (_, i) => i + start)

// Should always be YYYY-MM-DD
export const formatDateQueryParam = (date: Date) => {
  return date.toLocaleDateString('sv')
}

type SportEpgDaymenuProps = {
  selectedDay: string
  pastDaysToShow: number
  futureDaysToShow: number
  getHref: (day: string) => string
}

export const DayMenuBar: FC<SportEpgDaymenuProps> = ({ selectedDay, pastDaysToShow, futureDaysToShow, getHref }) => {
  const days = range(pastDaysToShow, futureDaysToShow)
  const t = useTranslations()

  return (
    <ButtonLinkBar
      items={days.map((day) => {
        const dateObject = new Date()
        dateObject.setDate(dateObject.getDate() + day)

        const getLabel = () => {
          if (day === 0) {
            return t('time__today')
          } else if (day === 1) {
            return t('time__tomorrow')
          } else if (day === -1) {
            return t('time__yesterday')
          } else {
            return formatDate(dateObject, { day: 'numeric', month: 'long', weekday: 'short' })
          }
        }

        const formattedDate = formatDateQueryParam(dateObject)

        return {
          label: capitalizeFirstLetter(getLabel()) ?? '',
          href: getHref(formattedDate),
          selected: selectedDay == formattedDate,
        }
      })}
    />
  )
}
