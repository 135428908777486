import { useEffect } from 'react'
import type { RouterEvent } from 'next/router'
import { useRouter } from 'next/router'

const useOnRouterEvent = (event: RouterEvent, functionToCall: (...evts: LegitimateAny[]) => void) => {
  const router = useRouter()
  useEffect(() => {
    router.events.on(event, functionToCall)
    return () => router.events.off(event, functionToCall)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, functionToCall])
}

export { useOnRouterEvent }
