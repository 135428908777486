import { createGlobalStyle } from 'styled-components'

export const forceHideConcentBannerClass = 'force-hide-concent-banner'

export const ForceHideConcentBannerStyle = createGlobalStyle`
  .${forceHideConcentBannerClass}  {
    #onetrust-consent-sdk {
      display: none!important;
    }
  }
`
