import { useApolloClient } from '@apollo/client'
import { useQueryClient } from '@tanstack/react-query'
import { useDidUpdateEffect } from '@nordic-web/utils/hooks/use-did-update-effect'
import { useAuthenticationStore } from '@/features/auth/authentication-store'

export const OnAuthChange = () => {
  const apolloClient = useApolloClient()
  const queryClient = useQueryClient()

  const { profileId, isLoggedIn } = useAuthenticationStore()

  useDidUpdateEffect(() => {
    console.log('Resetting the apollo store because of profile change')
    apolloClient.resetStore()
  }, profileId)

  useDidUpdateEffect(() => {
    if (isLoggedIn) {
      console.log('Resetting the apollo store because of logging in')
      apolloClient.resetStore()
      queryClient.clear()
    }
  }, isLoggedIn)

  return null
}
