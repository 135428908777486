import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'

const ClientSideContext = createContext<boolean | null>(null)

export function ClientSideProvider({ children }: PropsWithChildren) {
  const [isClientSide, setIsClientSide] = useState(false)

  useEffect(() => {
    setIsClientSide(true)
  }, [])

  return <ClientSideContext.Provider value={isClientSide}>{children}</ClientSideContext.Provider>
}

export function useIsClientSide() {
  const isClientSide = useContext(ClientSideContext)
  if (isClientSide === null) {
    throw new Error('useIsClientSide must be used within a ClientSideProvider')
  }
  return isClientSide
}
