import { isServerSide } from '@nordic-web/utils/misc/detect-side'

export const applyLogErrorHack = async () => {
  if (isServerSide) {
    const logger = (await import('@/pages/api/logger')).logger

    console.error = (...args) => {
      logger.error(`applyLogErrorHack error`, { error: { ...args } })
    }
  }
}
