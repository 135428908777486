import type { FC, PropsWithChildren } from 'react'
import isPropValid from '@emotion/is-prop-valid'
import { StyleSheetManager as StyledComponentsStyleSheetManager } from 'styled-components'

export const StyleSheetManager: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledComponentsStyleSheetManager
      enableVendorPrefixes
      // See this post for context: https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
      shouldForwardProp={(propName, target) => {
        if (typeof target === 'string') {
          // For HTML elements, forward the prop if it is a valid HTML attribute
          return isPropValid(propName)
        }
        // For other elements, forward all props
        return true
      }}
    >
      {children}
    </StyledComponentsStyleSheetManager>
  )
}
