import { useTranslations } from 'next-intl'
import type { ApiError } from '@nordic-web/rest-codegen/generated/account'

/* See https://swe.account.a2d.tv/docs/index.html
0	generic	Generic error
1	email_already_in_use	The email is already in use
2	email_invalid	Please enter a valid email
3	password_too_short	Password does not meet the minimum length requirement of 8
5	client_missing	Missing client parameter
7	zip_code_invalid	Invalid zip code
10	invalid_credentials	Wrong username or password
11	http_error	HTTP error
12	service_outage	Service outage in underlying system
15	invalid_session	Authentication required
16	session_not_found	Session not found
18	username_already_in_use	The username is already in use
20	mismatching_passwords	Mismatching passwords
21	user_not_found	User not found
22	client_unauthorized	Client is not authorized
23	password_reset_token_already_used	Password reset token has already been used
 */

export const useErrorMessage = (error?: ApiError | null) => {
  const t = useTranslations()

  if (!error) return

  const errorMessageMap: Record<number, string> = {
    0: t('error_message__unknown_error'),
    1: t('error_message__email_already_in_use'),
    2: t('error_message__invalid_email'),
    7: t('error_message__invalid_zipcode'),
    10: t('error_message__invalid_credentials'),
    18: t('error_message__email_already_in_use'),
    21: t('error_message__no_user_with_email'),
    30: t('error_message__unable_to_delete_active_subscription'),
  }

  const wrongPassword = t('error_message__invalid_password')
  const genericErrormessage = t('error_message__unknown_error')

  let errorCode: number | undefined

  if (typeof error.body === 'object' && error.body !== null && 'code' in error.body) {
    errorCode = (error.body as { code: number }).code
    if (typeof errorCode !== 'number') {
      errorCode = undefined
    }
  }

  const errorFromCode = errorCode !== undefined ? errorMessageMap[errorCode] : undefined

  if (errorFromCode) {
    return errorFromCode
  }

  if (error.status === 401) {
    return wrongPassword
  }

  return genericErrormessage
}
