import type { FC, ReactNode } from 'react'
import { Fragment } from 'react'
import { TypographyDot } from '@nordic-web/ui-components'
import { nonNullable } from '@nordic-web/utils/typescript'

type DotRowProps = {
  items: ReactNode[]
}

export const DotRow: FC<DotRowProps> = ({ items }) => {
  const nonNullItems = items.filter(nonNullable)
  return (
    <>
      {nonNullItems.map((item, index) => (
        <Fragment key={index}>
          {item}
          {index < nonNullItems.length - 1 && <TypographyDot />}
        </Fragment>
      ))}
    </>
  )
}
