import React from 'react'
import { useAccountApi } from '@/features/auth/hooks/use-account-api'
import { ChildLockFormViews } from '@/features/auth/types'
import { ChildLockDisable as DisableChildLock } from '@/features/child-lock/manage/child-lock-disable'
import { ChildLockEnable as EnableChildLock } from '@/features/child-lock/manage/child-lock-enable'
import { DISABLE_CHILD_LOCK_METHODS } from '@/features/child-lock/manage/child-lock.constants'

type ChildLockFormProps = {
  onCancelChildLock: () => void
  formView?: ChildLockFormViews | null
}

export const ChildLockForms = ({ onCancelChildLock, formView }: ChildLockFormProps) => {
  const { user } = useAccountApi()
  const pinCode = user.data?.tv4ChildLock || ''

  if (!user || !formView) return null

  if (formView === ChildLockFormViews.ENABLE_PIN) {
    return <EnableChildLock onCancelChildLock={onCancelChildLock} pinCode={pinCode} />
  } else if (formView === ChildLockFormViews.DISABLE_WITH_PIN) {
    return (
      <DisableChildLock
        method={DISABLE_CHILD_LOCK_METHODS.PIN}
        onCancelChildLock={onCancelChildLock}
        pinCode={pinCode}
      />
    )
  } else if (formView === ChildLockFormViews.DISABLE_WITH_PASSWORD) {
    return (
      <DisableChildLock
        email={user.data?.email}
        method={DISABLE_CHILD_LOCK_METHODS.PASSWORD}
        onCancelChildLock={onCancelChildLock}
        pinCode={pinCode}
      />
    )
  } else if (formView === ChildLockFormViews.CHANGE_PIN) {
    return (
      <DisableChildLock
        isChangingPin
        method={DISABLE_CHILD_LOCK_METHODS.PIN}
        onCancelChildLock={onCancelChildLock}
        pinCode={pinCode}
      />
    )
  } else if (formView === ChildLockFormViews.CHANGE_WITH_PASSWORD) {
    return (
      <DisableChildLock
        email={user.data?.email}
        isChangingPin
        method={DISABLE_CHILD_LOCK_METHODS.PASSWORD}
        onCancelChildLock={onCancelChildLock}
        pinCode={pinCode}
      />
    )
  }

  return null
}
