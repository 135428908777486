import styled from 'styled-components'
import { TypographyText } from '@nordic-web/ui-components'
import { belowDesktop, desktop } from '@nordic-web/ui-styles'

const Container = styled.footer<{ useContentColor?: boolean }>(({ theme, useContentColor }) => ({
  background: useContentColor ? 'var(--content-color)' : theme.color.base.background,
  marginTop: 'auto',
  position: 'relative',
}))

const InnerContainer = styled.div(({ theme }) => ({
  paddingBlock: theme.space(6),
  background: theme.color.surface.black10,
}))

const Column = styled.div({
  width: '100%',
  [desktop]: {
    width: '25%',
  },
})

const ColumnContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBlock: theme.space(6),
  [desktop]: {
    flexDirection: 'row',
  },
}))

const ColumnHeading = styled.h2(({ theme }) => ({
  paddingBlock: theme.space(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [desktop]: {
    paddingBlock: theme.space(4),
    borderBottom: 'none',
    cursor: 'default',
    svg: {
      display: 'none',
    },
  },
}))

const ColumnHeadingButton = styled.button({
  background: 'transparent',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  color: 'inherit',
  fontSize: 'inherit',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  [desktop]: {
    display: 'none',
  },
})

const ColumnHeadingText = styled(TypographyText)<{ desktop?: boolean }>(({ desktop }) => ({
  ...(desktop ? { [belowDesktop]: { display: 'none' } } : {}),
}))

const ColumnItem = styled.li(({ theme }) => ({
  padding: `0px 0px ${theme.space(2)} ${theme.space(4)}`,
  [desktop]: {
    padding: `0px ${theme.space(4)} ${theme.space(4)} 0px`,
  },
  a: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const CookieSettings = styled.div({
  background: 'transparent',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  color: 'inherit',
  fontSize: 'inherit',
})

const ListContainer = styled.ul<{ open?: boolean }>(({ open }) => ({
  [belowDesktop]: {
    ...(!open ? { visibility: 'hidden', height: 0 } : {}),
  },
}))

const RotateIcon = styled.span<{ flip?: boolean }>`
  line-height: 0;
  transform: rotate(${(props) => (props.flip ? '180deg' : '0deg')});
`

export const styles = {
  Container,
  InnerContainer,
  Column,
  ColumnContainer,
  ColumnHeading,
  ColumnHeadingButton,
  ColumnHeadingText,
  ColumnItem,
  RotateIcon,
  CookieSettings,
  ListContainer,
}
