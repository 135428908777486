import { useEffect } from 'react'
import type { Path, PathValue, UseFormReturn } from 'react-hook-form'

type FieldValuesBase = {
  [key: string]: unknown
}

type UseMaxLengthOptions<T extends FieldValuesBase, K extends keyof T = keyof T> = {
  fieldName: K
  maxLength: number
  watchValue: T[K]
  setValue: UseFormReturn<T>['setValue']
}

export const useFormInputNumberMaxLength = <T extends FieldValuesBase>({
  fieldName,
  maxLength,
  watchValue,
  setValue,
}: UseMaxLengthOptions<T>) => {
  useEffect(() => {
    if (watchValue) {
      const valueString = watchValue.toString()
      const lastChar = valueString[valueString.length - 1]?.toString() || ''
      const isLastCharNumber = !isNaN(parseInt(lastChar, 10))

      if (isLastCharNumber) {
        if (valueString.length > maxLength) {
          const slicedValue = parseInt(watchValue.toString().slice(0, maxLength), 10)
          setValue(fieldName as unknown as Path<T>, slicedValue as unknown as PathValue<T, Path<T>>)
        }
      } else {
        const slicedValue = valueString.slice(0, valueString.length - 1)
        setValue(fieldName as unknown as Path<T>, slicedValue as unknown as PathValue<T, Path<T>>)
      }
    }
  }, [watchValue, maxLength, setValue, fieldName])
}
