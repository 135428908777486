import type { FC } from 'react'
import type { ChannelFieldsFragment, EpgFieldsFragment } from '@nordic-web/gql'
import { Dialog } from '@nordic-web/ui-components'
import { EpgDetails } from '@/features/channel-epg-panel/components/details-dialog/epg-details'

type DetailsDialogProps = {
  epgItems: EpgFieldsFragment[]
  channel: ChannelFieldsFragment
  selectEpgItem: (epgItem: EpgFieldsFragment | null) => void
  selectedEpgItem: EpgFieldsFragment | null
}

export const DetailsDialog: FC<DetailsDialogProps> = ({ epgItems, channel, selectEpgItem, selectedEpgItem }) => {
  const currentIndex = epgItems.findIndex((item) => item.broadcastId === selectedEpgItem?.broadcastId)

  const goToEpgItem = (diff: number) => {
    const nextIndex = currentIndex + diff
    const nextItem = epgItems[nextIndex]
    if (nextItem) {
      selectEpgItem(nextItem)
    }
  }

  return (
    <Dialog onClose={() => selectEpgItem(null)} nwInitOpen={!!selectedEpgItem}>
      {selectedEpgItem && (
        <EpgDetails
          hasPrevious={currentIndex > 0}
          hasNext={currentIndex < epgItems.length - 1}
          onNext={() => goToEpgItem(1)}
          onPrevious={() => goToEpgItem(-1)}
          channel={channel}
          epgItem={selectedEpgItem}
        />
      )}
    </Dialog>
  )
}
