/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import Script from 'next/script'
import { nextConfig } from '@/helpers/env'
import { TrackingGuard } from '@/layouts/tracking-guard'

const isEnabled = nextConfig.bool('ENABLE_THIRD_PARTY_SERVICES') && nextConfig.bool('ENABLE_GOOGLE_ANALYTICS')

// GTM SST configuration
const GTM_TAGGING_SERVER_HOST = nextConfig.string('GTM_TAGGING_SERVER_HOST')
const GTM_CONTAINER_ID = 'GTM-5BJHJCX'

type Path = {
  path: string
}

const Head = ({ path }: Path) => {
  return (
    <TrackingGuard path={path} isEnabled={isEnabled}>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '${GTM_TAGGING_SERVER_HOST}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');
    `}
      </Script>
    </TrackingGuard>
  )
}

const Body = ({ path }: Path) => (
  <TrackingGuard path={path} isEnabled={isEnabled}>
    <noscript id="google-tag-manager-body">
      <iframe
        height="0"
        src={`${GTM_TAGGING_SERVER_HOST}/ns.html?id=${GTM_CONTAINER_ID}`}
        style={{ display: 'none', visibility: 'hidden' }}
        width="0"
      />
    </noscript>
  </TrackingGuard>
)

export const gtmScript = {
  Head,
  Body,
}
