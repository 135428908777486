import type { FunctionComponent } from 'react'
import React from 'react'
import styled from 'styled-components'
import type { ParentalRatingFieldsFragment } from '@nordic-web/gql'
import { ParentalRatingReasonFinland } from '@nordic-web/gql'
import iconAgeRate7 from '@nordic-web/ui-svg-icons/kavi/icon-7.svg?url'
import iconAgeRate12 from '@nordic-web/ui-svg-icons/kavi/icon-12.svg?url'
import iconAgeRate16 from '@nordic-web/ui-svg-icons/kavi/icon-16.svg?url'
import iconAgeRate18 from '@nordic-web/ui-svg-icons/kavi/icon-18.svg?url'
import iconAgeRateAnxiety from '@nordic-web/ui-svg-icons/kavi/icon-anxiety.svg?url'
import iconAgeRateDrugs from '@nordic-web/ui-svg-icons/kavi/icon-drugs.svg?url'
import iconAgeRateS from '@nordic-web/ui-svg-icons/kavi/icon-s.svg?url'
import iconAgeRateSex from '@nordic-web/ui-svg-icons/kavi/icon-sex.svg?url'
import iconAgeRateT from '@nordic-web/ui-svg-icons/kavi/icon-t.svg?url'
import iconAgeRateViolence from '@nordic-web/ui-svg-icons/kavi/icon-violence.svg?url'

type ParentalRatingProps = NonNullable<
  ParentalRatingFieldsFragment['finland'] & { height?: number; className?: string }
>
type Reason = ParentalRatingProps['reason']

const IconContainer = styled.div<{ icon: string; height: number }>(({ theme, icon, height }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
  alignItems: 'center',
  background: `url(${icon}) center center no-repeat`,
  backgroundSize: 'contain',
  width: height,
  height: height,
}))

export const MtvParentalRating: FunctionComponent<ParentalRatingProps> = ({
  ageRestriction,
  reason,
  height = 24,
  className,
}) => {
  if (!ageRestriction && (!reason || reason.length === 0)) {
    return null
  }

  const getIcon = (key: Reason | string | null) => {
    if (!key) {
      return null
    }

    switch (key) {
      case '7':
        return iconAgeRate7
      case '12':
        return iconAgeRate12
      case '16':
        return iconAgeRate16
      case '18':
        return iconAgeRate18
      case 't':
        return iconAgeRateT
      case 'S':
        return iconAgeRateS
      case ParentalRatingReasonFinland.Anxiety:
        return iconAgeRateAnxiety
      case ParentalRatingReasonFinland.Drugs:
        return iconAgeRateDrugs
      case ParentalRatingReasonFinland.Sex:
        return iconAgeRateSex
      case ParentalRatingReasonFinland.Violence:
        return iconAgeRateViolence
      default:
        return null
    }
  }

  const icon = getIcon(ageRestriction)

  return (
    <>
      {icon && <IconContainer className={className} icon={icon} height={height} />}
      {reason &&
        reason.length > 0 &&
        reason.map((r) => {
          const reasonIcon = getIcon(r)
          return <IconContainer className={className} icon={reasonIcon} height={height} key={r} />
        })}
    </>
  )
}
