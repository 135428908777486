import { css } from 'styled-components'
import type { Theme } from '@nordic-web/ui-theme'

export const form = (theme: Theme) => css`
  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    caret-color: ${theme.color.text.primary};
    -webkit-text-fill-color: ${theme.color.text.primary};
    -webkit-box-shadow: 0 0 0px 1000px #474645 inset; /* Transparency not working so this is set explicitly */
  }
`
