import { type FC, useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import styled from 'styled-components'
import type { EpgFieldsFragment } from '@nordic-web/gql'
import { type ChannelFieldsFragment, useEpgQuery } from '@nordic-web/gql'
import { Button, CircleSpinner, Icon, ResponsiveImage, Stack, TypographyText } from '@nordic-web/ui-components'
import { isPlaying } from '@nordic-web/utils/epg'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'
import { EpgList } from '@/components/epg-list'
import { ChannelEpgContent } from '@/features/channel-epg-panel/components/channel-epg-content'
import { DetailsDialog } from '@/features/channel-epg-panel/components/details-dialog'
import { MissingEpgMessage } from '@/features/channel-epg-panel/components/missing-epg-message'
import { paths } from '@/helpers/paths'

const ITEMS_WHEN_COLLAPSED = 7

type LargeScreenChannelEpgProps = {
  channel: ChannelFieldsFragment
  date: number
  shouldShowFullDay: boolean
}

const ChannelEpgCard = styled(Stack)(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: theme.radii.border_radius_large,
}))

type ChannelEpgHeaderProps = {
  $disabled: boolean
}

const ChannelEpgHeader = styled(Link)<ChannelEpgHeaderProps>(({ theme, $disabled }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.color.surface.white5,
  padding: theme.space(3),
  ...($disabled && {
    pointerEvents: 'none',
  }),
  ...(!$disabled && {
    '&:hover': {
      background: theme.color.surface.white10,
      [String(HoverText)]: {
        display: 'flex',
      },
    },
  }),
}))

const HoverText = styled(TypographyText)(({ theme }) => ({
  display: 'none',
  marginLeft: theme.space(2),
}))

const PlayButton = styled.div(({ theme }) => ({
  position: 'absolute',
  right: theme.space(4),
  display: 'flex',
  alignItems: 'center',
  borderRadius: 100,
  padding: theme.space(1.5),
  backgroundColor: theme.color.surface.white5,
  gap: theme.space(2),
}))

export const LargeScreenChannelEpg: FC<LargeScreenChannelEpgProps> = ({ channel, date, shouldShowFullDay }) => {
  useReRenderWithInterval()

  const t = useTranslations()
  const [shouldShowFullDayInternal, setShouldShowFullDayInternal] = useState(shouldShowFullDay)

  const [selectedEpgItem, setSelectedEpgItem] = useState<EpgFieldsFragment | null>(null)

  useEffect(() => {
    setShouldShowFullDayInternal(shouldShowFullDay)
  }, [shouldShowFullDay])

  const { data, loading: isLoading } = useEpgQuery({
    variables: {
      id: channel.id,
      fullBroadcastDay: true,
      date,
    },
    context: {
      batch: true,
    },
  })

  const epg = (data?.media?.__typename === 'Channel' && data.media.epg) || []
  const hasEpgItems = !!epg.length

  const filteredEpgItems = () => {
    if (shouldShowFullDayInternal) {
      return epg
    }

    const liveProgramIndex = epg.findIndex(isPlaying)
    // If there is no program being broadcasted right now, we just show 7 items
    if (liveProgramIndex === -1) {
      return epg.slice(0, ITEMS_WHEN_COLLAPSED)
    }

    // Otherwise, show max 2 items in the past and max 5 items in the future
    const startIndex = Math.max(0, liveProgramIndex - 2)
    const endIndex = Math.min(epg.length, liveProgramIndex + 5)
    return epg.slice(startIndex, endIndex)
  }

  const epgItemsToRender = filteredEpgItems()

  const content = () => {
    if (isLoading) {
      return <CircleSpinner nwVariant="secondary" nwPaddingTop />
    }

    if (!hasEpgItems) {
      return <MissingEpgMessage channel={channel} />
    }

    return <EpgList onClick={setSelectedEpgItem} epg={epgItemsToRender} />
  }

  const logoSrc = channel.images.horizontalLogo?.sourceEncoded
  const isDisabled = !channel.access.hasAccess
  return (
    <>
      <ChannelEpgCard nwGap={1}>
        <ChannelEpgHeader
          $disabled={isDisabled}
          href={paths.video.urlString({ assetId: channel.id, slug: channel.slug })}
        >
          {logoSrc && <ResponsiveImage width={300} height={24} alt={channel.title} src={logoSrc} />}
          {!isDisabled && (
            <PlayButton>
              <HoverText nwColor="primary" nwVariant="body3">
                {t('channels__play')}
              </HoverText>
              <Icon nwSize="mini" nwVariant="play" />
            </PlayButton>
          )}
        </ChannelEpgHeader>
        {epg.length > ITEMS_WHEN_COLLAPSED && (
          <Button
            nwBorderType="none"
            nwTextVariant="body4"
            nwVariant="subtle"
            nwIconFirst={shouldShowFullDayInternal ? 'minimize-vertical' : 'maximize-vertical'}
            onClick={() => setShouldShowFullDayInternal(!shouldShowFullDayInternal)}
          >
            {shouldShowFullDayInternal ? t('channels__collapse_day') : t('channels__expand_day')}
          </Button>
        )}
        <ChannelEpgContent>{content()}</ChannelEpgContent>
      </ChannelEpgCard>
      <DetailsDialog
        channel={channel}
        epgItems={epgItemsToRender}
        selectEpgItem={setSelectedEpgItem}
        selectedEpgItem={selectedEpgItem}
      />
    </>
  )
}
