import type { KeyboardEvent, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Icon } from '../../icon/icon/icon'
import { AvatarCircle, AvatarWrapper } from '../shared-styles'

const AvatarNewCircle = styled(AvatarCircle)(({ theme }) => ({
  backgroundColor: theme.color.surface.white10,
  '&:hover': {
    backgroundColor: theme.color.surface.white20,
  },
}))

type AvatarNewProps = {
  onClick?: () => void
  tabIndex?: number
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void
}

export const NewAvatar = ({ onClick, tabIndex, onKeyDown = () => {}, children }: PropsWithChildren<AvatarNewProps>) => {
  return (
    <AvatarWrapper tabIndex={tabIndex} onKeyDown={onKeyDown}>
      <AvatarNewCircle onClick={onClick}>
        <Icon nwVariant="plus" nwSize="medium" />
      </AvatarNewCircle>
      {children}
    </AvatarWrapper>
  )
}
