import type { PropsWithChildren } from 'react'
import { brandConfig } from '@/config/brand'

// For the byt-losenord url, the token is part of the URL and is sensitive.
// We we're unable to configure all scripts loaded by GTM to not incldue this data when reporting.
// Therefore the final solution is to not load GTM when the user visits this page.
// We do not care about re-injecting GTM when client-side navigating from /byt-losenord to another page,
// since the behaviour is probably undefined. And it's pretty rare that users invoke the /byt-losenord flow.
// See https://teliatv4media.atlassian.net/browse/TV4PLAY-2233

const TRACKING_IGNORE_PATHS = [brandConfig.translatedPaths.changePassword]
export const shouldIgnoreRoute = (path: string) => TRACKING_IGNORE_PATHS.some((p) => path.startsWith(p))

type Props = {
  path: string
  isEnabled: boolean
}

export function TrackingGuard({ path, children, isEnabled }: PropsWithChildren<Props>) {
  if (!(isEnabled && !shouldIgnoreRoute(path))) {
    return null
  }
  return <>{children}</>
}
