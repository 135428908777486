export const size = {
  icon: {
    tiny: 14,
    tinyMedium: 18,
    mini: 20,
    small: 24,
    smallMedium: 32,
    medium: 40,
    large: 48,
    xl: 60,
  },
}
