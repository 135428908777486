import type { ReactElement, ReactNode } from 'react'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ApolloProvider } from '@apollo/client'
import { initApollo } from '@/lib/apollo/init-apollo'

type Props = {
  stateFromServer: NormalizedCacheObject
  children: ReactNode
  apolloClient?: ApolloClient<NormalizedCacheObject>
}

export const ApolloProvidersWrapper = ({ stateFromServer, children, apolloClient }: Props): ReactElement => {
  // Either use the client that is from the server, or initialize a new one
  const client = apolloClient || initApollo(stateFromServer)
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
