import { brandConfig } from '@/config/brand'

export const formatPrice = (price: number) => {
  const isInteger = Number.isInteger(price)

  const options = isInteger
    ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
    : { minimumFractionDigits: 2, maximumFractionDigits: 2 }

  return price.toLocaleString(brandConfig.language, options)
}
