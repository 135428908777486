import type { KeyboardEvent } from 'react'
import styled from 'styled-components'
import { Icon } from '../../icon/icon/icon'
import { Image } from '../../image/image/image'
import { AvatarAcronym, AvatarImage } from '../avatar/styles'
import { nwSizeStyles } from '../constants'
import { AvatarText } from '../shared-styles'

const IconContainer = styled.div(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  color: 'black',
  backgroundColor: theme.color.base.primaryLight,
  borderRadius: 48,
  width: 32,
  height: 32,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export type EditAvatarProps = {
  nwImageUrl: string
  nwName?: string
  onClick?: () => void
  nwShowIcon?: boolean
  tabIndex?: number
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void
  nwSelectable?: boolean
}

export const EditAvatar = ({
  nwName = '',
  nwImageUrl,
  onClick = () => {},
  nwShowIcon = true,
  tabIndex,
  onKeyDown,
  nwSelectable = true,
}: EditAvatarProps) => {
  const acronym = nwName.slice(0, 2).toUpperCase()
  return (
    <AvatarImage
      nwSize="large"
      nwSelected={false}
      onClick={onClick}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      nwSelectable={nwSelectable}
    >
      <Image src={nwImageUrl} alt={nwName} {...nwSizeStyles.large} />
      <AvatarText>
        <AvatarAcronym nwSize="large">{acronym}</AvatarAcronym>
        {nwShowIcon && (
          <IconContainer>
            <Icon nwVariant="edit" />
          </IconContainer>
        )}
      </AvatarText>
    </AvatarImage>
  )
}
