import Bugsnag from '@bugsnag/js'
import type { NextPage, NextPageContext } from 'next'
import { isServerSide } from '@nordic-web/utils/misc/detect-side'
import { Error404 } from './error-404'
import { Error500 } from './error-500'

/**
 * FIXME: check this later, maybe this behavior already fixed in Next.js (https://github.com/vercel/next.js/issues/39616)
 * for some reasons `statusCode` on client always 500 even if GIP return other code
 * so extract it from `__NEXT_DATA__` on the client
 */
const getStatusCode = (statusCodeFromProps: number) => {
  if (isServerSide) {
    return statusCodeFromProps
  }

  try {
    return (
      JSON.parse(document.getElementById('__NEXT_DATA__')?.textContent ?? '').props.pageProps.statusCode ??
      statusCodeFromProps
    )
  } catch (e) {
    return statusCodeFromProps
  }
}

type ErrorPageProps = {
  statusCode: number
}

const ErrorPage: NextPage<ErrorPageProps> = (props) => {
  const statusCode = getStatusCode(props.statusCode)
  switch (statusCode) {
    case 404:
      return <Error404 />
    default:
      return <Error500 />
  }
}

ErrorPage.getInitialProps = ({ res, err, req }: NextPageContext) => {
  let statusCode = res?.statusCode ?? 404

  if (err?.statusCode) {
    statusCode = err.statusCode
  }

  if (statusCode !== 404) {
    Bugsnag.notify(err ? err : 'Error page shown', (event) => {
      event.addMetadata('details', {
        url: req?.url,
        statusCode,
      })
    })
  }

  if (res) {
    res.statusCode = statusCode
    // We don't want CloudFront to cache error pages for too long.
    res.setHeader('Cache-Control', 'public, max-age=30')
  }

  return { statusCode }
}

export default ErrorPage
