import type { ChannelFieldsFragment } from '@nordic-web/gql'
import { ProgressBar, Stack, TypographyText } from '@nordic-web/ui-components'
import { getEpgProgressPercentage, isPlaying } from '@nordic-web/utils/epg'
import { useReRenderWithInterval } from '@nordic-web/utils/hooks/use-re-render-interval'
import type { OnCardClick } from '@/components/cards/types'
import { useUpdateEpg } from '@/components/epg-channel-card/use-update-epg'
import { LogoCard, LogoCardImage } from '@/components/logo-card'
import { usePrefetchVideoQuery } from '@/components/prefetch-links/video-prefetch-link'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { paths } from '@/helpers/paths'
import { useIsAboveTablet } from '@/hooks/use-breakpoint'
import { AssetTracking } from '@/tracking/asset-tracking'

type ChannelCardProps = {
  channel: ChannelFieldsFragment
  onCardClick?: OnCardClick
}

const Description = ({ channel }: ChannelCardProps) => {
  useReRenderWithInterval()
  useUpdateEpg(channel.id)

  const playingEpgItem = channel.epg?.find(isPlaying)

  if (playingEpgItem) {
    return (
      <Stack nwGap={1}>
        <TruncateLines lines={1}>{playingEpgItem.title}</TruncateLines>
        <ProgressBar percentage={getEpgProgressPercentage(playingEpgItem)} />
      </Stack>
    )
  }

  return <>{channel.tagline}</>
}

export const ChannelCard = ({ channel, onCardClick }: ChannelCardProps) => {
  const shouldShowDescription = useIsAboveTablet()

  const handleClick = () => {
    if (!onCardClick) return

    onCardClick(channel)
  }

  const prefetchVideoQuery = usePrefetchVideoQuery(channel.id)

  return (
    <AssetTracking content_media_id={channel.id}>
      {({ trackOnAssetClick }) => (
        <Stack nwGap={2}>
          <LogoCard
            onMouseEnter={prefetchVideoQuery}
            href={paths.video.urlString({ assetId: channel.id, slug: channel.slug })}
            onClick={() => {
              handleClick()
              trackOnAssetClick()
            }}
          >
            <LogoCardImage alt={channel.title} src={channel.images.logo?.sourceEncoded ?? ''} />
          </LogoCard>
          {shouldShowDescription && (
            <TypographyText nwVariant="body3Strong" nwColor="primary">
              <Description channel={channel} />
            </TypographyText>
          )}
        </Stack>
      )}
    </AssetTracking>
  )
}
