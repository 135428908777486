import { maxBreakpoint, mediaMinWidth, minBreakpoint } from '@nordic-web/ui-styles'
import { BRAND, Brand } from '@/config/brand'

const LARGE_DESKTOP_HEADER = 2000
const DESKTOP_HEADER = BRAND === Brand.TV4 ? 1150 : 1125
const TABLET_HEADER = BRAND === Brand.TV4 ? 970 : 965

// Object annotation syntax
export const aboveLargeDesktopHeader = minBreakpoint(LARGE_DESKTOP_HEADER)
export const aboveDesktopHeader = minBreakpoint(DESKTOP_HEADER)
export const belowTabletHeader = maxBreakpoint(TABLET_HEADER)
export const aboveTabletHeader = minBreakpoint(TABLET_HEADER)

// Template literal syntax
export const mediaLargeDesktopHeader = mediaMinWidth(LARGE_DESKTOP_HEADER)
export const mediaTabletHeader = mediaMinWidth(TABLET_HEADER)
