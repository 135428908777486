import type { PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'

type MenuState = {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: (open: boolean) => void
  toggleMobileMenuOpen: () => void
}

const MenuStateContext = createContext<MenuState | undefined>(undefined)

export const MenuStateProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const toggleMobileMenuOpen = () => setIsMobileMenuOpen((prevState) => !prevState)

  return (
    <MenuStateContext.Provider value={{ isMobileMenuOpen, setIsMobileMenuOpen, toggleMobileMenuOpen }}>
      {children}
    </MenuStateContext.Provider>
  )
}

export const useMenuState = () => {
  const menuState = useContext(MenuStateContext)

  if (typeof menuState === 'undefined') {
    throw new Error('useMenuState must be used within a MenuStateConext')
  }

  return menuState
}
