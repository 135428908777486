import styled from 'styled-components'

type PageMarginProps = {
  applyMargin?: boolean
}

export const PAGE_MARGIN = '5%'

export const PageMargin = styled.div<PageMarginProps>(({ applyMargin = true }) => ({
  ...(applyMargin && {
    marginLeft: PAGE_MARGIN,
    marginRight: PAGE_MARGIN,
  }),
  '&:has(.hide-module-spacer)': {
    display: 'none',
  },
}))
