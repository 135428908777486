import { type FC } from 'react'
import styled from 'styled-components'
import { type ChannelFieldsFragment } from '@nordic-web/gql'
import { Stack, UtilHorizontalScrollArea } from '@nordic-web/ui-components'
import { LogoCard, LogoCardImage } from '@/components/logo-card'

type SmallScreenChannelSelectProps = {
  channels: ChannelFieldsFragment[]
  selectedChannel: ChannelFieldsFragment
  onChannelClick: (channel: ChannelFieldsFragment) => void
}

const SmallLogoCard = styled(LogoCard)({
  width: 56,
})

export const SmallScreenChannelSelect: FC<SmallScreenChannelSelectProps> = ({
  channels,
  selectedChannel,
  onChannelClick,
}) => {
  return (
    <UtilHorizontalScrollArea nwFullWidth>
      <Stack nwDirection="row" nwGap={2}>
        {channels.map((channel) => (
          <SmallLogoCard
            draggable={false}
            selected={channel.id === selectedChannel.id}
            href="#"
            key={channel.id}
            onClick={(event) => {
              event.preventDefault()
              onChannelClick(channel)
            }}
          >
            <LogoCardImage draggable={false} alt={channel.title} src={channel.images.logo?.sourceEncoded ?? ''} />
          </SmallLogoCard>
        ))}
      </Stack>
    </UtilHorizontalScrollArea>
  )
}
