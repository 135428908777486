import type { FC } from 'react'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const getAnimation = (color1: string, color2: string) => keyframes`
  0% {
    color: ${color1};
    transform: scale(1);
  }
  100% {
    color: ${color2};
    transform: scale(1.5);
    text-shadow: 0px 0px 3px 0px ${color2};
  }
`

type TypographyDotProps = {
  color1?: string
  color2?: string
}

const Wrapper = styled.span<TypographyDotProps>(
  ({ theme, color1 = theme.color.surface.white50 }) => ({
    display: 'inline-block',
    margin: '0 6px',
    color: color1,
  }),
  ({ color1, color2 }) =>
    color1 &&
    color2 &&
    css`
      animation: ${getAnimation(color1, color2)} 1s infinite alternate ease-out 200ms;
    `
)

/**
 * Might be the only dot component you need
 */
export const TypographyDot: FC<TypographyDotProps> = (props) => <Wrapper {...props}>&bull;</Wrapper>
