import type { FC } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import type { PackageTierLinkFieldsFragment } from '@nordic-web/gql'
import { Banner } from '@/components/banner'
import { useAuthenticationStore } from '@/features/auth/authentication-store'
import { paths } from '@/helpers/paths'

type MediaUpgradeBannerProps = {
  title: string
  packageTierLink: PackageTierLinkFieldsFragment
  tierName: string
  sticky: boolean
}

export const MediaUpgradeBanner: FC<MediaUpgradeBannerProps> = ({ title, packageTierLink, tierName, sticky }) => {
  const t = useTranslations()
  const { isLoggedIn } = useAuthenticationStore()
  const router = useRouter()
  return (
    <Banner
      sticky={sticky}
      text={t(isLoggedIn ? 'cdp__upsell_banner_logged_in' : 'cdp__upsell_banner_logged_out', {
        title,
        tierName,
      })}
      href={paths.packages.urlString({
        packageId: packageTierLink.packageId,
        withAdFree: packageTierLink.adFree ? 'true' : 'false',
        return: router.asPath,
      })}
      packageId={packageTierLink.packageId}
    />
  )
}
