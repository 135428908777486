import { useTranslations } from 'next-intl'
import { NotificationSnackbarDuration, useNotificationSnackbar } from '@nordic-web/ui-components'

export const useProfileUpgradeMessage = () => {
  const { showSnackbarMessage } = useNotificationSnackbar()
  const t = useTranslations()

  const showProfileUpgradeMessage = () => {
    showSnackbarMessage({
      text: t('profiles__upgrade_required'),
      duration: NotificationSnackbarDuration.LONG,
      type: 'negative',
    })
  }

  return { showProfileUpgradeMessage }
}
