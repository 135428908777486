import type { PageInfoFieldsFragment, PanelQuery } from '@nordic-web/gql'
import { nonNullable } from '@nordic-web/utils/typescript'
import type { SwipeModuleItem } from './swipe-module'

type PanelResponse = PanelQuery['panel']

export const parsePanelResponse = (panel?: PanelResponse) => {
  let pageInfo: PageInfoFieldsFragment | null = null
  let content: SwipeModuleItem[] | null = null
  let showOnlyUpsellLabel = false
  switch (panel?.__typename) {
    case 'ClipsPanel':
      pageInfo = panel.content.pageInfo
      content = panel.content.items.map((item) => item.clip).filter(nonNullable)
      break
    case 'EpisodesPanel':
      pageInfo = panel.content.pageInfo
      content = panel.content.items.filter(nonNullable)
      break
    case 'MediaPanel':
      pageInfo = panel.content.pageInfo
      content = panel.content.items
        .map((item) => {
          if (item.__typename === 'MediaPanelMovieItem') {
            return item.movie
          }
          if (item.__typename === 'MediaPanelSeriesItem') {
            return item.series
          }
        })
        .filter(nonNullable)
      showOnlyUpsellLabel = panel.hideLabels
      break
    case 'ChannelPanel': {
      pageInfo = panel.content.pageInfo
      content = panel.content.items.map((item) => item.channel)
      break
    }
    case 'SportEventPanel': {
      pageInfo = panel.content.pageInfo
      content = panel.content.items.map((item) => item.sportEvent)
      break
    }
    case 'PagePanel': {
      pageInfo = panel.content.pageInfo
      content = panel.content.items.map((item) => item.page)
      break
    }
    default:
      break
  }

  return { pageInfo, content, showOnlyUpsellLabel }
}
