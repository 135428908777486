import styled from 'styled-components'
import { TypographyText } from '../../typography/typography-text/typography-text'

type LabelCountProps = {
  nwCount: number | string
}

export const LabelCount = ({ nwCount }: LabelCountProps) => {
  return (
    <LabelInner aria-hidden nwVariant="label" as="span">
      {nwCount}
    </LabelInner>
  )
}

const LabelInner = styled(TypographyText)(({ theme }) => ({
  background: theme.color.surface.white5,
  padding: theme.space(2),
  borderRadius: theme.radii.border_radius,
  fontWeight: 500,
}))
