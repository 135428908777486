import React from 'react'
import { Label } from '@nordic-web/ui-components'
import { VideoLiveLabels } from '@/components/video-live-labels'

type LivePanelLabelsProps = {
  playableFrom?: {
    isoString: string
    readableDateTime: string
  } | null
  isLiveContent: boolean
}

export const LivePanelLabels = ({ playableFrom, isLiveContent }: LivePanelLabelsProps) => {
  if (!isLiveContent) return null

  if (playableFrom) {
    return <VideoLiveLabels playableFrom={playableFrom} labelProps={{ nwPlacement: 'corner' }} />
  }

  return <Label nwVariant="live" nwPlacement="corner" />
}
