import styled from 'styled-components'
import { hiddenBaseState, showElement } from '@/styles/animations/visibility'

export const VideoFade = styled.div<{ loaded: number }>(({ theme, loaded }) => ({
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  height: '100%',
  backgroundColor: theme.color.base.background,
  opacity: loaded === 1 ? 0 : 1,
  transition: 'opacity 0.3s ease-in-out',
}))

export const VideoWrap = styled.div<{ visible: boolean }>((props) => ({
  opacity: props.visible ? 1 : 0,
  display: 'block',
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
}))

export const Video = styled.video({
  width: '100%',
  objectFit: 'cover',
})

export const ImageContainer = styled.div<{ visible: boolean; backgroundColorHex?: string }>((props) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  ...hiddenBaseState(),
  ...(props.visible ? showElement : {}),
  backgroundColor: props.backgroundColorHex ? `${props.backgroundColorHex}B3` : undefined,
}))
