import { useQuery } from '@tanstack/react-query'
import * as ServiceMessagesService from '@nordic-web/rest-codegen/generated/service-messages'
import { brandConfig } from '@/config/brand'

export function useServiceMessages() {
  return useQuery({
    queryKey: ['serviceMessages'],
    queryFn: () =>
      ServiceMessagesService.servicemessagesApiApplicationPublishedMessagesHandler({
        clientName: brandConfig.clientName,
      }),
    refetchInterval: 1000 * 60 * 3, // milliseconds
    refetchOnWindowFocus: true,
  })
}
