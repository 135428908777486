import type { PropsWithChildren, ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'
import { TypographyText } from '@nordic-web/ui-components'
import { tablet } from '@nordic-web/ui-styles'

type PanelTitleProps = {
  actions?: ReactNode
  firstTitle?: boolean
}

export const PanelTitle = ({ children, actions, firstTitle = false }: PropsWithChildren<PanelTitleProps>) =>
  children ? (
    <div>
      <PanelTitleHeader>
        <PanelText data-test="panel-title" as={firstTitle ? 'h1' : 'h3'} nwVariant="title3">
          {children}
        </PanelText>
        {actions && <div>{actions}</div>}
      </PanelTitleHeader>
    </div>
  ) : null

const PanelText = styled(TypographyText)({
  display: 'flex',
  alignItems: 'center',
})

const PanelTitleHeader = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.space(2),
  marginBottom: theme.space(2),
  [tablet]: {
    marginBottom: theme.space(4),
  },
}))
