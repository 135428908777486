import styled from 'styled-components'
import { nwSizeStyles } from '../constants'
import { AvatarCircle, AvatarWrapper } from '../shared-styles'
import { type AvatarProps } from './avatar'

type AvatarContainerProps = Required<Pick<AvatarProps, 'nwAxis' | 'nwDisabled'>>

export const AvatarContainer = styled(AvatarWrapper)<AvatarContainerProps>(({ nwAxis, nwDisabled }) => ({
  flexDirection: nwAxis === 'x' ? 'row' : 'column',
  ...(nwDisabled && {
    opacity: 0.5,
  }),
}))

type AvatarImageProps = Required<Pick<AvatarProps, 'nwSize' | 'nwSelected' | 'nwSelectable'>>

export const AvatarImage = styled(AvatarCircle)<AvatarImageProps>(({ nwSize, nwSelected, theme, nwSelectable }) => {
  return {
    position: 'relative',
    outlineWidth: 3,
    outlineOffset: -1,
    outlineStyle: 'solid',
    outlineColor: nwSelected ? theme.color.surface.white25 : 'transparent',
    ...nwSizeStyles[nwSize],
    ...(nwSelectable
      ? {
          '&:hover': {
            outlineColor: theme.color.base.primaryLight,
            background: theme.color.base.primaryLight,
          },
        }
      : { cursor: 'default' }),
    '&:focus': {
      outlineColor: theme.color.base.primaryLight,
      background: theme.color.base.primaryLight,
    },
  }
})

type AvatarAcronymProps = Required<Pick<AvatarProps, 'nwSize'>>

const nwSizeStylesAvatarAcronym = {
  mini: {
    fontSize: 12,
  },
  small: {
    fontSize: 14,
  },
  large: {
    fontSize: 30,
  },
}

export const AvatarAcronym = styled.span<AvatarAcronymProps>(({ nwSize, theme }) => {
  return {
    lineHeight: 'normal',
    userSelect: 'none',
    fontWeight: theme.fontWeightBold,
    ...nwSizeStylesAvatarAcronym[nwSize],
  }
})
