export const hiddenBaseState = ({ transitionMs = 200, transitionTiming = 'ease-in-out' } = {}) =>
  ({
    transition: `opacity ${transitionMs}ms ${transitionTiming}, visibility ${transitionMs}ms ${transitionTiming}`,
    opacity: 0,
    visibility: 'hidden', // needed because image bug on firefox
  }) as const

export const showElement = {
  opacity: 1,
  visibility: 'visible',
} as const
