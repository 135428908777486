import React, { useState } from 'react'
import type { LivePanelFieldsFragment } from '@nordic-web/gql'
import { useLivePanelQuery } from '@nordic-web/gql'
import { LiveCard } from '@/components/cards/live-card/live-card'
import { mapLivePanelItems } from '@/components/live-panel/map-live-panel-items'
import { Slider } from '@/components/slider/slider'
import { liveCardBreakpoints } from '@/components/slider/slider-breakpoints'
import { getSwipeModuleApiLimit } from '@/components/swipe-module/swipe-module'
import { PanelTitle } from '@/components/typography/panel-title'

export const LivePanel = ({ panel, index }: { panel: LivePanelFieldsFragment; index: number }) => {
  const { data, fetchMore } = useLivePanelQuery({
    fetchPolicy: 'cache-and-network',
    ssr: false,
    variables: {
      panelId: panel.id,
      offset: 0,
      limit: getSwipeModuleApiLimit(),
    },
  })

  const [activeIndex, setActiveIndex] = useState(0)
  const items = data?.panel?.__typename === 'LivePanel' ? mapLivePanelItems(data) : []
  const shouldPlayOutOfView = data?.panel?.__typename === 'LivePanel' ? data.panel.playOutOfView : null
  const pageInfo = data?.panel?.__typename === 'LivePanel' ? data.panel.content.pageInfo : null
  const hasNextPage = !!pageInfo?.hasNextPage

  return (
    <>
      <PanelTitle firstTitle={index === 0}>{panel.title}</PanelTitle>
      <Slider
        breakpoints={liveCardBreakpoints}
        hasMoreItems={hasNextPage}
        onIndexChange={(index) => setActiveIndex(index)}
        fetchMoreData={() => {
          fetchMore({
            variables: {
              limit: getSwipeModuleApiLimit(),
              offset: pageInfo?.nextPageOffset,
            },
          })
        }}
      >
        {items.map((item, index) => (
          <LiveCard
            key={item.id}
            asset={item}
            isActive={index === activeIndex}
            shouldPlayOutOfView={shouldPlayOutOfView}
          />
        ))}
      </Slider>
    </>
  )
}
