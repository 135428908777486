import type { PropsWithChildren } from 'react'
import React from 'react'
import { NotificationSnackbarProvider } from '@nordic-web/ui-components'
import { ClientSideProvider } from '@nordic-web/utils/hooks/use-is-client-side'
import { TrailersMutedStateProvider } from '@/components/trailer/trailers-muted-state'
import { RouteHistoryProvider } from '@/context/route-history-context'
import { AuthModalProvider } from '@/features/auth/context/auth-modal-context'
import { CheckoutStateProvider } from '@/features/checkout/context/checkout-state-context'
import { MenuStateProvider } from './menu-state-context'

export const ContextsProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <ClientSideProvider>
      <RouteHistoryProvider>
        <AuthModalProvider>
          <MenuStateProvider>
            <NotificationSnackbarProvider>
              <CheckoutStateProvider>
                <TrailersMutedStateProvider>{children}</TrailersMutedStateProvider>
              </CheckoutStateProvider>
            </NotificationSnackbarProvider>
          </MenuStateProvider>
        </AuthModalProvider>
      </RouteHistoryProvider>
    </ClientSideProvider>
  )
}
