// Can be fetched from https://tiering-api.clpl.tv4.a2d-dev.tv/v1/tiers
export const tv4Tiers = [
  {
    id: 'tv4-tier-0',
    name: 'TV4 Play (oinloggad)',
  },
  {
    id: 'tv4-tier-1',
    name: 'TV4 Play',
  },
  {
    id: 'tv4-tier-2',
    name: 'TV4 Play+',
  },
  {
    id: 'tv4-tier-3',
    name: 'TV4 Play+ utan reklam',
  },
  {
    id: 'tv4-tier-4',
    name: 'TV4 Play+ Sport',
  },
  {
    id: 'tv4-tier-5',
    name: 'TV4 Play+ Sport total',
  },
  {
    id: 'tv4-tier-11',
    name: 'HVOD Sport',
  },
  {
    id: 'tv4-tier-12',
    name: 'HVOD Sport Total',
  },
  {
    id: 'tv4-tier-8',
    name: 'TV4 Barn (AVOD)',
  },
  {
    id: 'tv4-tier-9',
    name: 'TV4 Barn (HVOD)',
  },
  {
    id: 'tv4-tier-10',
    name: 'TV4 Barn (SVOD)',
  },
]
