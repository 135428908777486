import type { CSSObject } from 'styled-components'
import { breakpointList } from '../breakpoints'

export const generateResponsiveStyles = <T extends keyof CSSObject>(property: T, values: CSSObject[T][]) => {
  const styles: CSSObject = {}

  values.forEach((value, index) => {
    if (index === 0) {
      // The first index is assumed to be mobile
      styles[property] = value
    } else if (value !== undefined) {
      const mq = `@media (min-width: ${breakpointList[index - 1]})`

      if (!styles[mq]) styles[mq] = {}
      ;(styles[mq] as CSSObject)[property] = value
    }
  })

  return styles
}
