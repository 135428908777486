import type { SportEventFieldsLightFragment } from '@nordic-web/gql'
import { isPast } from '../date'

export const useSportEventMeta = (studioText: string, event?: SportEventFieldsLightFragment) => {
  if (!event) {
    return null
  }

  const endTime = event.liveEventEnd?.isoString
  const hasEnded = endTime && isPast(endTime)

  if (hasEnded) {
    return event.playableFrom?.readableDate
  }

  if (event.studio) {
    return studioText
  }

  return null
}
