export const radiiFactorMap = [0, 1, 2, 3, 4, 5, 6, 8, 10, 12] as const

export type RadiiFactor = (typeof radiiFactorMap)[number]

export const radiiBaseUnit = 2

const radiiX = (value: RadiiFactor) => value * radiiBaseUnit

const borderRadius = {
  border_radius_small: radiiX(3),
  border_radius: radiiX(4),
  border_radius_large: radiiX(6),
}

export const radii = { radiiX, ...borderRadius }
