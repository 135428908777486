export type NonEmptyArray<T> = [T, ...T[]]

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never

export const nonNullable = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined

/**
 * Typesafe helper designed to narrow a GQL unions by __typename.
 *
 * example usage:
 * const res = myListOfUnionItems.filter(isTypeInUnion('myDesiredType'))
 * //typeof res is now Extract<MyUnion, {__typename: 'myDesiredType'}>[]
 *
 * @param typename string literal of the __typename you want to assert
 * @returns (union) => boolean
 */
export const isTypeInUnionList =
  <Union extends { __typename: string }, Typename extends Union['__typename']>(...typenames: Typename[]) =>
  (value: Union): value is Extract<Union, { __typename: Typename }> =>
    typenames.includes(value.__typename as Typename)

/**
 * Same as above, but to be used in if-statements. The above does not work for if-statements for some reason.
 * If you want to try to make the above work for if's, please try!
 */
export const isTypeInUnion = <T extends { __typename: string }, K extends T['__typename']>(
  input?: T | null,
  ...types: K[]
): input is Extract<T, { __typename: K }> => {
  if (!input) return false
  return types.includes(input.__typename as K)
}

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${P1}${Uppercase<P2>}${CamelCase<P3>}`
  : S

export type CamelCasedProperties<T> = {
  [K in keyof T as CamelCase<Extract<K, string>>]: T[K]
}
