import type {
  ClipFieldsLightFragment,
  EpisodeFieldsFragment,
  MovieFieldsLightFragment,
  SportEventVideoFieldsFragment,
} from '@nordic-web/gql'

type Movie = Pick<MovieFieldsLightFragment, '__typename' | 'id' | 'title' | 'slug'>
type SportEvent = Pick<SportEventVideoFieldsFragment, '__typename' | 'id' | 'title' | 'slug'>
type Episode = Pick<EpisodeFieldsFragment, '__typename' | 'series'>
type Clip = Pick<ClipFieldsLightFragment, '__typename' | 'parent'>
type Channel = { __typename: 'Channel' }

export const getParentInfo = (input: Movie | Episode | Clip | Channel | SportEvent) => {
  switch (input.__typename) {
    case 'Clip':
      return input.parent
    case 'Episode':
      return input.series
    case 'Movie':
    case 'SportEvent':
      return input
    case 'Channel':
      return undefined
  }
}
