import styled from 'styled-components'
import { combineResponsiveStyles, generateResponsiveStyles } from '@nordic-web/ui-styles'

type Placement = 'start' | 'center' | 'end'
type Axis = 'x' | 'y' | 'x-reverse' | 'y-reverse'
type Size = 'small' | 'medium'

type ButtonGroupProps = {
  nwPlacement?: Placement
  nwAxis?: Axis
  nwMobileAxis?: Axis | null
  nwSize?: Size
  nwFullWidth?: boolean
  nwWrap?: boolean
}

const getFlexDirection = (axis: Axis) => {
  switch (axis) {
    case 'x':
      return 'row'
    case 'x-reverse':
      return 'row-reverse'
    case 'y':
      return 'column'
    case 'y-reverse':
      return 'column-reverse'
    default:
      return 'row'
  }
}

export const ButtonGroup = styled.div<ButtonGroupProps>(
  ({
    theme,
    nwPlacement = 'start',
    nwAxis = 'x',
    nwMobileAxis = null,
    nwSize = 'medium',
    nwFullWidth = false,
    nwWrap = false,
  }) => {
    const sizeGap = {
      small: [theme.space(2)],
      medium: [theme.space(2), theme.space(4)],
    }

    const justifyContentValues = {
      start: 'flex-start',
      center: 'center',
      end: 'flex-end',
    }

    const flexDirection = getFlexDirection(nwAxis)
    const mobileDirection = nwMobileAxis ? getFlexDirection(nwMobileAxis) : flexDirection

    return {
      display: 'flex',
      justifyContent: justifyContentValues[nwPlacement],
      flexWrap: nwWrap ? 'wrap' : 'nowrap',
      ...combineResponsiveStyles([
        generateResponsiveStyles('flexDirection', [mobileDirection, flexDirection]),
        generateResponsiveStyles('gap', sizeGap[nwSize]),
      ]),
      ...(nwFullWidth && { width: '100%' }),
    }
  }
)
