import { css } from 'styled-components'

export const globalAnimations = css`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .fader-exit {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fader-exit.fader-exit-active {
    opacity: 0.01;
  }

  .fader-enter {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }

  .fader-enter.fader-enter-active {
    opacity: 1;
  }

  .fader-appear {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }

  .fader-appear.fader-appear-active {
    opacity: 1;
  }
`
