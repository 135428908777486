import { useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'

const twoWeeksInMilliseconds = 1000 * 60 * 60 * 24 * 14
const tenMinutesInMilliseconds = 1000 * 60 * 10

/**
 * This hook prevents browsers from using an app version that is older than 2 weeks
 */
export const useCheckAppAge = () => {
  const [pageLoadTime] = useState(Date.now())
  useEffect(() => {
    const checkPageAge = () => {
      const currentTime = Date.now()
      const ageInMilliseconds = currentTime - pageLoadTime
      if (ageInMilliseconds > twoWeeksInMilliseconds) {
        Bugsnag.notify('A browser was reloaded because it was open for more than two weeks')
        window.location.reload()
      }
    }

    const intervalId = setInterval(checkPageAge, tenMinutesInMilliseconds)
    return () => clearInterval(intervalId)
  }, [pageLoadTime])
}
