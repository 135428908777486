import { useRouter } from 'next/router'
import { useOnRouterEvent } from '@/hooks/use-on-router-event'

export const useOnNavigateToCurrentUrl = ({ onNavigate }: { onNavigate: () => void }) => {
  const router = useRouter()
  useOnRouterEvent('routeChangeStart', (newUrl: string) => {
    const currentUrl = router.asPath

    if (currentUrl === newUrl) {
      onNavigate()
    }
  })
}
