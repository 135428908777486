import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Image } from '@nordic-web/ui-components'
import { hiddenBaseState, showElement } from '@/styles/animations/visibility'
import { isIOSClient } from '@/utils/detect-device'

const Placeholder = styled.div<{ backgroundColorHex?: string; aspectRatio: string }>(
  ({ theme, backgroundColorHex, aspectRatio }) => ({
    backgroundColor: backgroundColorHex ? `${backgroundColorHex}B3` : theme.color.surface.white10,
    display: 'flex',
    width: '100%',
    height: '100%',
    aspectRatio,
  })
)

const InternalImage = styled(Image)<{ $shouldCancelTransition: boolean; $hasImageLoaded: boolean }>((props) => ({
  width: '100%',
  height: 'auto',
  pointerEvents: 'none',
  display: 'flex',
  ...hiddenBaseState(),
  ...(props.$shouldCancelTransition ? { transition: 'none' } : {}),
  ...(props.$hasImageLoaded ? showElement : {}),
}))

const LOAD_THRESHOLD_MS = 50

type FadingImageProps = {
  source: string
  width?: number
  height?: number
  alt?: string
  onClick?: () => void
  className?: string
  backgroundColorHex?: string
}

export const FadeInImage = ({
  width = 464,
  height = 261,
  source,
  onClick,
  alt = '',
  className,
  backgroundColorHex,
}: FadingImageProps) => {
  const [loadStartTime] = useState(new Date().getTime())
  const [hasImageLoaded, sethasImageLoaded] = useState(false)
  const [shouldCancelTransition, setShouldCancelTransition] = useState(false)

  const onImageLoaded = useCallback(() => {
    const end = new Date().getTime()
    const shouldNotFadeWhenLoaded = end - loadStartTime < LOAD_THRESHOLD_MS

    // Safari on iOS has a lot of problems with flickering elements during transitions (like card labels)
    // and as effect isn't really needed on smaller screens (very few cards are shown at the same time) we disable the effect for now
    if (isIOSClient() || shouldNotFadeWhenLoaded) {
      setShouldCancelTransition(true)
    }
    sethasImageLoaded(true)
  }, [loadStartTime])

  return (
    <Placeholder
      aspectRatio={String(width / height)}
      className={className}
      onClick={onClick}
      backgroundColorHex={backgroundColorHex}
    >
      <InternalImage
        $shouldCancelTransition={shouldCancelTransition}
        $hasImageLoaded={hasImageLoaded}
        onLoad={onImageLoaded}
        alt={alt}
        src={source}
        width={width}
        height={height}
      />
    </Placeholder>
  )
}
