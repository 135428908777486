import { Icon, Stack, TypographyText } from '@nordic-web/ui-components'

type Props = {
  bullets?: string[] | null
}
export const BulletList = ({ bullets }: Props) => {
  if (!bullets?.length) return null
  return (
    <Stack nwGap={2}>
      {bullets.map((bullet, index) => (
        <Stack nwGap={2} nwDirection="row" key={index} nwAlignItems="center">
          <Icon nwVariant="check" />
          <TypographyText nwVariant="packageCardBody">{bullet}</TypographyText>
        </Stack>
      ))}
    </Stack>
  )
}
