import { useTranslations } from 'next-intl'
import { Button, Stack, TypographyText } from '@nordic-web/ui-components'
import { CenterPage } from '@/components/scaffolding/center'
import { DefaultSeoTags } from '@/components/seo/default-seo-tags'

export const Error500 = () => {
  const t = useTranslations()

  return (
    <>
      <DefaultSeoTags />
      <CenterPage>
        <Stack nwGap={4} nwAlignItems="center">
          <TypographyText as="h1" nwVariant="title2">
            {t('error_message__unknown_error')}
          </TypographyText>
          <Stack nwGap={8}>
            <TypographyText nwVariant="body2">
              {t.rich('error_page__message', {
                br: () => <br></br>,
              })}
            </TypographyText>
            <Button nwIconFirst="refresh" onClick={() => window.location.reload()}>
              {t('error_message__button')}
            </Button>
          </Stack>
        </Stack>
      </CenterPage>
    </>
  )
}
