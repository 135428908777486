import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'

export const DefaultSeoTags = () => {
  const t = useTranslations()

  const defaultTitle = t('seo__default_title')
  const defaultDescription = t('seo__default_description')

  return <NextSeo title={defaultTitle} description={defaultDescription} />
}
