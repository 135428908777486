import type { PropsWithChildren, ReactNode } from 'react'
import { type TextAlign } from '../../../types/component-props'
import { Stack } from '../../layout/stack-redux/stack'
import { TypographyPreamble } from '../typography-preamble/typography-preamble'
import { TypographyText } from '../typography-text/typography-text'

export const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const
type HeadingLevel = (typeof headingLevels)[number]

export type TypographyTitleProps = {
  as: HeadingLevel
  /**
   * Make the heading look like another heading level
   */
  nwHeadingAppearance?: HeadingLevel
  nwPreamble?: ReactNode
} & TextAlign

/**
 * Title component for creating headings, with an optional preamble
 */
export const TypographyTitle = ({
  as,
  nwHeadingAppearance,
  nwPreamble,
  nwTextAlign = 'center',
  children,
}: PropsWithChildren<TypographyTitleProps>) => {
  const headingLevelAsNumber = parseInt(nwHeadingAppearance ? nwHeadingAppearance.slice(1) : as.slice(1))
  return (
    <Stack nwGap={4}>
      <TypographyText
        as={as}
        nwTextAlign={nwTextAlign === 'center' ? 'center' : 'initial'}
        nwVariant={headingLevelAsNumber > 2 ? 'title3' : headingLevelAsNumber === 2 ? 'title2' : 'title1'}
      >
        {children}
      </TypographyText>
      {nwPreamble && <TypographyPreamble nwTextAlign={nwTextAlign}>{nwPreamble}</TypographyPreamble>}
    </Stack>
  )
}
