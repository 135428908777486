import styled from 'styled-components'

type IProps = {
  readonly lines: number
}

export const TruncateLines = styled.div<IProps>`
  line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => props.lines};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
`
