import { useSyncExternalStore } from 'react'
import { useSwiper } from 'swiper/react'

export const useSwiperActiveIndex = () => {
  const swiper = useSwiper()
  return useSyncExternalStore(
    (callback) => {
      swiper.on('activeIndexChange', callback)
      return () => swiper.off('activeIndexChange', callback)
    },
    () => swiper.realIndex
  )
}

export const useSwiperSlideCount = () => {
  const swiper = useSwiper()
  return useSyncExternalStore(
    (callback) => {
      swiper.on('slidesLengthChange', callback)
      return () => swiper.off('slidesLengthChange', callback)
    },
    // Need to remove duplicates we are running swiper in loop mode, and then it has more slides than it actually has
    () => swiper.slides.filter((slide) => !slide.classList.contains('swiper-slide-duplicate')).length
  )
}
