import type React from 'react'
import { useEffect } from 'react'
import { BRAND, Brand } from '@/config/brand'

// The render deploy doesnt work with the login iframe, thats why we have a custom domain
export const PreviewDeployRedirect: React.FC = () => {
  useEffect(() => {
    const currentHost = window.location.host

    const domain = BRAND === Brand.MTV ? 'render-mtv.a2d-dev.tv/' : 'render.a2d-dev.tv/'

    if (currentHost.endsWith('.onrender.com')) {
      const subdomain = currentHost.split('.')[0]
      window.location.href = `https://${subdomain}.${domain}`
    }
  }, [])

  return null
}
