import styled from 'styled-components'
import { Stack } from '../../layout/stack-redux/stack'
import type { CircleSpinnerProps } from '../circle-spinner/circle-spinner'
import { CircleSpinner } from '../circle-spinner/circle-spinner'

const PageCenter = styled(Stack)({
  height: '100vh',
  width: '100%',
})

export const FullPageCircleSpinner = (props: CircleSpinnerProps) => {
  return (
    <PageCenter nwJustifyContent="center" nwAlignItems="center">
      <CircleSpinner {...props} />
    </PageCenter>
  )
}
