import Image from 'next/image'
import styled from 'styled-components'
import { brandConfig } from '@/config/brand'

const Container = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
})

export const SiteLogo = () => {
  return (
    <Container>
      {/* The logo is purely for decoration. We don't need an alt text. */}
      <Image alt="" priority src={brandConfig.logo} fill />
    </Container>
  )
}
