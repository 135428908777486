import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'
import { FlagProvider, useFlag, useUnleashContext } from '@unleash/proxy-client-react'
import type { IConfig } from '@unleash/proxy-client-react'
import { InMemoryStorageProvider } from '@unleash/proxy-client-react'
import { LocalStorageProvider } from 'unleash-proxy-client'
import { isClientSide } from '@nordic-web/utils/misc/detect-side'
import { brandConfig } from '@/config/brand'
import { useAccountApi } from '@/features/auth/hooks/use-account-api'
import { nextConfig } from '@/helpers/env'

// We use unleash very rarely, set this to true if you need a feature flag
const isUnleashEnabled = false

const config: IConfig = {
  appName: brandConfig.clientName,
  // Client key is not secret.
  clientKey: nextConfig.string('UNLEASH_CLIENT_KEY'),
  disableMetrics: true,
  disableRefresh: true, // Disable polling
  environment: nextConfig.string('UNLEASH_ENVIRONMENT'),
  url: 'https://unleash-edge.a2d.tv/api/frontend',
  storageProvider: isClientSide ? new LocalStorageProvider() : new InMemoryStorageProvider(),
}

export const useFeatureToggle = ({ name }: { name: string }) => {
  return useFlag(name)
}

export const FeatureToggleProvider = ({ children }: PropsWithChildren) => {
  if (!isUnleashEnabled) {
    return children
  }

  return <FlagProvider config={config}>{children}</FlagProvider>
}

const FeatureToggleRefresherInternal = () => {
  const updateContext = useUnleashContext()
  const { user } = useAccountApi()
  const email = user.data?.email

  useEffect(() => {
    if (email) {
      updateContext({ userId: email })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  return null
}

export const FeatureToggleRefresher = () => {
  if (!isUnleashEnabled) {
    return null
  }

  return <FeatureToggleRefresherInternal />
}
