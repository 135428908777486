import { useMemo } from 'react'
import styled from 'styled-components'
import { Stack } from '../../layout/stack-redux/stack'

const DotsStack = styled(Stack)<{ $count: number }>(({ theme, $count }) => ({
  height: theme.space(6),
  minWidth: `calc(${$count} * ${theme.space(3)} + ${theme.space(6)})`,
  backgroundColor: theme.color.surface.black25,
  backdropFilter: 'blur(20px)',
  zIndex: 1,
  borderRadius: theme.space(4),
  paddingInline: theme.space(3),
}))

const Dot = styled.div<{ $size: 'big' | 'medium' }>(({ theme, $size }) => ({
  transition: 'all 250ms',
  width: $size === 'big' ? theme.space(4) : theme.space(2),
  backgroundColor: $size === 'big' ? theme.color.icon.primary : theme.color.icon.tertiary,
  height: theme.space(2),
  borderRadius: theme.space(1),
  marginLeft: theme.space(1),
  marginRight: theme.space(1),
}))

const DotContainer = styled.div<{ $clickable: boolean }>(({ $clickable }) => ({
  ...($clickable ? { cursor: 'pointer' } : {}),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

type NavigationDotsProps = {
  count: number
  activeIndex: number
  onDotClick?: (index: number) => void
}

export const NavigationDots = ({ count, activeIndex, onDotClick }: NavigationDotsProps) => {
  const dots = useMemo(() => Array.from({ length: count }, (_, i) => i), [count])

  return (
    <Stack nwDirection="row" nwAlignItems="center">
      <DotsStack nwDirection="row" nwAlignItems="center" nwJustifyContent="center" $count={count}>
        {dots.map((index) => (
          <DotContainer $clickable={typeof onDotClick === 'function'} key={index} onClick={() => onDotClick?.(index)}>
            <Dot $size={activeIndex === index ? 'big' : 'medium'} />
          </DotContainer>
        ))}
      </DotsStack>
    </Stack>
  )
}
